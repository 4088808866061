import {
    useOutletContext
  } from 'react-router';
import { useEffect, useState } from 'react';

export const GeoView = () => {
    const session = useOutletContext();
    const [isLoading, setIsLoading] = useState(true);
    const [crd, setCrd] = useState([]);
    console.log('app.js geoview')
    console.log(session)
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    
    function success(pos) {
      const crd = pos.coords;
      console.log("Your current position is:");
      console.log(crd)
      setCrd(crd)
      setIsLoading(false);
    }
    
    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }
    
    useEffect(() => {
      setIsLoading(true);
      navigator.geolocation.getCurrentPosition(success, error, options); 
  }, [])
  
  return (
      <div>
        Geo View
        <p>{error.message}</p>
        <div>
        {isLoading ? <p>Loading...</p> : null }
        {
        <div>
        <p>{"Your current position is:"}</p>
        <p>{`Latitude : ${crd.latitude}`}</p>
        <p>{`Longitude: ${crd.longitude}`}</p>
        <p>{`More or less ${crd.accuracy} meters.`}</p>
        </div>
        }
        </div>
      </div>
    );
  };
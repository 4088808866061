// This is where users are gonna check who has liked, commented, read/bought, and followed them.

// For now we'll call a getPgData when this page is opened--in the future I'd like notifs to
// be a realtime feature.

// Okay so maybe not realtime, but I'd at least like to show a bell-with-number on the feedview for unread notifications

import { Outlet, useNavigation, useOutletContext } from 'react-router';
import { useState, useEffect } from "react";
import {Container, Avatar, Typography, Card, CardHeader, CardContent, CardMedia, IconButton, Box, Button, useMediaQuery, Divider} from '@mui/material';
import React, { useRef } from 'react';
import '../styles/UserViewStyles/user.css';
import { useNavigate } from 'react-router-dom';
import { FollowingView } from './FollowingView';
import { getPgData, postPgData, patchPgData, deletePgData } from '../data/rdsClient';

// add a function for parsing the article timestamps
const { DateTime } = require('luxon');


export const NotificationsView = () => {
  const session = useOutletContext();
  console.log('NotificationsView, session=',session)
  const [userPublic, setUserPublic] = useState(session.data?session.data.session.userPublic:{});
  const navigation = useNavigation();
  const navigate = useNavigate();

  //State variables for notifications---------------------------------
  const [notifs, setNotifs] = useState([{
    eid: 'loading',
    created: 'loading',
    updated: 'loading',
    type: ['loading'],
    trigger_uid: ['loading'],
    trigger_uname: 'loading',
    action: 'loading',
    subject_aid_uid: 'loading',
    subject_title_name: 'loading',
    owner_uid: 'loading',
    is_checked: false // added an is_checked boolean with pgAdmin (default false)
  }]);
  //State variables for notifications---------------------------------

    // add a function for parsing the article timestamps
    function parseTimestamp(timestamp) {
    // Create a Luxon DateTime object from the timestamp
    const dt = DateTime.fromISO(timestamp, { zone: 'utc' });
  
    // Get the current UTC time
    const now = DateTime.utc();
  
    // Calculate the difference in days between now and the timestamp
    const diffInDays = now.diff(dt, 'days').toObject().days;
  
    // If the difference is greater than or equal to 1 day, display m/d/y format
    if (diffInDays >= 1) {
      return dt.toFormat('LL/dd/yyyy');
    } else {
      // Get the day of the week and format it in English
      const dayOfWeek = dt.toFormat('EEEE');
  
      // Get the time of day in 12-hour HH:mm a format (with AM/PM)
      const timeOfDay = dt.toFormat('hh:mm a');
  
      // Get the English time zone name
      const timeZoneName = dt.toFormat('ZZZZ');
  
      return `${dayOfWeek}, ${timeOfDay} ${timeZoneName}`;}
    }
    // const timestamp = '2023-09-13T23:03:53.011426+00:00';
    // const result = parseTimestamp(timestamp);
    // console.log(result); // Example output: "Wednesday, 11:03 PM UTC"



  async function fetchNotifications() {
      console.log('fetchNotifications');
      let token = '';
      if(session.data){ // will fail when not logged in
        console.log('fetchNotifications session=',session) // may not be signed in
        token = session?session.data.session.access_token:null // todo: examine session param for default value case
      }else{
        token = ""
      }
      try {
        // getPgData notifications where is = session...id 
        const { data: notificationsData, error: notificationsError } = await getPgData(`/notifications?owner_uid=eq.${session.data.session.userPublic.id}`, {}, session);// todo: examine session param for default value case
    
        if (notificationsError) {
          console.error('Error:', notificationsError);
          console.log(notificationsError.message);
          return { error: notificationsError.message };
        }
    
        console.log('getPgData - fetchNotifications:', notificationsData);
        // Update the otherUserPublic state object
        setNotifs(notificationsData); // setNotifs to the full array
      } catch (error) {
        console.error('fetchNotifications error:', error);
        return { error: error.message };
      }
    }


  // set all notifications is_checked to true for this userPublic.id
  async function checkNotifications() {
    console.log('checkNotifications');
    let token = '';
    if(session.data){ // will fail when not logged in
      console.log('checkNotifications session=',session) // may not be signed in
      token = session?session.data.session.access_token:null // todo: examine session param for default value case
    }else{
      token = ""
    }
    try {
      // getPgData notifications where is = session...id 
      const { data: checkNotificationsData, error: checkNotificationsError } = await patchPgData(`/notifications?owner_uid=eq.${session.data.session.userPublic.id}`, {is_checked: true}, session);// set all notifications is_checked to true for this userPublic.id
  
      if (checkNotificationsError) {
        console.error('Error:', checkNotificationsError);
        console.log(checkNotificationsError.message);
        return { error: checkNotificationsError.message };
      }
  
      console.log('getPgData - checkNotifications:', checkNotificationsData);
      // Update the otherUserPublic state object
      setNotifs(checkNotificationsData); // setNotifs to the full array
    } catch (error) {
      console.error('checkNotifications error:', error);
      return { error: error.message };
    }
  }

  useEffect(() => {
      // must be signed on to see this page
      fetchNotifications()
      checkNotifications()
  }, []);


  function NotifCard(notif){
    console.log('NotifCard: ', notif)
    return ( 
      <>
        <Card sx={{height: '100%', marginBottom: '1rem', marginTop: '1rem', borderBottom: '1px solid white'}}>
          <CardHeader
            // it'd be helpful is the notifications table also contained the profile_pic_url
            // avatar={
            //   <Avatar src={comment.author_profile_pic_url} alt={comment.author_name} sx={{width: '40px', height: '40px'}}></Avatar>
            // }
            title={notif.action=='followed'? // if liked/commented/read/bought, link to article.  If followed just say so
            <Typography varient='body1'><a href={`/user/${notif.trigger_uid}`}>{notif.trigger_uname}</a> {notif.action} you!</Typography>
              :<Typography varient='body1'><a href={`/user/${notif.trigger_uid}`}>{notif.trigger_uname}</a> {notif.action} <a href={`/${notif.subject_aid_uid}`}>{notif.subject_title_name}</a></Typography>
            }
            subheader={parseTimestamp(notif.created)}
          />
          {/* keep this for now in case I don't like the way it looks */}
          {/* <CardContent>
            <Typography variant={viewport ? 'h6' : 'subtitle1'} gutterBottom paragraph>
              {comment.text}
            </Typography>
          </CardContent> */}
        </Card>
      </>
    )
  }

  return (
    <div style={{ height: '100%', backgroundColor: '#0f0f0f', color: 'white', padding: '40px 0px' }}>
      <Container>
        <div id='notificationsview-container'>
          {/* Reposition divs once external CSS files are created */}
          <div id='notifications-body'>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
              <h2>Notifications</h2>
            </div>
              <br></br>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
                {/* I'm using materialUI to make cards */}
                <div id='notification-cards'> 
                <div style={{ display: 'inline-block' }}>
                    {notifs ? notifs.map(notif => NotifCard(notif)) : <h2>No notifications yet!</h2>}
                </div>
                </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
} // end NotificationsView
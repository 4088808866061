import { useActionData, useNavigation, useOutletContext } from 'react-router';
import { Form, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import '../styles/LoginSignUpStyles/signup.css';
import { Mail } from '@mui/icons-material';
import { registerWithPg, changePasswordWithPg, loginWithPg, getPgData } from '../data/rdsClient';

// TODO: this page uses isLoading and navigation.state correctly.  How can I use it in SignUpView?

// if someone tries to register a duplicate account, we let them complete signup, but then the registration itself fails.  
// in the browser, they see the window popup 'bad login', in the console, pgrst returns:
/**
 * 
 * @returns [Log] registerWithPg:  (main.d48b6e3e.js, line 2)
Object
code: "23505"
details: "Key (email)=(doug31123@gmail.com) already exists."
hint: null
message: "duplicate key value violates unique constraint \"users_email_unique\""
Object Prototype
 */

export const RegisterView = () => {
  //Introduce State for Error Handling
  const [errorMessage, setErrorMessage] = useState(null);
  const handleError = (message) => {setErrorMessage(message);};
  const resetError = () => {setErrorMessage(null);};
    // Step 1: Introduce State for Success Handling
  const [successMessage, setSuccessMessage] = useState(null);
  const handleSuccess = (message) => {setSuccessMessage(message);};
  const resetSuccess = () => {setSuccessMessage(null);};

  const { email, code, initial_password } = useParams();
    const navigation = useNavigation();
    const navigate = useNavigate();
    const session = useOutletContext();  // this should be null, can we remove/  or do we want to redirect a signed-in user away from the signup page?
    const isLoading =
      navigation.state === 'loading' || navigation.state === 'submitting';

    const data = useActionData();
    // this is called on form submission
    useEffect(() => {
      console.log("RegisterView useEffect");
      if (data) {
        if (data.error) {
          handleError(data.error);
        } else {
          if (data === 'passwords dont match') {
            handleError('Password and confirmed password don\'t match, please try again');
            navigate(`/register/${email}/${code}/${initial_password}`);
          } else if ('session' in data) {
            // Use the login function from SessionProvider to set the session data
            session.login({ data });
            handleSuccess('Registration Complete!');
            // Redirect the user to a protected route or the home page
            navigate('/');
          }
        }
      }
    }, [data, email, code, initial_password, session, navigate]);
    
    return(
            <div style={{height: '100vh', backgroundColor: '#0f0f0f', color: 'white',}}>
                {/* these used to be signup-header and subheader, may be causing a CSS bug */}
                <h1 id='register-header'>Single Pay News</h1>
                <h3 id='register-subheader'>Register</h3>

                <div className='form-container'>

                {/* <StyledFormLayout> */}
                    <Form id='form' action={`/register/${email}/${code}/${initial_password}`} method='post'>
                    <input disabled={isLoading} type="hidden" name="initial_password" value={initial_password} className='signup-input'/>
                    <label className='signup-label'>
                        Email:
                        <input disabled={isLoading} type="text" name="email" value={email.toLowerCase()} placeholder={email.toLowerCase()} className='signup-input'/>
                    </label>
                    <label className='signup-label'>
                        6-Digit Code:
                        <input disabled={isLoading} type="text" name="code" value={code} placeholder={code} className='signup-input'/>
                    </label>
                    <label className='signup-label'>
                        Set Your Password:
                        <input disabled={isLoading} type="password" name="password" placeholder='password' className='signup-input'/>
                    </label>
                    <label className='signup-label'>
                        Confirm Password:
                        <input disabled={isLoading} type="password" name="password_confirm" placeholder='confirm password' className='signup-input'/>
                    </label>
                    {/* this used to be id='signup-button'; may be causing CSS bug */}
                    <button disabled={isLoading} id='register-button'>
                        {isLoading ? 'loading...' : 'Register'}
                    </button>
                    </Form>
                    {data && data.error && <div>{data.error}</div>}
        {/** Step 2: Displaying Error Messages in the UI */}
        {errorMessage && (
          <div className="error-message">
              <p>{errorMessage}</p>
              <button onClick={resetError}>Close</button>
          </div>
      )}
      {/** Step 2: Displaying Success Messages in the UI */}
      {successMessage && (
          <div className="success-message">
              <p>{successMessage}</p>
              <button onClick={resetSuccess}>Close</button>
          </div>
      )}
                {/* </StyledFormLayout> */}
                </div>
            </div>
    );
};

export async function action({ request }) {
  console.log('action called')
  // get formData
  const formData = await request.formData();
  const formDataEscape = { 
    email: formData.get('email').toLowerCase(),
    password: formData.get('password'),
    confirm: formData.get('password_confirm'),
    code: formData.get('code'),
    initial_password: formData.get('initial_password')
  };

  if (formDataEscape.password!=formDataEscape.confirm){
    return { error: 'Passwords do not match' };
  }

  try {
    // 1] use registerWithPg to insert to temp table basic_auth.unverified_users(email, pass, role, created, name, code)
    // 2] update the password
    // 3] log in
    // 4] getPgData userpublic where is = session...id 
    // 5] build session.data in correct format for <SessionProvider> and return it.
  
    /* check if email already registered
    on second though, don't.  I'd rather register a duplicate account and ban the user than expose what emails are in use
    const { getExistingUserData, getExistingUserError } = await getPgData(rdsUri, "", `/userpublic?email=eq.${formDataEscape.email}`, {})
    "column userpublic.email does not exist"
    */

    // 1] use registerWithPg to insert to temp table basic_auth.unverified_users(email, pass, role, created, name, code)
    const { data: registerData, error: registerError } = await registerWithPg(
      {
        input_code: formDataEscape.code,
        input_email: formDataEscape.email.toLowerCase(),
        input_pass: formDataEscape.initial_password
      })
      if (registerError) {
        console.log('registerError', registerError)
        return registerError // handled in data/useEffect()
      }
      console.log('registerData: ', registerData);

    // 2] update the password
    const { data: changePasswordWithPgResponse, error: changePasswordWithPgError }  = await changePasswordWithPg({
      // input_email TEXT, input_pass TEXT, input_code TEXT
      input_email: formDataEscape.email.toLowerCase(),
      input_pass: formDataEscape.password,
      input_code: '123456'
    })
    if (changePasswordWithPgError) {
      console.log('changePasswordWithPgError', changePasswordWithPgError)
      return changePasswordWithPgError // handled in data/useEffect()
    }
    console.log('changePasswordWithPgResponse: ', changePasswordWithPgResponse);
    // change password didn't work at all.  old password still functions.
    console.log('await changePasswordWithPgResponse.body: ', await changePasswordWithPgResponse.body);

    // 3] log in
    const { data: loginData, error: loginError } = await loginWithPg(
      {
        input_email: formDataEscape.email.toLowerCase(),
        pass: formDataEscape.password 
      }
    );
    if (loginError) {
      console.log('bad login credentials', loginError)
      return loginError
    }
    console.log('loginWithPg: ', loginData); // note the format of data.  This is used to build ret, and can throw error.
    
    // 4] getPgData userpublic/userprivate where is = session...id 
    const { data: userPublicData, error: userPublicError } = await getPgData(`/userpublic?id=eq.${loginData[0].id}`); // todo: examine session param for default value case
    if (userPublicError) {
      console.error('Error:', userPublicError);
      console.log(userPublicError.message);
      return { error: userPublicError.message };
    }
    console.log('getPgData - userPublic:', userPublicData);

    const { data: userPrivateData, error: userPrivateError } = await getPgData(`/userprivate?id=eq.${loginData[0].id}`, {}, loginData[0].token.token); // todo: examine session param for default value case
    if (userPrivateError) {
      console.error('Error:', userPrivateError);
      console.log(userPrivateError.message);
      return { error: userPrivateError.message };
    }
    console.log('getPgData - userPrivate:', userPrivateData);
    // 5] build session.data in correct format for <SessionProvider> and return it.
    const ret = { // TODO: edit login function and user table to return this stuff as expected
      user: {
        email_confirmed_at: "todo",
        id: loginData[0].id,
        last_sign_in_at: "todo",
        phone: "todo",
        role: loginData[0].role,
        updated_at: "todo",
        username: loginData[0].name
      },
      session: {
        access_token: loginData[0].token.token, //data.token becomes nested?
        token_type: "bearer",
        expires_in: 3600,
        expires_at: 1695076987,
        refresh_token: "todo",
        // ,user again as object, supabase does this we mayeb don't want to
        userPublic: userPublicData[0], // yes--returns an array, must escape.  TODO: is this an array now?  Do I need userPublicData[0]?
        userPrivate: userPrivateData[0]
      }
    };
    console.log(ret);
    return ret;
  } catch (error) {
    console.log(error)
    return { error: 'Something went wrong. Please email us at singlepaynews@gmail.com.' };
    throw error;
  }
}

// ToDo: apply css styling.  Grant, do we need this?

// const StyledFormLayout = styled.div`
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-direction: column;
//     margin: 24px;

//     label{
//         font-family: system-ui;
//         font-size:16px;
//         display: flex;
//         flex-direction: column;
//         margin: 10px 0;
//     }

//     input {
//         width: 350px;
//         padding-bottom: 20px; 
//         box-sizing: border-box;
//         border-radius: 6px;
//         margin: 5px 0;
//         padding: 14px 16px;
//         border: 2px solid #dddfe2;
//     }

//     button {
//         cursor: pointer;
//         font-family:system-ui;
//         font-weight: 600;
//         border: none;
//         background-color: #448cf8;
//         color: white;
//         width: 100%;
//         height: 2.4em;
//         line-height: 48px;
//         margin-top: 20px;
//         font-size: 20px;
//         border-radius: 6px;
//         padding: 0 16px;
//     }
// `;


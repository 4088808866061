// relevant tables:

/** hashtags
 * id(bigint)
 * created datetime
 * updated datetime
 * type text -- null
 * text text -- #string; includes # in string
 * article_ids(bigint[])
 */

/** has_hashtag_relation
 * hashtag_id(bigint)
 * article_id(bigint)
 */

/** articlefresh
 * tag_names (text[]) -- format like "{#do,#blurb,#hashtags,#work,#like}"
 * tag_ids (bigint[]) -- currently null
 */

/** userpublic
 * hashtags_following_id (bigint[])
 * hashtags_following_text (text[])
 * blocked_hashtags_id (bigint[])
 * blocked_hashtags_text (text[])
 */

/** following_hashtag_relation
 * user_id(bigint)
 * hashtag_id(bigint)
 * hashtag_text(text)
 * time(Default NOW)
 */

/** blocked_hashtags_relation
 * user_id(bigint)
 * hashtag_id(bigint)
 * hashtag_text(text)
 * time(Default NOW)
 */


import { Outlet, useNavigation, useOutletContext } from 'react-router';
import { useState, useEffect } from "react";
import React, { useRef } from 'react';
import '../styles/UserViewStyles/user.css';
import { useNavigate } from 'react-router-dom';
import { getPgData, postPgData, patchPgData, deletePgData } from '../data/rdsClient';
import {Container, Avatar, Typography, Card, CardHeader, 
CardContent, CardMedia, IconButton, Button, Box, useMediaQuery, Divider} from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share'; 
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import { useParams } from 'react-router-dom';

// add a function for parsing the article timestamps
const { DateTime } = require('luxon');



export const HashtagView = () => {
  //Introduce State for Error Handling
  const [errorMessage, setErrorMessage] = useState(null);
  const handleError = (message) => {setErrorMessage(message);};
  const resetError = () => {setErrorMessage(null);};
    // Step 1: Introduce State for Success Handling
  const [successMessage, setSuccessMessage] = useState(null);
  const handleSuccess = (message) => {setSuccessMessage(message);};
  const resetSuccess = () => {setSuccessMessage(null);};

  const { text, id } = useParams(); // link can have neither, text, or both.  Never just id.
  console.log('HashtagView, text=',text,' id=',id);
  const session = useOutletContext(); // does not have to be signed in to be on this page
  console.log('HashtagView, session=',session);
  const navigation = useNavigation();
  const navigate = useNavigate();

  // For media queries under 900px viewport
  const viewport = useMediaQuery('(min-width: 680px)');

    // add a function for parsing the article timestamps
    function parseTimestamp(timestamp) {
        // Create a Luxon DateTime object from the timestamp
        const dt = DateTime.fromISO(timestamp, { zone: 'utc' });
        
        // Get the current UTC time
        const now = DateTime.utc();
        
        // Calculate the difference in days between now and the timestamp
        const diffInDays = now.diff(dt, 'days').toObject().days;
        
        // If the difference is greater than or equal to 1 day, display m/d/y format
        if (diffInDays >= 1) {
            return dt.toFormat('LL/dd/yyyy');
        } else {
            // Get the day of the week and format it in English
            const dayOfWeek = dt.toFormat('EEEE');
        
            // Get the time of day in 12-hour HH:mm a format (with AM/PM)
            const timeOfDay = dt.toFormat('hh:mm a');
        
            // Get the English time zone name
            const timeZoneName = dt.toFormat('ZZZZ');
        
            return `${dayOfWeek}, ${timeOfDay} ${timeZoneName}`;}
        }

  // todo: if looking at one hashtag in particular, fetch all the articles that use it
  async function fetchArticleContent(article_ids) {
    console.log('fetchArticleContent article_ids=',article_ids) // may not be signed in
    try {
        const response = await getPgData(`/articlefresh?id=in.(${article_ids})&order=created.desc`);
        if (Array.isArray(response.data)) {
            console.log('fetchArticleContent response.data: ', response.data);
            return response.data;
            // todo: add this back in to display articles featuring hashtag
            // setItems(mapToFormatB(response.data))
        } else {
            console.error('Response data is not an array:', response.data);
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
  }

  // handleFollowClick can follow or unfollow
  async function handleFollowClick(addRemove, hashtag_id, hashtag_text) {
    if(session?.data?.session?.userPublic?.id){
        if(isBlocked(hashtag_id)){ // disallow following something you're currently blocking
            handleError('Can\'t follow a hashtag you\'re blocking.');
            return;
        }else{
              // relation
            const relation = {
                p_user_id:session.data.session.userPublic.id,
                p_hashtag_id:hashtag_id,
                p_hashtag_text:hashtag_text
            }
            // we're going to assume the user is followed/unfollowed according to param 'addRemove' TODO: is_followed_flag would be a better name for this flag
            if (addRemove === 'follow'){ 
                // use /rpc/follow
                const { data, error } = await postPgData('/rpc/follow_hashtag', 
                relation,
                session//.data.session.access_token) // todo: examine session param for default value case
                )
                if (error) {
                console.error('Error:', error);
                console.log(error.message);
                return { error: error.message };
                }
                console.log('postPgData - /rpc/follow_hashtag:', data);
                /**
                 * [Log] postPgData - /rpc/follow_hashtag: – 
                 * [
                 * {
                 * updated_hashtags_following_id: [33], 
                 * updated_hashtags_following_text: ["test"]
                 * }
                 * ]
                 *  (1) (main.d267f1fc.js, line 2)
                 */
                // change button state
                handleSuccess('Followed!'); 
                // update stored session
                let newSession = session;
                newSession.data.session.userPublic.hashtags_following_id = data[0].updated_hashtags_following_id;
                newSession.data.session.userPublic.hashtags_following_text = data[0].updated_hashtags_following_text;
                session.login(newSession);
                // todo: navigate() to update DOM?
                
            }else if(addRemove === 'unfollow'){
                // use /rpc/follow
                const { data, error } = await postPgData('/rpc/unfollow_hashtag', 
                relation,
                session//.data.session.access_token // todo: examine session param for default value case
                )
                if (error) {
                    console.error('Error:', error);
                    console.log(error.message);
                    return { error: error.message };
                }
                console.log('postPgData - /rpc/unfollow_hashtag:', data);
                handleError('Unfollowed'); 
                // update stored session
                let newSession = session;
                newSession.data.session.userPublic.hashtags_following_id = data[0].updated_hashtags_following_id;
                newSession.data.session.userPublic.hashtags_following_text = data[0].updated_hashtags_following_text;
                session.login(newSession);
                // todo: navigate() to update DOM?
            }else{
                console.log('problem with addRemove param')
                handleError('Something went wrong; try signing in again?')
            }
        }
    } else {
        handleError('Are you logged in?');
        return
    }
  } 
  
  // TODO: make a rpc/block_hashtag
  // TODO: make a rpc/unblock_hashtag
  async function handleBlockClick(addRemove, hashtag_id, hashtag_text) {
    console.log('handleBlockClick');
    if(session?.data?.session?.userPublic?.id){
        // relation
        const relation = {
          p_user_id:session.data.session.userPublic.id,
          p_hashtag_id:hashtag_id,
          p_hashtag_text:hashtag_text
        }
        // we're going to assume the user is followed/unfollowed according to param 'addRemove' TODO: is_followed_flag would be a better name for this flag
        if (addRemove === 'block'){ 
          // use /rpc/follow
          const { data, error } = await postPgData('/rpc/block_hashtag', 
            relation,
            session//.data.session.access_token) // todo: examine session param for default value case
          )
          if (error) {
            console.error('Error:', error);
            console.log(error.message);
            return { error: error.message };
          }
          console.log('postPgData - /rpc/block_hashtag:', data);
          handleError('Blocked.'); 
          // update stored session
          let newSession = session;
          newSession.data.session.userPublic.hashtags_following_id = data[0].updated_hashtags_following_id;
          newSession.data.session.userPublic.hashtags_following_text = data[0].updated_hashtags_following_text;
          newSession.data.session.userPublic.blocked_hashtags_id = data[0].updated_blocked_hashtags_id;
          newSession.data.session.userPublic.blocked_hashtags_text = data[0].updated_blocked_hashtags_text;
          session.login(newSession);
          // todo: navigate() to update DOM?
          
        }else if(addRemove === 'unblock'){
            // use /rpc/follow
            const { data, error } = await postPgData('/rpc/unblock_hashtag', 
            relation,
            session//.data.session.access_token // todo: examine session param for default value case
            )
            if (error) {
              console.error('Error:', error);
              console.log(error.message);
              return { error: error.message };
            }
            console.log('postPgData - /rpc/unblock_hashtag:', data);
            handleSuccess('unblocked'); 
            // update stored session
            let newSession = session;
            newSession.data.session.userPublic.blocked_hashtags_id = data[0].updated_blocked_hashtags_id;
            newSession.data.session.userPublic.blocked_hashtags_text = data[0].updated_blocked_hashtags_text;
            session.login(newSession);
            // todo: navigate() to update DOM?
        }else{
          console.log('problem with addRemove param')
          handleError('Something went wrong; try signing in again?')
        }
      } else {
          handleError('Are you logged in?');
          return
      }
  }

  async function fetchAllHashtags(){
    try {
        const response = await getPgData(`/hashtags?order=created.desc`);
        if (Array.isArray(response.data)) {
            console.log('fetchAllHashtags response.data: ', response.data);
            return(response.data); // return data
        } else {
            console.error('Response data is not an array:', response);
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
  }

  async function fetchHashtagFromText(hashtag_text){
    try {
        const response = await getPgData(`/hashtags?text=eq.${hashtag_text}`);
        if (Array.isArray(response.data)) {
            console.log('fetchHashtagId response.data: ', response.data);
            return(response.data); // return data
        } else {
            console.error('Response data is not an array:', response);
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
  }

  const [Hashtags, setHashtags] = useState();
  const [articles, setarticles] = useState();
  useEffect(() => {
    console.log('HashtagView useEffect');

    const fetchData = async () => {
      if (id === undefined) {
        if (text === undefined) {
          console.log("we're on the page for viewing all hashtags");
          try {
            const hashtagsData = await fetchAllHashtags();
            setHashtags(hashtagsData);
            // Fetch articles based on hashtags
            if (hashtagsData.length === 1) {
                const hashtag = hashtagsData[0];
                try {
                const articlesData = await fetchArticleContent(hashtag.article_ids);
                console.log(articlesData);
                setarticles(articlesData);
                } catch (error) {
                console.error('Error fetching articles:', error);
                }
            }
          } catch (error) {
            console.error('Error fetching hashtags:', error);
          }
        } else {
          console.log('get the hashtags from the hashtag_text');
          const hashtagsData = await fetchHashtagFromText(text);
          setHashtags(hashtagsData);
          // Fetch articles based on hashtags
            if (hashtagsData.length === 1) {
                const hashtag = hashtagsData[0];
                try {
                const articlesData = await fetchArticleContent(hashtag.article_ids);
                console.log(articlesData);
                setarticles(articlesData);
                } catch (error) {
                console.error('Error fetching articles:', error);
                }
            }
        }
      } else {
        console.log('we have text and id.');
        console.log('get the hashtags from the hashtag_text');
        const hashtagsData = await fetchHashtagFromText(text);
        setHashtags(hashtagsData);
        // Fetch articles based on hashtags
        if (hashtagsData.length === 1) {
            const hashtag = hashtagsData[0];
            try {
            const articlesData = await fetchArticleContent(hashtag.article_ids);
            console.log(articlesData);
            setarticles(articlesData);
            } catch (error) {
            console.error('Error fetching articles:', error);
            }
        }
      }
    };

    fetchData();
  }, []); // Include dependencies in the array


  // isFollowing(hashtag_id); returns true if hashtag_id in hashtags_following_id, false otherwise (not in or logged out)
  function isFollowing(hashtag_id) {
    if (session?.data?.session?.userPublic?.hashtags_following_id){
        return session.data.session.userPublic.hashtags_following_id.includes(hashtag_id);
    }else{
        return false;
    }
  }


  // isBlocked(hashtag_id); returns true if hashtag_id in blocked_hashtags_id, false otherwise (not in or logged out)
  function isBlocked(hashtag_id) {
    if (session?.data?.session?.userPublic?.blocked_hashtags_id){
        return session.data.session.userPublic.blocked_hashtags_id.includes(hashtag_id);
    }else{
        return false;
    }
  }

  //Returns 'More - Paid' if value == true or 'More - False' if value == false or null
  const isMoreFree = (value) => value ? <Button size='large'>$0.25</Button> : <Button size='large'>Free</Button>

  return (
    <div style={{ height: '100%', backgroundColor: '#0f0f0f', color: 'white', padding: '40px 0px', justifyContent: 'center' }}>
        {/* If on page for all hashtags (or one hashtag in particular), show hashtag cards with follow/block buttons */}

        <div style={{ height: '100%', backgroundColor: '#0f0f0f', color: 'white', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {/** Step 2: Displaying Error Messages in the UI */}
        {errorMessage && (
          <div className="error-message">
              <p>{errorMessage}</p>
              <button onClick={resetError}>Close</button>
          </div>
      )}
      {/** Step 2: Displaying Success Messages in the UI */}
      {successMessage && (
          <div className="success-message">
              <p>{successMessage}</p>
              <button onClick={resetSuccess}>Close</button>
          </div>
      )}
        {Hashtags && Hashtags.length>0 ? (Hashtags.map(Hashtag => ( // Check if Hashtags data is available
            <Card sx={{ height: '100px', margin: '10px', borderBottom: '1px solid white' }}>
                <CardHeader
                title={
                    <Typography varient='body1'><a href={`/hashtag/${Hashtag.text}/${Hashtag.id}`}>{Hashtag.text}</a></Typography>
                }
                />
                <Button 
                variant="contained"
                sx={{ margin: '8px' }}
                // isFollowing(hashtag_id); returns true if hashtag_id in hashtags_following_id, false otherwise (not in or logged out)
                onClick={() => handleFollowClick(isFollowing(Hashtag.id) ? 'unfollow' : 'follow', Hashtag.id, Hashtag.text)}>
                {isFollowing(Hashtag.id) ? 'Unfollow' : 'Follow'}
                </Button>
                <Button 
                variant="contained"
                sx={{ margin: '8px' }}
                color="error"
                // isBlocked(hashtag_id); returns true if hashtag_id in blocked_hashtags_id, false otherwise (not in or logged out)
                onClick={() => handleBlockClick(isBlocked(Hashtag.id) ? 'unblock' : 'block', Hashtag.id, Hashtag.text)}>
                {isBlocked(Hashtag.id) ? 'Unblock' : 'Block'}
                </Button>
            </Card>
        ))) : (
        // Render nothing if this is false
        <></>
        )}
        </div>
        {/* If Hashtags.length===0, nobody has used this hashtag before */}
        {/* need strict === so this doesn't render when undefined */}


        {Hashtags && Hashtags.length===0 ? (
        <div style={{ height: '100%', backgroundColor: '#0f0f0f', color: 'white', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>    
            <Card sx={{ height: '100px', margin: '10px', borderBottom: '1px solid white' }}>
                <CardHeader
                title={
                    <Typography varient='body1'>Nobody has used this Hashtag before.</Typography>
                }
                />
                <Button 
                variant="contained"
                sx={{ margin: '8px' }}
                onClick={() => navigate('/create')}>
                Post your Article and be the first!
                </Button>
            </Card>
        </div>
        ) : (
        // Render nothing if this is false
        <></>
        )}
        {/* If Hashtags=undefined, page is loading */}
        {Hashtags === undefined ? (
            <div style={{ height: '100%', backgroundColor: '#0f0f0f', color: 'white', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>    
            Loading...
            </div>
        ):(
            <></>
        )}
        {/* If looking at one hashtag in particular, show all articles that feature it. */}
        
        {Hashtags?.length === 1 ? (
        <>
            <div style={{ height: '100%', backgroundColor: '#0f0f0f', color: 'white', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>    
                <Divider />
                <h2>Published Articles using #{Hashtags[0].text}:</h2>
            </div>
        </>
        ) : (
        <></>
        )}

        <Divider />
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {articles ? (articles.map(post => (
            <Card sx={{height: '100%', margin: '10px', borderBottom: '1px solid white' }}>
                <CardHeader
                avatar={
                    <Avatar src={post.author_profile_picture} sx={{width: '40px', height: '40px'}}></Avatar>
                }
                title={
                    <Typography varient='body1'><a href={`/user/${post.user_author}`}> By {post.author_name}</a></Typography>
                }
                subheader={parseTimestamp(post.created)}
                />


                <CardContent>
                <Typography variant={viewport ? 'h5' : 'body1'} sx={{fontWeight: 600}} gutterBottom>
                    {post.title}
                    </Typography>
                </CardContent>

                {/* TODO: what if no image for this article? */}
                {/* then post.image_url will =="" */}
                {(post.image_url!="")?(
                <CardMedia
                    component="img"
                    sx={{ height: '100%', width:'50%', margin: '0 auto' }}
                    image={post.image_url}
                    title={post.author_name}
                />
                ):(
                <></>
                )}

                  <CardContent>
                    <Typography variant={viewport ? 'h6' : 'subtitle1'} gutterBottom paragraph>
                      {post.blurb}
                    </Typography>

                    <Box sx={{display: 'inline'}} onClick={() => navigate(`/${post.id}`)}>
                      {/* post.is_paid is true when post costs (posted via createview), and null when posted for free*/}
                      {isMoreFree(post.is_paid)} 
                    </Box>

                    <Box sx={{float: 'right'}}>
                      {/* TODO: this highlights the tumb, but doesn't increment the count */}
                      <IconButton onClick={() => navigate(`/${post.id}`)}>
                        <CommentIcon/>{post.num_comments}
                      </IconButton>

                      <IconButton onClick={() => session ? navigate(`/report/${'article'}/${post.id}`):handleError('Are you logged in?')}>
                        <ReportProblemIcon/>
                      </IconButton>

                      <IconButton onClick={() => handleSuccess(`https://www.singlepaynews.com/${post.id}`)}>
                        <ShareIcon/>
                      </IconButton>

                      <Button size='large' onClick={() => navigate(`/${post.id}`)}>Open in Feed</Button> 
                    </Box>
                  </CardContent>
                </Card>
            ))):(<></>)}
        </div>
    </div>
  );
}
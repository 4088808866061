import { useActionData, useNavigation, useOutletContext } from 'react-router';
import { useNavigate, Form } from 'react-router-dom';
import { useEffect, useState } from 'react';
import '../styles/LoginSignUpStyles/login.css';
import { 
  Grid,
  Header,
  Button,
  Segment } from 'semantic-ui-react'
import { CurrencyLira } from '@mui/icons-material';
import { getPgData, loginWithPg } from '../data/rdsClient';


export const LoginView = () => {

  //Introduce State for Error Handling
  const [errorMessage, setErrorMessage] = useState(null);
  const handleError = (message) => {setErrorMessage(message);};
  const resetError = () => {setErrorMessage(null);};
    // Step 1: Introduce State for Success Handling
  const [successMessage, setSuccessMessage] = useState(null);
  const handleSuccess = (message) => {setSuccessMessage(message);};
  const resetSuccess = () => {setSuccessMessage(null);};

  const navigation = useNavigation();
  const navigate = useNavigate();
  const session = useOutletContext();
  const isLoading =
    navigation.state === 'loading' || navigation.state === 'submitting';

  const data = useActionData();  //https://remix.run/docs/en/main/hooks/use-action-data

  useEffect(() => {
    console.log("LoginView useEffect")
    if (data === null || data === undefined) {
      // Handle the case when data is null or undefined (initial page load)
      return;
    }
    if (data && !data.error) {//https://remix.run/docs/en/main/hooks/use-action-data
      console.log('data=',data)
      if ('session' in data) { // if token exists, we have valid login
        // Use the login function from SessionProvider to set the session data
        
        session.login({data});
        
        // Redirect the user to a protected route or the home page
        navigate('/');
      }
      if ('code' in data) { 
        // Handle the case when data has a PGRST code (bad login)
        handleError(data.message);
        navigate('/login');
      }
    }
    // Handle the final 'else' condition if none of the above conditions were met
    if (data && data.error) {
      handleError('data.error:',data.error);
      navigate('/');
    }
  }, [data]); 
return (
  <div style={{height: '100vh', backgroundColor: '#0f0f0f', color: 'white'}}>
    <h1 id="login-header">Single Pay News</h1>
    <h3 id="login-subheader">Login</h3>
    <div className='form-container'>
      <Form action='/login' method='post'>
        <label  className='login-label'>
          Email:
          <input disabled={isLoading} type="text" name="Email" placeholder='Email' className='login-input'/>
        </label>
        <label className='login-label'>
          Password:
          <input disabled={isLoading} type="password" name="password" placeholder='password' className='login-input'/>
        </label>
        <button disabled={isLoading} id='login-button'>
          {isLoading ? 'loading...' : 'Login'}
        </button>
      </Form>
    <h3 id="forgot-password-link"><a href='/forgot-password'>Forgot Password?</a></h3>
      {data && data.error && <div>{data.error}</div>}
        {/** Step 2: Displaying Error Messages in the UI */}
        {errorMessage && (
          <div className="error-message">
              <p>{errorMessage}</p>
              <button onClick={resetError}>Close</button>
          </div>
      )}
      {/** Step 2: Displaying Success Messages in the UI */}
      {successMessage && (
          <div className="success-message">
              <p>{successMessage}</p>
              <button onClick={resetSuccess}>Close</button>
          </div>
      )}
    </div>
  </div>
  );
};

//https://hackernoon.com/understanding-session-management-using-react-router-v6
export async function action({ request }) {
  console.log('action called');
  const formData = await request.formData();
  const formDataEscape = {
    Email: formData.get('Email'),
    password: formData.get('password'),
  };

  try {
    const { data: loginData, error: loginError } = await loginWithPg(
      {// Mother fucker.  The login redirect works with the sql login function from (19), but not (25)
        input_email: formDataEscape.Email, 
        pass: formDataEscape.password //lol this still worked with an extra comma?
      }
    );

    // todo: come to understand the error handling between here, the return values of loginWithPg, and useEffect.
    // apply that understanding to other pages.
    // prosper.
    if (loginError) {
      console.log('bad login credentials', loginError)
      return loginError
    }

    console.log('loginWithPg: ', loginData); // note the format of data.  This is used to build ret, and can throw error.

    // getPgData userpublic where is = session...id 
    const { data: userPublicData, error: userPublicError } = await getPgData(`/userpublic?id=eq.${loginData[0].id}`); // todo: examine session param for default value case
    if (userPublicError) {
      console.error('Error:', userPublicError);
      console.log(userPublicError.message);
      return { error: userPublicError.message };
    }
    console.log('getPgData - userPublic:', userPublicData);

    // getPgData userpublic where is = session...id 
    const { data: userPrivateData, error: userPrivateError } = await getPgData(`/userprivate?id=eq.${loginData[0].id}`, {}, loginData[0].token.token); // todo: examine session param for default value case
    if (userPrivateError) {
      console.error('Error:', userPrivateError);
      console.log(userPrivateError.message);
      return { error: userPrivateError.message };
    }
    console.log('getPgData - userPrivate:', userPrivateData);


    console.log('userPublicData[0]:', userPublicData[0]);
    console.log('userPrivateData[0]:', userPrivateData[0]);

    // must use let data to allow overwrite
    let ret = { // TODO: edit login function and user table to return this stuff as expected
      user: {
        email_confirmed_at: "todo",
        id: loginData[0].id,
        last_sign_in_at: "todo",
        phone: "todo",
        role: loginData[0].role,
        updated_at: "todo",
        username: userPublicData[0].name // use username from userPublic table
      },
      session: {
        access_token: loginData[0].token.token, //data.token becomes nested?
        token_type: "bearer",
        expires_in: 3600,
        expires_at: 1695076987,
        refresh_token: "todo",
        // ,user again as object, supabase does this we mayeb don't want to
        userPublic: userPublicData[0], // yes--returns an array, must escape.  TODO: is this an array now?  Do I need userPublicData[0]?
        userPrivate: userPrivateData[0]
      }
    };

    console.log('ret: ', ret);
    return ret;
  } catch (error) {
    if (error.message === 'Invalid credentials') {
      return {
        error: 'Invalid credentials.',
      };
    }
    throw error;
  }
}

// const StyledHeaders = styled.div`

//   #login-header{
//     display: flex;
//     font-size: 64px; 
//     justify-content: center;
//     margin: 0;
//     padding-top: 80px;
//     font-family: 'DM Serif Display serif';
//   }

//   #login-subheader{
//     display: flex;
//     font-size: 32px;
//     justify-content: center;
//     margin: 12px; 
//     font-family: 'DM Serif Display', serif; 
//   }

//   @media screen and (min-width: 0px) and (max-width: 768px)
//   {
//     #login-header{
//       font-size: 48px;
//     }

//     #login-subheader{
//       font-size: 24px;
//     }
//   }
// `;

// const StyledFormLayout = styled.div`
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-direction: column;
//     margin: 24px;

//     label{
//         font-family:system-ui;
//         font-size:16px;
//         display: flex;
//         flex-direction: column;
//         margin: 10px 0;
//     }

//     input {
//         width: 350px;
//         padding-bottom: 20px; 
//         box-sizing: border-box;
//         border-radius: 6px;
//         margin: 5px 0;
//         padding: 14px 16px;
//         border: 2px solid #dddfe2;
//     }

//     .container.button {
//       cursor: pointer;
//       font-family:system-ui;
//       font-weight: 600;
//       border: none;
//       background-color: #448cf8;
//       color: white;
//       width: 100%;
//       height: 2.4em;
//       line-height: 48px;
//       margin-top: 20px;
//       font-size: 20px;
//       border-radius: 6px;
//       padding: 0 16px;
//   }
// `;

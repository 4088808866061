import { now } from "lodash";
import { useEffect, useState } from "react";
import { useActionData, useNavigation, useOutletContext} from 'react-router';
import { useNavigate, Form } from 'react-router-dom';
import { Container, Input, Button, Table, Accordion, Icon, Header, TextArea} from 'semantic-ui-react';
import '../styles/SettingsViewStyles/settings.css'
import { getPgData, postPgData, patchPgData } from '../data/rdsClient';
import { Avatar, Typography, Card, CardHeader, 
CardContent, CardMedia, IconButton, Box, useMediaQuery, Divider} from '@mui/material';



// add a function for parsing the article timestamps
const { DateTime } = require('luxon');
  // add a function for parsing the article timestamps
  function parseTimestamp(timestamp) {
    const dt = DateTime.fromISO(timestamp, { zone: 'utc' });// Create a Luxon DateTime object from the timestamp
    const now = DateTime.utc();// Get the current UTC time
    // for BalanceView, always show date and time
    // const diffInDays = now.diff(dt, 'days').toObject().days;// Calculate the difference in days between now and the timestamp
    // if (diffInDays >= 1) {// If the difference is greater than or equal to 1 day, display m/d/y format
    //   return dt.toFormat('LL/dd/yyyy');
    // } else {
      const dayOfWeek = dt.toFormat('EEEE');// Get the day of the week and format it in English
      const timeOfDay = dt.toFormat('hh:mm a');// Get the time of day in 12-hour HH:mm a format (with AM/PM)
      const timeZoneName = dt.toFormat('ZZZZ');// Get the English time zone name
      return `${dayOfWeek}, ${timeOfDay} ${timeZoneName}`;
      //}
    }
    // const timestamp = '2023-09-13T23:03:53.011426+00:00';
    // const result = parseTimestamp(timestamp);
    // console.log(result); // Example output: "Wednesday, 11:03 PM UTC"


export const BalanceView = () => {

    const navigation = useNavigation();
    const navigate = useNavigate();
    const session = useOutletContext();
    console.log('session:',session);
    const isLoading =
    navigation.state === 'loading' || navigation.state === 'submitting';

    const [tokenPurchaseData, setTokenPurchaseData] = useState();
    const [tokensCashedData, setTokensCashedData] = useState();
    const [articlesSoldData, setArticlesSoldData] = useState();
    const [articlesBoughtData, setArticlesBoughtData] = useState();
    
  
    const data = useActionData();  

    // Hard coded user public info parameters
    const user_name = session.data.session.userPublic.name;
    console.log('BalanceView user_name: ',user_name)
    const user_bio = session.data.session.userPublic.bio;
    console.log('BalanceView user_bio: ',user_bio)
    const user_avatar_url = session.data.session.userPublic.profile_pic_url;
    console.log('BalanceView user_avatar_url: ',user_avatar_url)


    const [activeIndex, setActiveIndex] = useState(4); //start with an unused value

    const handleAccordionClick = (index) => { // failing to handle clicks?
        if (index==0){
            fetchTokenPurchases();
            console.log('tokenPurchaseData: ', tokenPurchaseData);
        }else if(index==1){
            fetchTokensCashed();
            console.log('tokensCashedData',tokensCashedData);
        }else if(index==2){
            fetchArticlesSold();
            console.log('articlesSoldData',articlesSoldData);
        }else if(index==3){
            fetchArticlesBought();
            console.log('articlesBoughtData',articlesBoughtData);
        }else{
            console.log('error, invalid handleAccordionClick index: ', index);
        }
        setActiveIndex(index);
        console.log(activeIndex)
    };

    // todo: this keys off user_email isntead of user_id, because of missing user_id on registration.
    // we'd like that to be different
    async function fetchTokenPurchases() {
        try {
            const { data: TokenPurchaseData, error: TokenPurchaseError }  = await getPgData(`/token_purchases?user_email=eq.${session.data.session.userPrivate.email}&order=internal_transaction_id.desc`, {}, session);
            if (TokenPurchaseError) {
                console.error('Error:', TokenPurchaseError);
                console.log(TokenPurchaseError.message);
                return { error: TokenPurchaseError.message };
              }
            console.log('getPgData - TokenPurchaseData:', TokenPurchaseData);
            setTokenPurchaseData(TokenPurchaseData);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async function fetchTokensCashed() {
        try {
            const { data: TokensCashedData, error: TokensCashedError }  = await getPgData(`/tokens_cashed_by_author?user_id=eq.${session.data.session.userPrivate.id}&order=internal_transaction_id.desc`, {}, session);
            if (TokensCashedError) {
                console.error('Error:', TokensCashedError);
                console.log(TokensCashedError.message);
                return { error: TokensCashedError.message };
              }
            console.log('getPgData - TokensCashedData:', TokensCashedData);
            setTokensCashedData(TokensCashedData);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async function fetchArticlesSold() {
        try {
            const { data: ArticlesSoldData, error: ArticlesSoldError } =
            // get all the sales of articles you've published
             await getPgData(`/articleboughtby?uid_of_article_author=eq.${session.data.session.userPrivate.id}`
                , {}, session);
            if (ArticlesSoldError) {
                console.error('Error:', ArticlesSoldError);
                console.log(ArticlesSoldError.message);
                return { error: ArticlesSoldError.message };
              }
            console.log('getPgData - ArticlesSoldData:', ArticlesSoldData);
            setArticlesSoldData(ArticlesSoldData);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
    
    async function fetchArticlesBought() {
        try {
            const { data: ArticlesBoughtData, error: ArticlesBoughtError }  =
             await getPgData(`/articleboughtby?purchasing_user_id=eq.${session.data.session.userPrivate.id}`
                , {}, session);
            if (ArticlesBoughtError) {
                console.error('Error:', ArticlesBoughtError);
                console.log(ArticlesBoughtError.message);
                return { error: ArticlesBoughtError.message };
              }
            console.log('getPgData - ArticlesBoughtData:', ArticlesBoughtData);
            setArticlesBoughtData(ArticlesBoughtData);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }


    useEffect(() => {
        console.log('BalanceView useEffect');

    }, []); 

    return(


    <div style={{height: '100%', backgroundColor: '#0f0f0f', color: 'white', paddingTop: '40px', paddingBottom: '40px'}}>
        <Container text>
            <Header as='h2' inverted textAlign="center">
                Account Balances
                <Header.Subheader>View your Token Balance, Earnings, and Purchase History.</Header.Subheader>
            </Header>

            <Header as='h3' inverted>Current Token Balance: {session.data.session.userPrivate.balance}</Header>
            <Button type='submit' onClick={() => navigate('/buytokens')} color="blue">Buy Tokens</Button>
            <Header as='h3' inverted>Current Uncashed Earnings in Tokens: {session.data.session.userPrivate.earnings_total - session.data.session.userPrivate.payouts_total}</Header>
            <Header as='h3' inverted>Total Lifetime Earnings in Tokens: {session.data.session.userPrivate.earnings_total}</Header>
            <Button type='submit' onClick={() => navigate('/cashout')} color="blue">Cash Out Article Sales</Button>


            <Header as='h3' inverted>Purchase and Earnings History:</Header>
            
            <Accordion inverted>
            <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={() =>handleAccordionClick(0)}
            >
            <Icon name='dropdown'/>
            Token Purchases
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
            <>
                <Table celled fixed padded selectable basic='very' inverted collapsing>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Tokens Purchased</Table.HeaderCell>
                            <Table.HeaderCell>SPN Fee</Table.HeaderCell>
                            <Table.HeaderCell>PayPal Fee</Table.HeaderCell>
                            <Table.HeaderCell>Total Charge</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {tokenPurchaseData?(tokenPurchaseData.map(tpd => (
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    {parseTimestamp(tpd.time)}
                                </Table.Cell>
                                <Table.Cell>
                                    {tpd.tokens_added}
                                </Table.Cell>
                                <Table.Cell>
                                    {tpd.spn_fee}
                                </Table.Cell>
                                <Table.Cell>
                                    {tpd.external_fee}
                                </Table.Cell>
                                <Table.Cell>
                                    {tpd.total_amount}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    ))):(
                        <Table.Body>
                            <Table.Row>
                                <Table.HeaderCell>Loading...</Table.HeaderCell>
                            </Table.Row>
                        </Table.Body>
                    )} 
                </Table>
            </>

            </Accordion.Content>

            <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={() =>handleAccordionClick(1)}
            >
            <Icon name='dropdown' />
            Article Earnings Cashed Out
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
            <>
                <Table celled fixed padded selectable basic='very' inverted collapsing>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Time Initiated</Table.HeaderCell>
                            <Table.HeaderCell>Tokens Cashed</Table.HeaderCell>
                            <Table.HeaderCell>Time Completed</Table.HeaderCell>
                            <Table.HeaderCell>Total Paid Out by SPN</Table.HeaderCell>
                            <Table.HeaderCell>External Payment ID</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {tokensCashedData?(tokensCashedData.map(tcd => (
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    {parseTimestamp(tcd.time_initiated)}
                                </Table.Cell>
                                <Table.Cell>
                                    {tcd.cashed_to_user}
                                </Table.Cell>
                                <Table.Cell>
                                    Todo: pending PayPal integration
                                </Table.Cell>
                                <Table.Cell>
                                    Todo: pending PayPal integration
                                </Table.Cell>
                                <Table.Cell>
                                    Todo: pending PayPal integration
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                        
                    ))):(
                    <Table.Body>
                        <Table.Row>
                            <Table.HeaderCell>Loading...</Table.HeaderCell>
                        </Table.Row>
                    </Table.Body>
                    )} 
                </Table>
            </>
            </Accordion.Content>

            <Accordion.Title
                active={activeIndex === 2}
                index={2}
                onClick={() =>handleAccordionClick(2)}
            >
            <Icon name='dropdown' />
            Article Sales
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
            <>
                <Table celled fixed padded selectable basic='very' inverted collapsing>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Article</Table.HeaderCell>
                            <Table.HeaderCell>Purchased By</Table.HeaderCell>
                            <Table.HeaderCell>Purchase Time</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {articlesSoldData?(articlesSoldData.map(asd => (
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as='h4'>
                                        <Header.Content>
                                        <a href={`/${asd.article_id}`}>{asd.article_title}</a>
                                        <Header.Subheader>
                                        <a href={`/user/${asd.uid_of_article_author}`}>Written by {asd.author_name}</a>
                                        </Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>
                                    Purchased By: <a href={`/user/${asd.purchasing_user_id}`}>{asd.purchasing_user_name}</a>
                                </Table.Cell>
                                <Table.Cell>
                                    At {parseTimestamp(asd.when)}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    ))):(
                    <Table.Body>
                        <Table.Row>
                            <Table.HeaderCell>Loading...</Table.HeaderCell>
                        </Table.Row>
                    </Table.Body>
                    )} 
                </Table>
            </>
            </Accordion.Content>

            <Accordion.Title
                active={activeIndex === 3}
                index={3}
                onClick={() =>handleAccordionClick(3)}
            >
            <Icon name='dropdown' />
            Articles Bought
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 3}>
            <>  
                <Table celled fixed padded selectable basic='very' inverted collapsing>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Article</Table.HeaderCell>
                            <Table.HeaderCell>Purchased By</Table.HeaderCell>
                            <Table.HeaderCell>Purchase Time</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {articlesBoughtData?(articlesBoughtData.map(abd => (
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as='h4'>
                                        <Header.Content>
                                        <a href={`/${abd.article_id}`}>{abd.article_title}</a>
                                        <Header.Subheader>
                                        <a href={`/user/${abd.uid_of_article_author}`}>Written by {abd.author_name}</a>
                                        </Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>
                                    Purchased By: <a href={`/user/${abd.purchasing_user_id}`}>{abd.purchasing_user_name}</a>
                                </Table.Cell>
                                <Table.Cell>
                                    At: {parseTimestamp(abd.when)}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    ))):(
                    <Table.Body>
                        <Table.Row>
                            <Table.HeaderCell>Loading...</Table.HeaderCell>
                        </Table.Row>
                    </Table.Body>
                    )} 
                </Table>
            </>
            </Accordion.Content>
            </Accordion>
        </Container>
    </div>
    )
}
// define an rpc/cashout that writes to tokens_cashed and updates userPrivate

/**
 * write a postgresql function cash_tokens(), it should insert a row into the table
 * tokens_cashed_by_author (all values will be provided), and then update the table userprivate,
 * setting the columns payouts_total and withdrawal_ids to input values.
 * The table tokens_cashed_by_author has the following structure:
 * spn.tokens_cashed_by_author
(
    internal_transaction_id bigint NOT NULL DEFAULT nextval('spn.tokens_cashed_by_author_internal_transaction_id_seq'::regclass),
    time_initiated timestamp with time zone NOT NULL DEFAULT now(),
    user_id bigint NOT NULL,
    uncashed_earnings_before_cashing bigint NOT NULL,
    uncashed_earnings_after_cashing bigint NOT NULL,
    cashed_to_user bigint NOT NULL,
    "cashed_to_SPN" bigint NOT NULL,
    total_cashed bigint NOT NULL,
    time_completed timestamp with time zone,
    external_transaction_id text COLLATE pg_catalog."default",
    is_complete boolean NOT NULL DEFAULT false,
    CONSTRAINT tokens_cashed_by_author_pkey PRIMARY KEY (internal_transaction_id)
)
 */
// Certainly! Below is the PostgreSQL function cash_tokens() that inserts a row into the tokens_cashed_by_author table and updates the userprivate table:
/*
CREATE OR REPLACE FUNCTION spn.cash_tokens(
    p_internal_transaction_id bigint,
    p_time_initiated timestamp with time zone,
    p_user_id bigint,
    p_uncashed_earnings_before_cashing bigint,
    p_uncashed_earnings_after_cashing bigint,
    p_cashed_to_user bigint,
    p_cashed_to_SPN bigint,
    p_total_cashed bigint,
    p_time_completed timestamp with time zone,
    p_external_transaction_id text,
    p_is_complete boolean,
    p_payouts_total bigint,
    p_withdrawal_ids bigint[]
) RETURNS VOID AS $$
BEGIN
    -- Insert a row into tokens_cashed_by_author
    INSERT INTO spn.tokens_cashed_by_author(
        internal_transaction_id,
        time_initiated,
        user_id,
        uncashed_earnings_before_cashing,
        uncashed_earnings_after_cashing,
        cashed_to_user,
        cashed_to_SPN,
        total_cashed,
        time_completed,
        external_transaction_id,
        is_complete
    )
    VALUES (
        p_internal_transaction_id,
        p_time_initiated,
        p_user_id,
        p_uncashed_earnings_before_cashing,
        p_uncashed_earnings_after_cashing,
        p_cashed_to_user,
        p_cashed_to_SPN,
        p_total_cashed,
        p_time_completed,
        p_external_transaction_id,
        p_is_complete
    );

    -- Update the userprivate table
    UPDATE spn.userprivate
    SET
        payouts_total = p_payouts_total,
        withdrawal_ids = p_withdrawal_ids
    WHERE id = p_user_id;

    -- Commit the transaction
    COMMIT;
END;
$$ LANGUAGE plpgsql;

*/
/**
 * This function takes the input values and inserts a new row into the tokens_cashed_by_author table. 
 * It then updates the userprivate table with the provided p_payouts_total and p_withdrawal_ids values 
 * for the specified p_user_id. The transaction is committed at the end of the function. Adjust the 
 * function parameters and data types as needed based on your specific requirements.
 */







// Connect cashOutView to it
// Make a cashout_email in server
// Connect it to cashOutView 


import { useState, useEffect, useRef } from "react";
import { Container, Input, Button, Table, Accordion, Icon, Header, TextArea} from 'semantic-ui-react';

import { useNavigation, useOutletContext } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { getPgData, patchPgData, postPgData } from '../data/rdsClient';

// // Custom component to wrap the PayPalButtons and show loading spinner
// const ButtonWrapper = ({ showSpinner }) => {
//     const [{ isPending }] = usePayPalScriptReducer();
//     const session = useOutletContext(); // Capture session here
//     const navigate = useNavigate(); // Capture navigate here

//     const onApproveWrapper = (data) => {
//         onApprove(data, session, navigate); // Pass both data, session, and navigate to onApprove
//     };

//     return (
//         <>
//             { (showSpinner && isPending) && <div className="spinner" /> }
//             <div style={{height: '100%', backgroundColor: '#0f0f0f', color: 'white', paddingTop: '40px', paddingBottom: '40px'}}>
//             <Container text>
//                 <Header as='p' inverted textAlign="center" dividing>Buy Tokens</Header>
//                 <p textAlign="left" dividing>When you make a purchase, the Bank's transaction fees and $1 are deducted from the purchase price before adding to your Account Balance.</p>
//                 <p textAlign="left" dividing>For a more complete explanation, see below.</p> 
//                 <Header as='p' inverted textAlign="center" dividing>You will be charged $20.</Header>
//                 <PayPalButtons
//                     style={style}
//                     disabled={false}
//                     forceReRender={[style]}
//                     fundingSource={undefined}
//                     createOrder={createOrder}
//                     onApprove={onApproveWrapper} // Use the wrapper function
//                 />
//                 <h2 textAlign="left" dividing>Your Account Balance is funded by purchasing Tokens using your credit card or PayPal account.</h2>
//                 <p textAlign="left" dividing>The cost of the Bank's fees plus $1 is deducted from your purchase to determine your Account Balance.</p>
//                 <p textAlign="left" dividing>So, for example, if you fund your account with a $20.00 purchase using PayPal, and the transaction fee to PayPal is $1.11, your Account Balance will show 1789 Tokens.</p>
//                 <p textAlign="left" dividing>In this example, From the $20.00 charge:<br></br>
//                 - $1.11 is deducted for PayPal’s transaction fee<br></br>
//                 - $1.00 is deducted to pay Single Pay News (aka SPN).<br></br>
//                 - The remaining $17.89 is used to purchase 1789 Tokens.<br></br>
//                 - Those 1789 Tokens are added to your account balance.<br></br>
//                 </p>
//                 <h2 textAlign="left" dividing>There are no refunds.</h2>
//                 <p textAlign="left" dividing>Once you complete a transaction to purchase Tokens and Tokens are credited to your account, the transaction is complete. The fee is paid to the credit card company or PayPal, $1 is paid to SPN, and the remainder is spent to purchase the Tokens shown in your Account Balance. The Tokens in your Account Balance are not refundable, not transferrable, and have no cash value. The tokens can only be used to purchase Paid Posts on SPN. If you engage in conduct that causes you to be banned from SPN, you will also forfeit any Tokens in your Account Balance to SPN.</p>
//             </Container>
//             </div>
//         </>
//     );
// }


// async function handleSubmitCashoutForm(requesting_cashout, session, navigate){
//     // known pre-cashing facts:
//     const earnings_total = session.data.session.userPrivate.earnings_total; // earnings is in units of articles, not $ or tokens
//     const payouts_total = session.data.session.userPrivate.payouts_total;
//     // calculated pre-cashing facts:
//     const uncashed_earnings_before_cashing = earnings_total - payouts_total;
//     const uncashed_earnings_after_cashing = earnings_total - payouts_total - requesting_cashout;
//     // calculated in-cashing facts:
//     const cashed_to_user = requesting_cashout; // units already in token as penny
//     // TODO: Hmmmmmm....
//     const cashed_to_SPN = requesting_cashout*(5/20); // units already in token as penny
//     // sanity check
//     if ((cashed_to_user+cashed_to_SPN)>(requesting_cashout)){ // units already in token as penny
//         window.alert('something went wrong');
//         return 'something went wrong'
//     }

//     // 1) post to tokens_cashed_by_author
//     const { data: tokens_cashed_by_author_data, error: tokens_cashed_by_author_error } = await postPgData(
//         `/tokens_cashed_by_author`, 
//         {
//             //internal_transaction_id: default,
//             time_initiated: 'NOW()',
//             user_id: session.data.session.userPublic.id,
//             uncashed_earnings_before_cashing: uncashed_earnings_before_cashing,
//             uncashed_earnings_after_cashing: uncashed_earnings_after_cashing,
//             cashed_to_user: cashed_to_user,
//             cashed_to_SPN: cashed_to_SPN,
//             total_cashed: cashed_to_user+cashed_to_SPN,
//             time_completed: null,
//             external_transaction_id: null,
//             is_complete: false
//         }, 
//         session
//         );//trying to use session.data.user.id causes err Can't find variable: session
//     if (tokens_cashed_by_author_error) {
//         console.error('Error:', tokens_cashed_by_author_error);
//         console.log(tokens_cashed_by_author_error.message)
//         return { tokens_cashed_by_author_error: tokens_cashed_by_author_error.message };
//     }
//     console.log('tokens_cashed_by_author_data:', tokens_cashed_by_author_data);// returns array

//     // 2) update userPrivate
//     console.log('session.data.session.userPrivate.withdrawal_ids: ', session.data.session.userPrivate.withdrawal_ids);
//     let updated_withdrawal_ids=session.data.session.userPrivate.withdrawal_ids;
//     // check for null (which is the case if this is their first cashout)
//     if(updated_withdrawal_ids == null){
//         updated_withdrawal_ids = [];
//     }
//     updated_withdrawal_ids.push(tokens_cashed_by_author_data[0].internal_transaction_id);
//     console.log('updated_withdrawal_ids: ',updated_withdrawal_ids);

//     const { data: patch_userprivate_data, error: patch_userprivate_error } = await patchPgData(
//         `/userprivate?id=eq.${session.data.session.userPrivate.id}`, 
//         {
//             payouts_total: session.data.session.userPrivate.payouts_total + cashed_to_user,
//             withdrawal_ids: updated_withdrawal_ids
//         }, 
//         session
//         );//trying to use session.data.user.id causes err Can't find variable: session
//     if (patch_userprivate_error) {
//         console.error('Error:', patch_userprivate_error);
//         console.log(patch_userprivate_error.message)
//         return { patch_userprivate_error: patch_userprivate_error.message };
//     }
//     console.log('patch_userprivate_data:', patch_userprivate_data);

//     // update stored session
//     let newSessionData = session;
//     newSessionData.data.session.userPrivate = patch_userprivate_data[0];
//     session.login(newSessionData); // todo: is this updating session?

//     // 3] alert user
//     window.alert('Earnings cashed; Check you email for confirmation!');
//     // if I navigate will it rerender?
//     navigate('/cashout');
// }


// export default function CashoutForm() {
//     const navigate = useNavigate();
//   const session = useOutletContext();
//   const [name, setname] = useState(session.data.session.userPublic.name);
//   const [earnings_total, setearnings_total] = useState(session.data.session.userPrivate.earnings_total);
//   const [payouts_total, setpayouts_total] = useState(session.data.session.userPrivate.payouts_total);
//   const [requesting_cashout, setrequesting_cashout] = useState(0);
//   const earnings_totalAsNumber = Number(earnings_total);
//   return (
//     <>
//     {session.data.session?(
//         <>
//             <label>
//                 Hi {name}, here are your totals:
//             </label>
//             <br></br>
//             <label>
//                 Total Earnings:
//                 <h3>{earnings_total}</h3>
//             </label>
//             <label>
//                 Total Payouts:
//                 <h3>{payouts_total}</h3>
//             </label>
//             <label>
//                 Avaliable to Cash:
//                 <h3>{earnings_total-payouts_total}</h3>
//             </label>
//             <label>
//                 Requesting to Cash:
//                 <br></br>
//                 <input
//                 value={requesting_cashout}
//                 onChange={e => setrequesting_cashout(parseFloat(e.target.value))}
//                 type="number"
//                 />
//                 <br></br>
//                 <button onClick={() => (earnings_total-payouts_total >= requesting_cashout)? (handleSubmitCashoutForm(requesting_cashout, session, navigate)):(window.alert(`Requesting to cash ${requesting_cashout}; exceeds ${earnings_total-payouts_total} avaliable to cash.`))}>
//                 Cash Out Earnings
//                 </button>
//             </label>
//         </>
//     ):(
//         <>
//             <Header>Something's up, try logging in again.</Header>
//         </>
//     )}
    
//     </>
//   );
// }

export const CashOutView = () => {
  // const navigate = useNavigate();
  const session = useOutletContext();
  const [name, setname] = useState(session.data.session.userPublic.name);
  const [earnings_total, setearnings_total] = useState(session.data.session.userPrivate.earnings_total);
  const [payouts_total, setpayouts_total] = useState(session.data.session.userPrivate.payouts_total);
  const [requesting_cashout, setrequesting_cashout] = useState(0);
  const earnings_totalAsNumber = Number(earnings_total);
    
    // Create our number formatter.
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  
  console.log(formatter.format(2500)); /* $2,500.00 */
  
    return (
        <>
        <div style={{height: '100%', backgroundColor: '#0f0f0f', color: 'white', paddingTop: '40px', paddingBottom: '40px'}}>
            <Container text>
            <div style={{ maxWidth: "750px", minHeight: "200px" }}>
                {/* <CashoutForm></CashoutForm> */}

    {session.data.session?(
        <>
            <label>
                Hi {name}, here are your totals:
            </label>
            <br></br>
            <label>
                Total Earnings:
                <h3>{formatter.format(earnings_total/100)}</h3>
            </label>
            <label>
                Total Payouts:
                <h3>{formatter.format(payouts_total/100)}</h3>
            </label>
            <label>
                Avaliable to Cash:
                <h3>{formatter.format((earnings_total-payouts_total)/100)}</h3>
            </label>
            </>):(<p>You must be logged in to see your earnings.</p>)}
            <br></br>
                <p>Earnings from sales of articles are paid out automatically on the 1st and 15th of each month.</p>
                <p>Payments will only be made to a valid PayPal account.  We assume the email you used to register with singlepaynews is also the email for your PayPal account.  If you registered with an email address that is not tied to your valid PayPal account, please send:</p>
                    <p>1: your registered email at singlepaynews, and </p>
                    <p>2: the email associated with your paypal acount </p>
                    <p>to <a href='mailto:singlepaynews@gmail.com'>singlepaynews@gmail.com</a> so we can pay you.</p>
                <p>Once a payment to you has cleared via PayPal, your avaliable earnings will be updated accordingly.</p>
            </div>
            </Container>
            </div>
        </>
    );
};

import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useOutletContext } from 'react-router';
import styled from 'styled-components';
import {Container, Avatar, Typography, Card, CardHeader, 
CardContent, CardMedia, IconButton, Box, Button, useMediaQuery, Divider} from '@mui/material';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// Grant I want all this to be in a collapsible menu to make space for a searchbar
// I'm using this guide: https://mui.com/material-ui/react-menu/
import MenuIcon from '@mui/icons-material/Menu';
import * as React from 'react'; // todo: can this line be removed?  why not?
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Image } from "semantic-ui-react";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';



export const Navigation = () => {
  const session = useOutletContext(); // Use the useOutletContext hook to get the latest context value
  
  useEffect(() => {
    console.log('Navigation rendered with session= ', session);
    // Additional logic or side effects based on the session can be added here
  }, [session]); // only fires when the object itself changes, we're changing a prop of the object when we change Balance


// find a way to trigger rerender when a prop of the outletcontext changes


    return (
// TopNavigation and BottomNavigation work differently.  MUI provides a special BottomNavigation component prop with
// its own ability to perform navigation/routing: https://mui.com/material-ui/guides/routing/ 
// TopNavigation is a custom component defined by us that uses <Link> to navigate.
      <>
      <MuiTopNavigation className='top-navigation' />
      <MuiBottomNavigation className='bottom-navigation'/>
      </>
    );
  };

const MuiTopNavigation = () => {
  // Update the Navigation component to access the session.uncheckedNotifs from the context.
  const session = useOutletContext(); 
  const [isLoading, setLoading] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  // state var to update balance live when prop of context session changes
  // we probably want to use this approach for notifications as well
  const [balance, setBalance] = useState(session?.data?.session?.userPrivate?.balance || null);

  
  useEffect(() => {
    console.log('Navigation rendered with session= ', session);
    // Update the balance state when the balance property changes
    if (session?.data?.session?.userPrivate?.balance !== balance) {
      setBalance(session?.data?.session?.userPrivate?.balance);
    }
  }, [session?.data?.session?.userPrivate?.balance, balance, session?.uncheckedNotifs]);

  const onLogout = async () => {
    setLoading(true);
    //console.log('nav logout')
    await session.logout(); // session.logout is defined in useSession() in app.js
    setLoading(false);
    navigate('/');
  }


  const handleSearchToggle = () => {
    setIsSearchOpen(!isSearchOpen);
    setSearchQuery('');
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = () => {
    if (searchQuery.trim()) {
      navigate(`/search/${searchQuery}`);
      handleSearchToggle();
    }
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  return (
    <>
    <StyledNavigation className='top-navigation' sx={{ padding: '30px 30px 30px 30px', width: '100%', position: 'fixed', top: 0, backgroundColor: '#000000'}}>
    
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {session.data?.session?.userPublic ? ( // if signed in
        session.data?.session?.userPublic?.profile_pic_url ? ( //and has a profile picture
          <Avatar 
            src={session.data.session.userPublic.profile_pic_url} 
            sx={{width: '25px', height: '25px'}} 
            onClick={() => navigate('/account')}
            className="clickable-icon"
          ></Avatar>
        ):( // signed in, no profile picture
          <IconButton 
            sx={{color: 'white', padding: 0, float: 'left', width: '25px', height: '25px'}} // width and height appear to have no effect here
            onClick={() => navigate('/account')}    
            className="clickable-icon"
          >
            <AccountCircleIcon sx={{color: 'white', padding: 0, float: 'left', width: '25px', height: '25px'}}/>
          </IconButton>
        )
      ):( // not signed in
        <IconButton 
          sx={{color: 'white', padding: 0, float: 'left', width: '25px', height: '25px'}} // width and height appear to have no effect here
          onClick={() => navigate('/login')}    
          className="clickable-icon"
        >
          <Avatar src={'/logo192.png'} sx={{color: 'white', padding: 0, float: 'left', width: '25px', height: '25px'}}/>
        </IconButton>
      )}
    </div>

    <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {isSearchOpen ? (
          <SearchContainer>
            <SearchInput
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              onKeyPress={handleSearchKeyPress} //deprecated, and yet it works
              placeholder="Search..."
              style={{ padding: '5px', marginRight: '10px' }}
            />
            <IconButton onClick={handleSearchSubmit}>
              <SearchIcon sx={{color: 'black'}} />
            </IconButton>
            <IconButton onClick={handleSearchToggle}>
              <CloseIcon sx={{color: 'black'}} />
            </IconButton>
          </SearchContainer>
        ) : (
          <IconButton onClick={handleSearchToggle}>
            <SearchIcon sx={{color: 'white'}} />
          </IconButton>
        )}
        
  { !isSearchOpen && session.data ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {balance !== null && <Link to="/balance" style={{ marginRight: '10px' }}>Balance: {balance}</Link>}
      <Link to="/settings" style={{ marginRight: '10px' }}><SettingsIcon /></Link>
      <Link to="/notifications" style={{ display: 'flex', alignItems: 'center' }}>
        <NotificationsIcon style={{ marginRight: '5px' }} />
        <span>{session.uncheckedNotifs}</span>
      </Link>
      <button disabled={isLoading} onClick={onLogout} id='logout-button'>
        {isLoading ? 'loading...' : 'Log Out'}
      </button>
    </div>
  ) : (<></>)}

{ !isSearchOpen && !session.data ? (
    <div>
      <Link to="/about">About</Link>
      <Link to="/signup">Sign Up</Link>
      <Link to="/login">Login</Link>
    </div>
  ) : (<></>)}
</div>


    </StyledNavigation>
    </>
  );
}

const MuiBottomNavigation = () => {
  const [value, setValue] = useState(0);
  return(
  // Bottom navigation sticks to bottom of screen
  // Don't change MUI's sx styling for BottomNaviagtion 
  <BottomNavigation sx={{ padding: '30px 30px 30px 30px', width: '100%', position: 'fixed ', bottom: 0, backgroundColor: '#000000', zIndex:6}} value={value} 
  onChange={(event, newValue) => {
    setValue(newValue)
  }}
  >
    <BottomNavigationAction 
    label=''
    value='home' 
    icon={<HomeIcon sx={{color: 'white'}}/>} 
    component={Link}
    to='/'
    className='bottom-icon'
    style={{padding: 0}}
    />


    <BottomNavigationAction 
    label='' 
    icon={<AddCircleIcon sx={{color: '#00ff00'}}/>}
    value='account' 
    component={Link}
    to='create'
    className='bottom-icon'
    style={{padding: 0}}
    />

    {/* <BottomNavigationAction 
    label='' 
    icon={<AccountCircleIcon sx={{color: 'white', padding: 0}}/>} 
    value='account'
    component={Link}
    to='/account'
    className='bottom-icon'
    style={{padding: 0}}
    /> */}
    <BottomNavigationAction 
    label='' 
    icon={<AttachMoneyIcon sx={{color: 'white', padding: 0}}/>} 
    value='account'
    component={Link}
    to='/balance'
    className='bottom-icon'
    style={{padding: 0}}
    />
    {/* AttachMoneyIcon */}
  </BottomNavigation>
  );
}

const StyledNavigation = styled.div`
  position: fixed;
  width: 100%; 
  background: black;
  display: flex;
  padding: 10px 6%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
  font-family: system-ui;
  flex-shrink: 2;
  font-size:18px;
  /* white-space: nowrap; Added property to prevent wrapping, but I'd rather it wrap than push the avatar offscreen */
  z-index:6;
  a {
    color: #ffffff;
    margin: 10px 10px;
    text-decoration: none;
  }

  #logout-button{
    cursor: pointer;
    font-family:system-ui;
    border: none;
    background-color: #448cf8;
    color: white;
    width: 4.6em;
    height: 1.4em;
    font-size: 18px;
    border-radius: 12px;
    padding:0px;
  }

  .clickable-icon {
    cursor: pointer;
    &:hover {
      opacity: 0.8; // Adjust the opacity as needed
    }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  border-radius: 25px;
  padding: 0px;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  padding: 5px;
  margin-right: 10px;
  border-radius: 25px;
  font-size: 16px;
  width: 150px;
`;
import { now } from "lodash";
import { useEffect, useState } from "react";
import { useActionData, useNavigation, useOutletContext} from 'react-router';
import { useNavigate, Form } from 'react-router-dom';
import { Container, Input, Button, Table, Accordion, Icon, Header, TextArea} from 'semantic-ui-react';
import '../styles/SettingsViewStyles/settings.css'
import { getPgData, postPgData, patchPgData } from '../data/rdsClient';
import { Avatar, Typography, Card, CardHeader, 
CardContent, CardMedia, IconButton, Box, useMediaQuery, Divider} from '@mui/material';

// TODO: review this file in detail; remove anything unused.  As of this comment (Dec.17 2023), it all works without bugs.

// add a function for parsing the article timestamps
const { DateTime } = require('luxon');
  // add a function for parsing the article timestamps
  function parseTimestamp(timestamp) {
    const dt = DateTime.fromISO(timestamp, { zone: 'utc' });// Create a Luxon DateTime object from the timestamp
    const now = DateTime.utc();// Get the current UTC time
    // for settingsview, always show date and time
    // const diffInDays = now.diff(dt, 'days').toObject().days;// Calculate the difference in days between now and the timestamp
    // if (diffInDays >= 1) {// If the difference is greater than or equal to 1 day, display m/d/y format
    //   return dt.toFormat('LL/dd/yyyy');
    // } else {
      const dayOfWeek = dt.toFormat('EEEE');// Get the day of the week and format it in English
      const timeOfDay = dt.toFormat('hh:mm a');// Get the time of day in 12-hour HH:mm a format (with AM/PM)
      const timeZoneName = dt.toFormat('ZZZZ');// Get the English time zone name
      return `${dayOfWeek}, ${timeOfDay} ${timeZoneName}`;
      //}
    }
    // const timestamp = '2023-09-13T23:03:53.011426+00:00';
    // const result = parseTimestamp(timestamp);
    // console.log(result); // Example output: "Wednesday, 11:03 PM UTC"


export const SettingsView = () => {

    //Introduce State for Error Handling
    const [errorMessage, setErrorMessage] = useState(null);
    const handleError = (message) => {setErrorMessage(message);};
    const resetError = () => {setErrorMessage(null);};
      // Step 1: Introduce State for Success Handling
    const [successMessage, setSuccessMessage] = useState(null);
    const handleSuccess = (message) => {setSuccessMessage(message);};
    const resetSuccess = () => {setSuccessMessage(null);};

    const navigation = useNavigation();
    const navigate = useNavigate();
    const session = useOutletContext();
    console.log('session:',session);
    const isLoading =
    navigation.state === 'loading' || navigation.state === 'submitting';
    const [userpublic,setUserPublic] = useState(session.data.session.userPublic); //todo: we can remove these statevars
    const [userprivate,setUserPrivate] = useState(session.data.session.userPrivate); 
    const [file,setFile] = useState();

    const [tokenPurchaseData, setTokenPurchaseData] = useState();
    const [tokensCashedData, setTokensCashedData] = useState();
    const [articlesSoldData, setArticlesSoldData] = useState();
    const [articlesBoughtData, setArticlesBoughtData] = useState();
    
    const checkFileProps = (e) => {
        // Not supported in Safari for iOS.
        const name = e.name ? e.name : 'NOT SUPPORTED';
        // Not supported in Firefox for Android or Opera for Android.
        const type = e.type ? e.type : 'NOT SUPPORTED';
        // Unknown cross-browser support.
        const size = e.size ? e.size : 'NOT SUPPORTED';
        console.log({e, name, type, size});

        const twoKb = 200000;
        const twoMb = 2000000;
        const fiveMb = 5000000;

        // this is triggering for fucckin' everything, jgp, png, all of it.
        // if (size == 'NOT SUPPORTED') {
        //     window.alert('Filetype not supported')
        //     return
        // }
        if (size < twoMb) {
            setFile(e);//.target.files[0])
        }else{
            handleError('File size must be <2 Mb')
        }
    }

    // Hard coded user public info parameters now draw from session
    //                 this.data.session.userPublic.name
    console.log('settingsView session.data.session.userPublic.name: ',session.data.session.userPublic.name)
    console.log('settingsView session.data.session.userPublic.bio: ',session.data.session.userPublic.bio)
    console.log('settingsView session.data.session.userPublic.profile_pic_url: ',session.data.session.userPublic.profile_pic_url)

    // Hard coded private info parameters
    let user_email = session.data.session.userPrivate.email ? session.data.session.userPrivate.email : 'loading';
    let user_phone = session.data.session.userPrivate ? session.data.session.userPrivate.phone_number : 'loading';
    let user_city = session.data.session.userPrivate ? session.data.session.userPrivate.city : 'loading';
    let user_state = session.data.session.userPrivate ? session.data.session.userPrivate.state : 'loading';
    let user_zip = session.data.session.userPrivate ? session.data.session.userPrivate.zip : 'loading';

    //Hard coded bank parameters
    let user_balance = session.data.session.userPrivate.balance;

    const [activeIndex, setActiveIndex] = useState(4); //start with an unused value

    const handleAccordionClick = (index) => { // failing to handle clicks?
        if (index==0){
            fetchTokenPurchases();
            console.log('tokenPurchaseData: ', tokenPurchaseData);
        }else if(index==1){
            fetchTokensCashed();
            console.log('tokensCashedData',tokensCashedData);
        }else if(index==2){
            fetchArticlesSold();
            console.log('articlesSoldData',articlesSoldData);
        }else if(index==3){
            fetchArticlesBought();
            console.log('articlesBoughtData',articlesBoughtData);
        }else{
            console.log('error, invalid handleAccordionClick index: ', index);
        }
        setActiveIndex(index);
        console.log(activeIndex)
    };


    // todo: this keys off user_email isntead of user_id, because of missing user_id on registration.
    // we'd like that to be different
    async function fetchTokenPurchases() {
        try {
            const { data: TokenPurchaseData, error: TokenPurchaseError }  = await getPgData(`/token_purchases?user_email=eq.${session.data.session.userPrivate.email}&order=internal_transaction_id.desc`, {}, session);
            if (TokenPurchaseError) {
                console.error('Error:', TokenPurchaseError);
                console.log(TokenPurchaseError.message);
                return { error: TokenPurchaseError.message };
              }
            console.log('getPgData - TokenPurchaseData:', TokenPurchaseData);
            setTokenPurchaseData(TokenPurchaseData);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }


    async function fetchTokensCashed() {
        try {
            const { data: TokensCashedData, error: TokensCashedError }  = await getPgData(`/tokens_cashed_by_author?user_id=eq.${session.data.session.userPrivate.id}&order=internal_transaction_id.desc`, {}, session);
            if (TokensCashedError) {
                console.error('Error:', TokensCashedError);
                console.log(TokensCashedError.message);
                return { error: TokensCashedError.message };
              }
            console.log('getPgData - TokensCashedData:', TokensCashedData);
            setTokensCashedData(TokensCashedData);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async function fetchArticlesSold() {
        try {
            const { data: ArticlesSoldData, error: ArticlesSoldError } =
            // get all the sales of articles you've published
             await getPgData(`/articleboughtby?uid_of_article_author=eq.${session.data.session.userPrivate.id}`
                , {}, session);
            if (ArticlesSoldError) {
                console.error('Error:', ArticlesSoldError);
                console.log(ArticlesSoldError.message);
                return { error: ArticlesSoldError.message };
              }
            console.log('getPgData - ArticlesSoldData:', ArticlesSoldData);
            setArticlesSoldData(ArticlesSoldData);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
    
    async function fetchArticlesBought() {
        try {
            const { data: ArticlesBoughtData, error: ArticlesBoughtError }  =
             await getPgData(`/articleboughtby?purchasing_user_id=eq.${session.data.session.userPrivate.id}`
                , {}, session);
            if (ArticlesBoughtError) {
                console.error('Error:', ArticlesBoughtError);
                console.log(ArticlesBoughtError.message);
                return { error: ArticlesBoughtError.message };
              }
            console.log('getPgData - ArticlesBoughtData:', ArticlesBoughtData);
            setArticlesBoughtData(ArticlesBoughtData);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    function getMetadataForFileList(fileList) {
        for (const file of fileList) {
          // Not supported in Safari for iOS.
          const name = file.name ? file.name : 'NOT SUPPORTED';
          // Not supported in Firefox for Android or Opera for Android.
          const type = file.type ? file.type : 'NOT SUPPORTED';
          // Unknown cross-browser support.
          const size = file.size ? file.size : 'NOT SUPPORTED';
          console.log({file, name, type, size});
        }
      }
























      const onSubmit = async(formData, session) => {
        console.log('SettingsView onSubmit called')
        try {
            // formData is passed as input const formData = await request.formData(); // Wait for formData to be available
            console.log('formData:', formData);
            // TODO: refactor to just use session, this is an artifact
            const actionSession = session; 
            console.log('actionSession',actionSession)
            const Token = actionSession.data.session.access_token
    
            const whichForm = formData.get('which_form');
            if (whichForm == 'form_public') {
                // Public Form Field Names:
                // session
                // name
                // avatar
                // bio
                //const avatarFile = formData.get('avatar')
                const formDataEscape = { 
                    // key must match userpublic DB param, value must match a formdata key.get()
                    id: actionSession.data.user.id,
                    // type"
                    // created"
                    updated: 'NOW()',
                    name: actionSession.data.session.userPublic.name,
                    profile_pic_url: formData.get('avatar'),
                    bio: formData.get('bio')
                    // articles_posted_for_sale"
                    // follower_ids"
                    // num_followers"
                    // following_ids"
                    // numfollowing"
                };   
    
                console.log('formDataEscape: ',formDataEscape)
                const file = formDataEscape.profile_pic_url
                /**
                 * File {name: 'B0A758F9-2FB8-4CED-B425-E6533FB9E004_1_105_c.jpeg', lastModified: 1702158765691, lastModifiedDate: Sat Dec 09 2023 13:52:45 GMT-0800 (Pacific Standard Time), webkitRelativePath: '', size: 123792, …}
    lastModified
    : 
    1702158794606
    lastModifiedDate
    : 
    Sat Dec 09 2023 13:53:14 GMT-0800 (Pacific Standard Time) {}
    name
    : 
    "B0A758F9-2FB8-4CED-B425-E6533FB9E004_1_105_c.jpeg"
    size
    : 
    123792
    type
    : 
    "image/jpeg"
    webkitRelativePath
    : 
    ""
    [[Prototype]]
    : 
    File
                 */
                console.log('formDataEscape.profile_pic_url: ',file) 
                // check for compliant filesize
                if(file.size > 2000000){
                    // Nevermind; must navigate in useEffect()  window.alert('file must be under 2Mb, settings not updated')
                    // data[0] is returning the updated userpublic from patchPgData
                    return 'err: file too large'; // have to catch this in useEffect
                }
    
                // check if the fields are blank
                // if (formDataEscape.name===""){formDataEscape.name=actionSession.data.session.userPublic.name} name is always set by session
                if (formDataEscape.bio===""){formDataEscape.bio=actionSession.data.session.userPublic.bio}
                // if a new profile pic was uploaded it's a file either way, but when I don't upload the file.name is blank
                if (file.name !== ""){
                    // upload to s3
                    let reqData = new FormData(); //https://www.youtube.com/watch?v=vVBqEYNXxy8, https://stackoverflow.com/questions/50774176/sending-file-and-json-in-post-multipart-form-data-request-with-axios/64613695#64613695
                    reqData.append('file', file);
                    console.log(reqData);
                    const options = { //Default options are marked with *
                        method: "POST", // *GET, POST, PUT, DELETE, etc.
                        mode: "cors", // no-cors, *cors, same-origin
                        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                        headers: {
                            'filepath': `user_${formDataEscape.id}_profile_picture_${file.name}` //https://www.youtube.com/watch?v=vVBqEYNXxy8
                        },
                        redirect: "follow", // manual, *follow, error
                        referrerPolicy: 'strict-origin-when-cross-origin', //"no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                        body: reqData 
                    }
                    const response = await fetch('https://singlepaynews.com/api/upload', options)//
                    console.log(response)
                    // make the new url for patch
                    formDataEscape.profile_pic_url = `https://spnimages.s3.us-west-2.amazonaws.com/user_${formDataEscape.id}_profile_picture_${file.name}`;
                } else {
                    // make the new url for patch
                    formDataEscape.profile_pic_url = actionSession.data.session.userPublic.profile_pic_url;
                }
                console.log(formDataEscape);
                // update userpublic
                const { data, error } = await patchPgData(`/userpublic?id=eq.${formDataEscape.id}`, formDataEscape, actionSession);//trying to use session.data.user.id causes err Can't find variable: session
                
                if (error) {
                    console.error('Error:', error);
                    console.log(error.message)
                    return { error: error.message };
                }
            
                console.log('patchPgData:', data);
                return {userPublic: data};
    
            } else if (whichForm == 'form_private') {
                // Private Form Field Names:
                // session
                // email
                // phone
                // city
                // state
                // zip
                const formDataEscape = {
                    // key must match userpublic DB param, value must match a formdata key.get()
                    id: actionSession.data.user.id,
                    //type: 
                    //created: 
                    updated: 'NOW()',
                    //profile_pic_url: 
                    //bio: 
                    //articles_posted_for_sale: 
                    email: actionSession.data.session.userPrivate.email,
                    phone_number: formData.get('phone'),
                    //password: 
                    //street_address: 
                    city: formData.get('city'),
                    state: formData.get('state'),
                    zip: formData.get('zip')
                    //location_id: 
                    //num_followers: 
                    //follower_ids: 
                    //num_likes_on_comments_this_user: 
                    //num_likes_on_articles_this_user: 
                    //balance: 
                    //earnings_total: 
                    //payouts_total: 
                    //deposit_ids: 
                    //withdrawal_ids: 
                    //purchase_ids: 
                    //sale_ids: 
                    //last_purchase_time: 
                    //credit_card: 
                    //bank_account: 
                    //paypal: 
                    //venmo: 
                    //articles_bought: 
                    //comments_paid: 
                    //users_liked: 
                    //locations_liked: 
                    //subjects_liked: 
                    //subjects_blocked: 
                    //locations_blocked: 
                    //users_blocked: 
                    //articles_liked: 
                    //comments_liked: 
                    //articles_posted_for_free: 
                    //comments_posted_for_free: 
                    //is_banned: 
                    //is_frozen: 
                };   
    
                console.log('formDataEscape: ',formDataEscape)
                // code: "PGRST105"
                // message: "Filters must include all and only primary key columns with 'eq' operators"
                // add horizontal filtering to patch https://postgrest.org/en/stable/references/api/tables_views.html#horizontal-filtering
                const { data, error } = await patchPgData(`/userprivate?id=eq.${formDataEscape.id}`, formDataEscape, actionSession);//trying to use session.data.user.id causes err Can't find variable: session
                
                if (error) {
                    console.error('Error:', error);
                    console.log(error.message)
                    return { error: error.message };
                }
            
                console.log('patchPgData:', data);
                return {userPrivate: data};
    
            } else {
                console.log('error: invalid form type')
                // todo: throw onSubmit error
            }
    
        } catch (error) {
            console.error('onSubmit error:', error);
            return { error: error.message };
        }
    }









  // seems to be most equivalent to useEffect() in old method
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const result = await onSubmit(formData, session); // Pass session as a parameter

    // Handle the result as needed
    console.log('handleFormSubmit result: ', result); // returns an object of format {articleData, updated_userPublic}, where articleData and updated_userPublic are both arrays with json at updated_userPublic[0]
        
    if (result=='err: file too large'){
        handleError('File size must be under 2 Mb; settings not updated');
        navigate('/settings');
    }else{
        if (result.userPrivate && !result.error) {
            console.log('result.userPrivate=',result.userPrivate);
            // update the stored session
            const new_session = session;
            new_session.data.session.userPrivate = result.userPrivate[0];
            console.log('new_session: ',new_session);
            session.login(new_session);
            // alert wingow, redirect back to settings
            handleSuccess('Settings Updated');
            navigate('/settings');
        } else if (result.userPublic && !result.error) {
            console.log('result.userPublic=',result.userPublic);
            // update stored session
            const new_session = session;
            new_session.data.session.userPublic = result.userPublic[0];
            console.log('new_session: ',new_session);
            session.login(new_session);
            // alert wingow, redirect back to settings
            handleSuccess('Settings Updated');
            navigate('/settings');
        } else if (result.error) {
            console.log('handleFormSubmit error: ', result.error)
            handleError('Something went wrong.  Try logging out, logging back in, and trying again?  If you\'re using microsoft edge some features don\'t work--try using chrome or firefox.')
        }
    }
  };









    useEffect(() => {
        console.log('SettingsView useEffect session: ', session);        
        console.log('userpublic: ',userpublic);
        console.log('userprivate: ',userprivate);
    }, []); 

    return(
    /**
     * Email (button change email)
     * Phone number
     * Legal First Name
     * Legal Last Name
     * street address
     * city
     * state
     * zip
     * debt or credit card number
     * Link bank account?
     * balance - box with total in account
     * deposits - boxes to show deposits in reverse order, date, amout,
     * withdrawl - amount, date reverse order
     * articles sold
     * 
     * freeze account button
    */

    //Left hand 
    /**
     * General - Email, First Name, Last Name, Phone number
     * Finances - Balance, Deposit, Withdrawls, Articles sold, Articles bought, Comments
     * 
    */

    <div style={{height: '100%', backgroundColor: '#0f0f0f', color: 'white', paddingTop: '40px', paddingBottom: '40px'}}>
        <Container text>
        <Header as='h1' inverted textAlign="center" style={{fontSize: '3rem'}} dividing>User Settings</Header>
        {/** Step 2: Displaying Error Messages in the UI */}
        {errorMessage && (
          <div className="error-message">
              <p>{errorMessage}</p>
              <button onClick={resetError}>Close</button>
          </div>
      )}
      {/** Step 2: Displaying Success Messages in the UI */}
      {successMessage && (
          <div className="success-message">
              <p>{successMessage}</p>
              <button onClick={resetSuccess}>Close</button>
          </div>
      )}

        <Header as='h2' inverted textAlign="center">
            Public Settings 
            <Header.Subheader>This information will be publicly displayed on your profile page.</Header.Subheader>
            <Header.Subheader>Link to your Public Profile: <a href={`https://singlepaynews.com/user/${session.data.session.userPublic.id}`}>{`https://singlepaynews.com/user/${session.data.session.userPublic.id}`}</a></Header.Subheader>
        </Header>


        <Form 
        style={{backgroundColor: '#ffffff', paddingTop: '10px', paddingBottom: '40px', paddingLeft: '10px', paddingRight: '10px', textAlign: 'center', borderRadius:'4px'}}
        method='post' 
        action='/settings'
        encType="multipart/form-data"
        onSubmit={handleFormSubmit}
        >
            <input disabled={isLoading} name='session' value={JSON.stringify(session)} type='hidden'></input>
            <input disabled={isLoading} name='which_form' value={'form_public'} type='hidden'></input>

                
                <Header as='h5' floated="left" style={{margin: '0.8rem 0'}}>Name: (You cannot change your name.)</Header>
                <Input name='name' disabled='true' size="small" placeholder={session.data.session.userPublic.name} value={session.data.session.userPublic.name} style={{width: '100%'}}></Input>

                <Avatar src={session.data.session.userPublic.profile_pic_url} alt={session.data.session.userPublic.profile_pic_url} sx={{width: '40px', height: '40px',  margin: 2}}></Avatar>

                <Header as='h5' floated="left"  style={{margin: '0.8rem 0'}}>Update Avatar: </Header>
                <p>Must be under 2mb</p>

                <Input type="file" name='avatar' 
                accept="image/*" size="small" 
                placeholder={''}
                style={{width: '100%'}}
                onChange={e => setFile(e.target.files[0])}//checkFileProps(e)}//setFile(e.target.files[0])}
                >

                </Input>

                {/* onChange={e => setFile(e.target.files[0])} */}
                {/* https://web.dev/articles/read-files */}
                
                <Header as='h5' floated="left" style={{margin: '0.8rem 0'}}>Bio: </Header>
                <TextArea name='bio' size="small" placeholder={session.data.session.userPublic.bio} style={{width: '100%', padding: '0.625rem', border:'1px solid rgba(34,36,38,.15)', borderRadius: '5px', resize:'vertical', height:'7rem'}}></TextArea>

                <br/>

                <Button color="blue" floated="left" disabled={isLoading} type='submit'>{isLoading ? 'loading...' : 'Update Public'}</Button>
        </Form>




        <Header as='h2' inverted textAlign="center">
            Private Settings 
            <Header.Subheader>This information will be only be shown to you, and may be used to help you recover your account.</Header.Subheader>
        </Header>

            <Form 
            style={{backgroundColor: '#ffffff', paddingTop: '10px', paddingBottom: '40px', paddingLeft: '10px', paddingRight: '10px', textAlign: 'center', borderRadius:'4px'}} 
            method='post' 
            action='/settings'
            onSubmit={handleFormSubmit}
            >
                    <input disabled={isLoading} name='session' value={JSON.stringify(session)} type='hidden'></input>
                    <input disabled={isLoading} name='which_form' value={'form_private'} type='hidden'></input>
                    
                    <Header as='h5' floated="left" style={{margin: '0.8rem 0'}}>Email: (you cannot change your email.)</Header>
                    <Input size="small" disabled='true' placeholder={user_email} value={user_email} name='email' style={{width: '100%'}}></Input>

                    <Header as='h5' floated="left" style={{margin: '0.8rem 0'}}>Phone Number where you can recieve texts: {user_phone}</Header>
                    <Input size="small" placeholder='(xxx)xxx-xxxx' name='phone' style={{width: '100%'}}></Input>
                    
                    <br/>
                    <br/>
                    <br/>

                    <Header as='h5' floated="left" style={{margin: '0.8rem 0'}}>City: {user_city}</Header>
                    <Input size="small" placeholder='City' name='city' style={{width: '100%'}}></Input>

                    <Header as='h5' floated="left" style={{margin: '0.8rem 0'}}>State: {user_state}</Header>
                    <Input size="small" placeholder='State' name='state' style={{width: '100%'}}></Input>

                    <Header as='h5' floated="left" style={{margin: '0.8rem 0'}}>Zip Code: {user_zip}</Header>
                    <Input size="small" placeholder='Zip Code' name='zip' style={{width: '100%', marginBottom:'0.7rem'}}></Input>

                    <Button type='submit' floated="left" color="blue">Update Private</Button>
                </Form>

            <Header as='h1' inverted textAlign="center" style={{fontSize: '3rem'}} dividing>User Bank Settings</Header>

            <Header as='h3' inverted>Purchased Token Balance: {user_balance}</Header>
            <Button type='submit' onClick={() => navigate('/buytokens')} color="blue">Buy Tokens</Button>
            <Header as='h3' inverted>Total Earnings in Tokens: {session.data.session.userPrivate.earnings_total}</Header>
            <Button type='submit' onClick={() => navigate('/cashout')} color="blue">Cash Out Article Sales</Button>


            <Header as='h3' inverted>Purchase and Earnings History:</Header>
            
            <Accordion inverted>
            <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={() =>handleAccordionClick(0)}
            >
            <Icon name='dropdown'/>
            Token Purchases
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
            <>
                <Table celled fixed padded selectable basic='very' inverted collapsing>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Tokens Purchased</Table.HeaderCell>
                            <Table.HeaderCell>SPN Fee</Table.HeaderCell>
                            <Table.HeaderCell>PayPal Fee</Table.HeaderCell>
                            <Table.HeaderCell>Total Charge</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {tokenPurchaseData?(tokenPurchaseData.map(tpd => (
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    {parseTimestamp(tpd.time)}
                                </Table.Cell>
                                <Table.Cell>
                                    {tpd.tokens_added}
                                </Table.Cell>
                                <Table.Cell>
                                    {tpd.spn_fee}
                                </Table.Cell>
                                <Table.Cell>
                                    {tpd.external_fee}
                                </Table.Cell>
                                <Table.Cell>
                                    {tpd.total_amount}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    ))):(
                        <Table.Body>
                            <Table.Row>
                                <Table.HeaderCell>Loading...</Table.HeaderCell>
                            </Table.Row>
                        </Table.Body>
                    )} 
                </Table>
            </>

            </Accordion.Content>

            <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={() =>handleAccordionClick(1)}
            >
            <Icon name='dropdown' />
            Article Earnings Cashed Out
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
            <>
                <Table celled fixed padded selectable basic='very' inverted collapsing>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Time Initiated</Table.HeaderCell>
                            <Table.HeaderCell>Tokens Cashed</Table.HeaderCell>
                            <Table.HeaderCell>Time Completed</Table.HeaderCell>
                            <Table.HeaderCell>Total Paid Out by SPN</Table.HeaderCell>
                            <Table.HeaderCell>External Payment ID</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {tokensCashedData?(tokensCashedData.map(tcd => (
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    {parseTimestamp(tcd.time_initiated)}
                                </Table.Cell>
                                <Table.Cell>
                                    {tcd.cashed_to_user}
                                </Table.Cell>
                                <Table.Cell>
                                    Todo: pending PayPal integration
                                </Table.Cell>
                                <Table.Cell>
                                    Todo: pending PayPal integration
                                </Table.Cell>
                                <Table.Cell>
                                    Todo: pending PayPal integration
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                        
                    ))):(
                    <Table.Body>
                        <Table.Row>
                            <Table.HeaderCell>Loading...</Table.HeaderCell>
                        </Table.Row>
                    </Table.Body>
                    )} 
                </Table>
            </>
            </Accordion.Content>

            <Accordion.Title
                active={activeIndex === 2}
                index={2}
                onClick={() =>handleAccordionClick(2)}
            >
            <Icon name='dropdown' />
            Article Sales
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
            <>
                <Table celled fixed padded selectable basic='very' inverted collapsing>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Article</Table.HeaderCell>
                            <Table.HeaderCell>Purchased By</Table.HeaderCell>
                            <Table.HeaderCell>Purchase Time</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {articlesSoldData?(articlesSoldData.map(asd => (
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as='h4'>
                                        <Header.Content>
                                        <a href={`/${asd.article_id}`}>{asd.article_title}</a>
                                        <Header.Subheader>
                                        <a href={`/user/${asd.uid_of_article_author}`}>Written by {asd.author_name}</a>
                                        </Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>
                                    Purchased By: <a href={`/user/${asd.purchasing_user_id}`}>{asd.purchasing_user_name}</a>
                                </Table.Cell>
                                <Table.Cell>
                                    At {parseTimestamp(asd.when)}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    ))):(
                    <Table.Body>
                        <Table.Row>
                            <Table.HeaderCell>Loading...</Table.HeaderCell>
                        </Table.Row>
                    </Table.Body>
                    )} 
                </Table>
            </>
            </Accordion.Content>

            <Accordion.Title
                active={activeIndex === 3}
                index={3}
                onClick={() =>handleAccordionClick(3)}
            >
            <Icon name='dropdown' />
            Articles Bought
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 3}>
            <>  
                <Table celled fixed padded selectable basic='very' inverted collapsing>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Article</Table.HeaderCell>
                            <Table.HeaderCell>Purchased By</Table.HeaderCell>
                            <Table.HeaderCell>Purchase Time</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {articlesBoughtData?(articlesBoughtData.map(abd => (
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as='h4'>
                                        <Header.Content>
                                        <a href={`/${abd.article_id}`}>{abd.article_title}</a>
                                        <Header.Subheader>
                                        <a href={`/user/${abd.uid_of_article_author}`}>Written by {abd.author_name}</a>
                                        </Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>
                                    Purchased By: <a href={`/user/${abd.purchasing_user_id}`}>{abd.purchasing_user_name}</a>
                                </Table.Cell>
                                <Table.Cell>
                                    At: {parseTimestamp(abd.when)}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    ))):(
                    <Table.Body>
                        <Table.Row>
                            <Table.HeaderCell>Loading...</Table.HeaderCell>
                        </Table.Row>
                    </Table.Body>
                    )} 
                </Table>
            </>
            </Accordion.Content>
            </Accordion>
        </Container>
    </div>
    )
}

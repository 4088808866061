import { useActionData, useNavigation, useOutletContext } from 'react-router';
import { useNavigate, Form} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Container, Button, Input, Checkbox, TextArea, Header, Divider, Icon} from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

export const ReportView = () => {

    //Introduce State for Error Handling
    const [errorMessage, setErrorMessage] = useState(null);
    const handleError = (message) => {setErrorMessage(message);};
    const resetError = () => {setErrorMessage(null);};
      // Step 1: Introduce State for Success Handling
    const [successMessage, setSuccessMessage] = useState(null);
    const handleSuccess = (message) => {setSuccessMessage(message);};
    const resetSuccess = () => {setSuccessMessage(null);};

    //"/report/:item_type/:item_id"
    //{`/report/${item_type}/:${item_id}`}
    const { item_type, item_id } = useParams();

    const navigation = useNavigation();
    const navigate = useNavigate();
    const session = useOutletContext();  
    const isLoading =
      navigation.state === 'loading' || navigation.state === 'submitting';
    
    console.log('ReportView')
    console.log('session=',session)//this.data

    const data = useActionData(); 
    //console.log("The data const --> " + data);

    useEffect(() => {
        console.log('ReportView useEffect');
        
        if (data) {
          if (data.error) {
            handleError(data.error);
          } else if (data.success) {
            handleSuccess(data.success);
          }
        }
      }, [data, navigate]);
      
return(
    <div style={{color:'black', backgroundColor: '#0f0f0f', padding: '30px 0px'}}>
        <h1 id='report-header'>Single Pay News</h1>
        <h3 id='report-subheader'>Report Bad Stuff</h3>
        <Container text>
            <Form style={{backgroundColor: '#fbfbfb', padding: '10px 10px', textAlign: 'center', borderRadius:'4px'}} 
            method='post' action={`/report/${item_type}/:${item_id}`} encType="multipart/form-data">
                <input disabled={isLoading} name='session' value={JSON.stringify(session)} type='hidden'></input>
                <Header as='h2'>
                    Reporting {item_type} #{item_id}
                </Header>
                <input disabled={isLoading} name='item_type' value={item_type} type='hidden'></input>
                <input disabled={isLoading} name='item_id' value={item_id} type='hidden'></input>
                <Header as='h2'>
                    Why are you reporting this?
                </Header>
                <Input icon='edit' iconPosition='left' disabled={isLoading} placeholder="Tell us what's wrong" name='content' type='text' size='large'></Input>
                <br/>
                <Divider/>
                <Button primary size='large' disabled={isLoading} type='submit'>{isLoading ? 'loading...' : 'Submit Report'}</Button>
            </Form>
            {data && data.error && <div>{data.error}</div>}
        {/** Step 2: Displaying Error Messages in the UI */}
        {errorMessage && (
          <div className="error-message">
              <p>{errorMessage}</p>
              <button onClick={resetError}>Close</button>
          </div>
      )}
      {/** Step 2: Displaying Success Messages in the UI */}
      {successMessage && (
          <div className="success-message">
              <p>{successMessage}</p>
              <button onClick={resetSuccess}>Close</button>
          </div>
      )}
        </Container>    
    </div>
    )
}

export async function action({ request }) {
    console.log('ReportView action called')
    try {
      const formData = await request.formData();
      console.log('formData:', formData);
      // parse some basics
      const actionSession = JSON.parse(formData.get('session'));
      const Token = actionSession.data.session.access_token;
      console.log('formData Token = ',Token);
      const reporting_username = actionSession.data.user.username;
      console.log('formData reporting_username = ',reporting_username);
      const reporting_uid = actionSession.data.user.id;
      console.log('formData reporting_uid = ',reporting_uid);
      // parse formDataEscape for POST()
      const formDataEscape = {
          item_type: formData.get('item_type'),
          item_id: formData.get('item_id'),
          report_content: formData.get('content'),
          reporting_username: reporting_username,
          reporting_uid: reporting_uid, 
          reporting_session: JSON.stringify(actionSession)
      };
      console.log('formDataEscape: ',formDataEscape);
      // define POST options
      const options = {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          headers: {
              'Content-Type': "application/json"
          },
          redirect: "follow",
          referrerPolicy: 'strict-origin-when-cross-origin',
          body: JSON.stringify(formDataEscape)
      };
      await fetch('https://singlepaynews.com/api/report_email', options);
      
      return { success: 'Report Submitted' };  // Return success message
    } catch (error) {
      console.error('Action error:', error);
      return { error: error.message };
    }
  }
  


// TODO: also define a view ChangePasswordView.js for the link to send to; require a code to access it, and use that page to let a user set a new password.

import { useActionData, useNavigation, useOutletContext } from 'react-router';
import { Form, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import '../styles/LoginSignUpStyles/signup.css';
import { changePasswordWithPg, loginWithPg } from '../data/rdsClient';

export const ChangePasswordView = () => {
  //Introduce State for Error Handling
  const [errorMessage, setErrorMessage] = useState(null);
  const handleError = (message) => {setErrorMessage(message);};
  const resetError = () => {setErrorMessage(null);};
    // Step 1: Introduce State for Success Handling
  const [successMessage, setSuccessMessage] = useState(null);
  const handleSuccess = (message) => {setSuccessMessage(message);};
  const resetSuccess = () => {setSuccessMessage(null);};

    const { email, code } = useParams();
    const navigation = useNavigation();
    const navigate = useNavigate();
    const session = useOutletContext();  
    const isLoading =
      navigation.state === 'loading' || navigation.state === 'submitting';
    const data = useActionData();

    const [disableForm, setDisableForm] = useState(false); // for disabling form while processing action and useEffect

    // this is called on form submission and initial render
    useEffect(() => {
        console.log("ChangePasswordView useEffect")
        if (data && !data.error) {//https://remix.run/docs/en/main/hooks/use-action-data
          setDisableForm(true); // don't allow new form submissions until we get thru these window alerts
          console.log('data=',data)
          if (data == 'passwords dont match'){
            handleError('password and confirmed password don\'t match, please try again')
            navigate(`/change-password/${email}/${code}`)
          }else if (data.pass) { 
            session.logout();  
            handleSuccess('Password changed.');
            navigate('/login');
          }else if (data.code) { // todo: I think this is unreachable.  How does pgREST return errors?
              // Handle the case when data has a PGRST code (bad login)
              handleError('bad response, try again?');
              navigate('/');
        }else{
            handleError('bad response, try again?');
            navigate('/');
        }
        
        }
    }, [data]);

    return(
        <>
            <div style={{height: '100vh', backgroundColor: '#0f0f0f', color: 'white',}}>
                {/* these used to be signup-header and subheader, may be causing a CSS bug */}
                <h1 id='forgotPW-header'>Single Pay News</h1>
                <h3 id='forgotPW-subheader'>Change Password</h3>

                <div className='form-container'>

                {/* <StyledFormLayout> */}
                    <Form id='form' action={`/change-password/${email}/${code}`} method='post'>
                    <label className='signup-label'>
                        Email:
                        <input disabled={isLoading&&!disableForm} type="text" name="email" value={email.toLowerCase()} placeholder={isLoading&&!disableForm ? 'loading...' : email.toLowerCase()} className='signup-input'/>
                    </label>
                    <label className='signup-label'>
                        Code:
                        <input disabled={isLoading&&!disableForm} type="password" name="code" value={code} placeholder={isLoading&&!disableForm ? 'loading...' : code} className='signup-input'/>
                    </label>
                    <label className='signup-label'>
                        Set New Password:
                        <input disabled={isLoading&&!disableForm} type="password" name="password" placeholder={isLoading&&!disableForm ? 'loading...' : 'password'} className='signup-input'/>
                    </label>
                    <label className='signup-label'>
                        Confirm Password:
                        <input disabled={isLoading&&!disableForm} type="password" name="password_confirm" placeholder={isLoading&&!disableForm ? 'loading...' : 'confirm password'} className='signup-input'/>
                    </label>
                    {/* this used to be id='signup-button'; may be causing CSS bug */}
                    <button disabled={isLoading&&!disableForm} id='ChangePassword-button'>
                        {isLoading&&!disableForm ? 'loading...' : 'Change Password'}
                    </button>
                    </Form>
                    {data && data.error && <div>{data.error}</div>}
                {/* </StyledFormLayout> */}
                </div>
        {/** Step 2: Displaying Error Messages in the UI */}
        {errorMessage && (
          <div className="error-message">
              <p>{errorMessage}</p>
              <button onClick={resetError}>Close</button>
          </div>
      )}
      {/** Step 2: Displaying Success Messages in the UI */}
      {successMessage && (
          <div className="success-message">
              <p>{successMessage}</p>
              <button onClick={resetSuccess}>Close</button>
          </div>
      )}
            </div>
        </>
    )
}


export async function action({ request }) {
    console.log('action called')
    // setDisableForm(true);
// src/views/ChangePasswordView.js
// Line 89:5:  'setDisableForm' is not defined  no-undef
    // TODO: use rpc/add_temp_code to prime basic_auth.users row to change its password
    // TODO: then send an email to the use with the temp_code as a url param

    const formData = await request.formData();
    const formDataEscape = { 
      email: formData.get('email').toLowerCase(),
      password: formData.get('password'),
      confirm: formData.get('password_confirm'),
      code: formData.get('code'),
      initial_password: formData.get('initial_password')
    };

    if (formDataEscape.password!=formDataEscape.confirm){
      return 'passwords dont match'
    }

    try {

        // TODO: use rpc/add_temp_code to prime basic_auth.users row to change its password
        const { data, error } = await changePasswordWithPg( // basic_auth.update_user_password input_email TEXT, input_pass TEXT, input_code TEXT
          {
            input_code: formDataEscape.code,
            input_email: formDataEscape.email.toLowerCase(),
            input_pass: formDataEscape.password
          })
        
        console.log('changePasswordWithPg data: ', data);
        // todo: if error, prompt to contact us by email

        // TODO: what does changePasswordWithPg return when it works?
    
        console.log(data);
        return data;
      } catch (error) {
        if (error.message === 'Invalid credentials') {
          return {
            error: 'Something went wrong. Please email us at singlepaynews@gmail.com.',
          };
        }
        throw error;
      }
}

// SignUpView.js
import { useState } from 'react';
import '../styles/LoginSignUpStyles/signup.css';
import { signupWithPg } from '../data/rdsClient';
import { Container } from 'semantic-ui-react';

async function signUpForFree(name, email, password, handleSuccess, handleError) {
  const formData = {
    name,
    email: email.toLowerCase(),
    password,
    code: generate_code(),
    tokens: 0,
  };

  try {
    const signupResponse = await signupWithPg({
      input_name: formData.name,
      input_email: formData.email.toLowerCase(),
      pass: formData.password,
      input_code: formData.code,
      tokens: formData.tokens,
    });

    console.log('signupResponse:', signupResponse);

    if (!signupResponse) {
      throw new Error('Signup failed');
    }

    const signupEmailData = {
      input_name: formData.name,
      input_email: formData.email.toLowerCase(),
      input_code: formData.code,
      initial_password: formData.password,
    };

    const options = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'strict-origin-when-cross-origin',
      body: JSON.stringify(signupEmailData),
    };

    const emailerResponse = await fetch('https://singlepaynews.com/api/emailer', options);
    console.log(emailerResponse);

    handleSuccess('Signup successful! Check your email for further instructions.');
  } catch (error) {
    console.error(error);
    handleError(error.message);
  }
}

const generate_code = () => {
  const length = 6;
  let code = '';
  for (let i = 0; i < length; i++) {
    code += Math.floor(Math.random() * 10);
  }
  return code;
};

export const SignUpView = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  // State for Error Handling
  const [errorMessage, setErrorMessage] = useState(null);
  const handleError = (message) => setErrorMessage(message);
  const resetError = () => setErrorMessage(null);

  // State for Success Handling
  const [successMessage, setSuccessMessage] = useState(null);
  const handleSuccess = (message) => setSuccessMessage(message);
  const resetSuccess = () => setSuccessMessage(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await signUpForFree(name, email, "default", handleSuccess, handleError);
  };

  return (
    <div style={{ height: '100%', backgroundColor: '#0f0f0f', color: 'white', paddingTop: '40px', paddingBottom: '40px' }}>
      <Container text>
        <h1 id='signup-header'>Support Local Journalism</h1>
  <p>Single Pay News (SPN) is a platform where local journalists can earn a living by selling their work and building an audience that will last their entire career. The most straightforward way to support these journalists is to follow them on SPN and buy their work when it is published. Individual articles can be purchased for $0.25. The journalist earns 80% of all purchases, and SPN keeps 20%. 
                </p>

  <p>To get started, create an account by filling out the form below. Once you have created an account, you can then buy Tokens for your account.  You don’t have to buy Tokens, but you will need to if you want to buy paid articles. Once you have Tokens in your account, they will last until you spend them. There is no subscription at SPN. 
                </p>

  <p>You should use your real name and email to set up your account, so that it will match your credit card and PayPal account when you purchase Tokens and sell articles.  
                </p>
  


  <p>SPN will never sell or otherwise share your email with any third party.  SPN does not track your activity on the SPN website; except that when you buy an article from a journalist, that journalist will be added to your following, so that their future articles will appear in your feed.  That said, you can add or delete anyone from your following at any time.  SPN gives you complete control over your feed, because SPN makes money off comission, not ads. 
                </p>
<div className='form-container'>
          <form onSubmit={handleSubmit}>
            <div className='signup-label'>
              <label>Name: </label>
              <input type='text' className='signup-input' value={name} onChange={(e) => setName(e.target.value)} required />
            </div>
            <div className='signup-label'>
              <label>Email: </label>
              <input type='email' className='signup-input' value={email} onChange={(e) => setEmail(e.target.value)} required />
            </div>
            <button type='submit' id='signup-button'>Sign Up</button>
          </form>
        </div>
        {errorMessage && (
          <div className='error-message'>
            <p>{errorMessage}</p>
            <button onClick={resetError}>Close</button>
          </div>
        )}
        {successMessage && (
          <div className='success-message'>
            <p>{successMessage}</p>
            <button onClick={resetSuccess}>Close</button>
          </div>
        )}
      </Container>
    </div>
  );
};



/** 
 * Changes made:
Added a state for name: Added name state to manage the name input field.
Updated the form to capture name: Added an input field for the user's name.
Modified signUpForFree function: The function now accepts name, email, password, handleSuccess, and handleError as parameters and utilizes them appropriately.
Updated form submission: The form submission now passes the name along with the email and password to the signUpForFree function.
 */

/**
 * Changes made:
Removed quantity related states and UI elements: Removed the quantity state and related UI elements.
Introduced email and password states: Added email and password states to manage form inputs.
Updated the form to capture email and password: The form now has input fields for email and password.
Modified signUpForFree function: The function now accepts email, password, handleSuccess, and handleError as parameters and utilizes them appropriately.
Handle form submission: Updated form submission logic to call signUpForFree with the entered email and password.

 */

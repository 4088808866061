import { Outlet, useOutletContext } from 'react-router';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({ element }) => {
  const session = useOutletContext();
  console.log('ProtectedcRoute.js, session=')
  console.log(session)
  // Check for a valid session or authentication token
  return session.data ? (
    element || <Outlet />
  ) : (
    <Navigate to="/login" replace />
  );
};

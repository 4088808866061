
import { Outlet, useNavigate, useOutletContext } from 'react-router';
import { useState, useEffect } from "react";
import { 
    Grid,
    Header,
    Button,
    Segment,
    Container,
    Image,
    Divider,
    Card,
    Icon // Import Icon from Semantic UI React
  } from 'semantic-ui-react'
import React, { useRef } from 'react';
import avatar from './images/Jaden.JPG';
import '../styles/UserViewStyles/user.css';
import { Link } from 'react-router-dom';
import { FollowingView } from './FollowingView';
import { getPgData } from '../data/rdsClient';
import EditIcon from '@mui/icons-material/Edit'; // icon for editing articles
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';// icon for deleting articles



export const UserView = () => {

  //Introduce State for Error Handling
  const [errorMessage, setErrorMessage] = useState(null);
  const handleError = (message) => {setErrorMessage(message);};
  const resetError = () => {setErrorMessage(null);};
    // Step 1: Introduce State for Success Handling
  const [successMessage, setSuccessMessage] = useState(null);
  const handleSuccess = (message) => {setSuccessMessage(message);};
  const resetSuccess = () => {setSuccessMessage(null);};
  const session = useOutletContext();

  //State variables for user---------------------------------
  const [userPublic, setUserPublic] = useState(session.data.session.userPublic);
  //State variables for user---------------------------------


  const [articles,setArticles] = useState('loading');
  const [items, setItems] = useState([]);

  // Sample array of objects in format (a)
const formatA = [
  {
    author_name: "author_name",
    blurb: "Bloody, bloody, blah...",
    content: "A Seattle man recently...",
    created: "2023-09-25T01:19:23.284116+00:00",
    id: 209,
    image_url: "https://files.worldwildlife.org/...",
    is_paid: false,
    location_id: 3,
    num_likes: 0,
    subjects: [3, 1, 4, 1, 5],
    title: "Seattle Man Creates Multibillion Dollar Startup",
    total_sales: null,
    type: null,
    updated: "2023-09-25T01:19:23.284116+00:00",
    user_author: 2
  }
];

// Mapping function
const mapToFormatB = (formatA) => {
  return formatA.map((a) => {
    const header = a.title;
    const description = a.blurb;
    const meta = "By " + a.author_name;
    const extra = "Published: " + convertDateTimeWithTimeZoneToString(a.created);
    const image = a.image_url;
    const href = `/${a.id}`;
    const a_id = a.id; // added this id, I intend to pass it as a param to edit and delete icon handleClicks();

    return {
      header,
      description,
      meta,
      extra,
      image,
      href,
      a_id
    };
  });
};

// Call the mapping function
const formatB = mapToFormatB(formatA);

console.log(formatB);

function convertDateTimeWithTimeZoneToString(dateTimeWithTimeZoneString) {
  // Parse the PostgreSQL DateTimeWithTimeZone string into a JavaScript Date object
  const date = new Date(dateTimeWithTimeZoneString);

  // Check if the parsing was successful
  if (isNaN(date)) {
    return "Invalid Date"; // Handle invalid input
  }

  // Get the components of the date (month, day, year)
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const year = String(date.getFullYear());

  // Format the date as "mm/dd/yyyy"
  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
}

// Example usage:
const dateTimeWithTimeZoneString = "2023-10-11T15:30:00.000Z"; // Sample input
const formattedDate = convertDateTimeWithTimeZoneToString(dateTimeWithTimeZoneString);
console.log(formattedDate); // Output: "10/11/2023"


  console.log('userview')
  console.log(session)

  async function fetchArticles() {
      let token = '';
      let userId = '';
      if(session.data){
        console.log('fetchProfile session=',session)
        token = session.data.session.access_token
        userId = session.data.user.id
      }else{
        handleError('Are you logged in?')
        return 'not logged in'
      }
      try {
      const response = await getPgData(`/articlefresh?user_author=eq.${userId}&order=created.desc`, {}, session);
      if (Array.isArray(response.data)) {
          setArticles(response.data); // Set the response data if it's an array
          console.log('UserView articles query: ', response.data);
          setItems(mapToFormatB(response.data))
      } else {
          console.error('Response data is not an array:', response.data);
      }
      } catch (error) {
      console.log(error);
      throw error;
      }
  }

  async function handleEditArticleClick(a_id){
    // navigate('/editArticleView/a_id')
  }

  async function handleDeleteArticleClick(a_id){
    // prompt ('are you sure you want to delete this article?  It can't be un-deleted.')
  }

  useEffect(() => {
    //console.log('foobar: ',session) could not reproduce bug
      const userId = session.data.user.id
      console.log('UserView useEffect userId: ',userId)
      
      fetchArticles()
  }, []);

  const navigate = useNavigate();
  const handleFollowingClick = () => navigate('./following');
  const handleFollowersClick = () => navigate('./followers');
  const handleEditClick = () => navigate('/settings');
  
  return (
    <div style={{ height: '100%', backgroundColor: '#0f0f0f', color: 'white', padding: '40px 0px' }}>
      <Container>
        <div id='profile-container'>
          {userPublic ? (
            <>
              <Container text>
                <div id='profile-image'>
                  {userPublic.profile_pic_url ? (
                    <Image 
                      src={userPublic.profile_pic_url} 
                      alt="Profile Picture" 
                      as={'a'} 
                      avatar 
                      style={{ fontSize: '4.2rem', cursor: 'pointer' }} 
                    />
                  ) : (
                    <Icon 
                      name='user circle' 
                      size='massive' 
                      style={{ fontSize: '4.2rem', cursor: 'pointer' }} 
                    />
                  )}
                </div>
                
                <div id='profile-header' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                  <div>
                    <h3>{userPublic.name}</h3>
                    <Button basic color='blue' size='tiny' onClick={handleFollowingClick}>Following</Button>
                    <Button basic color='teal' style={{ marginLeft: '5px' }} size='tiny' onClick={handleFollowersClick}>Followers</Button>
                  </div>
                  <Button basic color='orange' style={{ padding: '.625rem 1.81rem' }} size='tiny' onClick={handleEditClick}>Edit Profile</Button>
                </div>
              </Container>
        {/** Step 2: Displaying Error Messages in the UI */}
        {errorMessage && (
          <div className="error-message">
              <p>{errorMessage}</p>
              <button onClick={resetError}>Close</button>
          </div>
      )}
      {/** Step 2: Displaying Success Messages in the UI */}
      {successMessage && (
          <div className="success-message">
              <p>{successMessage}</p>
              <button onClick={resetSuccess}>Close</button>
          </div>
      )}
  
              <Container text>
                <div id='profile-body'>
                  <Container text textAlign='left'>
                    <p style={{ fontSize: '1.2em', marginTop: '1em' }}>
                      {userPublic.bio}
                    </p>
                  </Container>
                  <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
                    <h2>Published Articles</h2>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <p>Click an Article to open it in Feed</p>
                  </div>
                  <Divider />
  
                  <div id='profile-cards'>
                    <div style={{ display: 'inline-block' }}>
                      <Card.Group items={items} />
                    </div>
                  </div>
                </div>
              </Container>
            </>
          ) : (
            <p>Loading Profile...</p>
          )}
        </div>
      </Container>
    </div>
  );
}  
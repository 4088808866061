import { useState } from "react";
import { Container, Input, Button, Table, Accordion, Icon, Header, TextArea} from 'semantic-ui-react';


export const CampaignView = () => {
    
    return(
        <>
        <div style={{height: '100%', backgroundColor: '#0f0f0f', color: 'white', paddingTop: '80px', paddingBottom: '40px'}}>
            <Container text>
            <Header as='h3' inverted>Single Pay News</Header>  
            
            <>
            <p>Has Facebook, Instagram, X, YouTube, or TikTok ever paid you for your posts or comments?</p>
            <p>We will.</p>
            <p>We are trying to get a conversation started to help local journalists find an audience in the United States.</p>
            <p>Our only real requirement is that you actually want to be a part of this discussion. If you already actively post on the internet, (either in comment threads or on social media), you are a perfect fit for this job and we would like to pay you for your thoughts.</p>
            <p></p>
            <p>The Job:</p>
            <p></p>
            <p>Sign up for an account at Single Pay News and be a part of the on-going discussion. Each day, make a post or a thoughtful comment on someone else's post on singlepaynews.com. It doesn't have to be about journalism or current events. Talk about whatever you want. Discuss the same topics you post about on other social media platforms.</p>
            <p>That's it.</p>
            <p>If you make one post or comment per day for a month, at the end of the month we will pay you $100.</p>
            <p>In addition, if you publish any "for sale" articles, and successfully promote them and attract new Users, we will pay you an a $10 bonus for each new User who buys any of your articles.</p>
            <p>If you have any friends who would also like to do this with you, even better.  We will pay you an additional $5 for every person you recruit who signs up to the $100/mo. deal, provided they also fund their account by buying Tokens.</p>
            <p>A word about buying Tokens:</p>
            <p>You don't need to buy Tokens to use the SPN website.  You can create an account and post and comment on free articles without ever funding your account.  However, if you want to post, buy, or comment on paid articles, you will need to have bought some Tokens for your account.</p>
            <p>Our goal is to build a community that provides thoughtful, civil conversation, and use that conversation to attract an audience that supports--pays for--quality journalism. This is very different from Facebook and X. Their business model motivates conflict, because for them, conflict drives engagement. Since they are trying to sell ads, they care less about the quality of content on their site, as long as it keeps people engaged. That is why they have disbanded their moderation teams and opened their doors to bots posting propaganda, trolls, and manufactured outrage. It helps their bottom line.</p>
            <p>Our target audience is people who want to abandon Facebook and X for that exact reason. We are seeking people who want to be informed, who value local journalism enough that they are willing to pay for it. We want to host the grown-up conversation on the internet, and enable the market that supports it.  If we are paying you to post, it is because we think you are the kind of person who can help us achieve that objective.</p>
            <p></p>
            <p>Again, The Job:</p>
            <p>Sign up for an account. Each day, either make a post or a thoughtful comment on someone else's post on singlepaynews.com. Generally discuss the same topics you post about on other social media platforms. We expect this will take only a few minutes of your time each day; and it is something you are already doing on other websites for free. If you have any friends who would also like to do this with you, even better. Send them our way, and if they sign up, you make an additional $5 if they fund their account and buy Tokens. If you publish any "for sale" articles, promote them to attract new users, and we will pay you an additional $10 for every new sign up and sale.</p>
            <p>If you help us get this conversation started, and you post at least once each day, at the end of the month we will pay you $100. We will then renew the agreement for the next month, depending on how things are going.</p>
            <p>If that sounds like something you want to be a part of, please send me an email at singlepaynews@gmail.com and I will send you a simple contract to get you started.</p>
            <p></p>
            <p>Thank you for your attention.</p>
            <p></p>
            <p>Doug McKinley<br></br>
            Single Pay News, LLC<br></br>
            Founder</p>
            </>
            </Container>
        </div>
        </>
    )
}
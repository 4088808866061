import { Container, Header} from 'semantic-ui-react';

// ToDo: this is just check your email page, rename it
export const SignedUpNotRegisteredView = () => {
    
    return(
        <>
        <div style={{height: '100%', backgroundColor: '#0f0f0f', color: 'white', paddingTop: '40px', paddingBottom: '40px'}}>
            <Container text>
            <Header as='h3' inverted>Single Pay News</Header>  
            <p>Check your email (and spam folder) for a link!</p>
        </Container>
        </div>
        </>
    )
}
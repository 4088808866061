import { useState } from "react";
import { Container, Input, Button, Table, Accordion, Icon, Header, TextArea} from 'semantic-ui-react';


export const ContestView = () => {
    
    return(
        <>
        <div style={{height: '100%', backgroundColor: '#0f0f0f', color: 'white', paddingTop: '40px', paddingBottom: '40px'}}>
            <Container text>
            <Header as='h3' inverted>Single Pay News</Header>  
            
            <Header as='h3' inverted>The $100 Weekly Contest</Header>
            <>
            <p>To attract journalists, SPN will be continuously running two contests, a Weekly Contest and a Yearly
Contest. The winner of each Weekly Contest is paid $100 and the winner of the Yearly Contest is paid
$1,000. Every Paid Post is automatically entered to win the Weekly Contest and every Journalist who
posts a Paid Post is automatically entered to win the Yearly Contest. Any Journalist who has won a
Weekly Contest in the proceeding twelve months becomes ineligible to win subsequent Weekly
Contests for the subsequent twelve months, but remains eligible to win the Yearly Contest.</p>
            <p>The Weekly Contest automatically enters every Paid Post that is posted between each midnight
between Sunday and Monday, UTC and the subsequent midnight between Sunday and Monday, UTC.
Beginning at the time each Paid Post was posted, SPN then calculates the total number of unique Users
who purchased a copy of each Paid Post posted during the 168 hours following the time the Paid Post
was posted. The Paid Post that was purchased by the most unique Users wins $100.</p>
            <p>The Yearly Contest begins on January 1, 2024 and ends on January 1, 2025 (hereafter the “Yearly
Posting Period”). Every Journalist who posts a Paid Post during the Yearly Posting Period is
automatically entered to win the Yearly Contest. SPN calculates the total number of unique Users who
purchased a copy of any of the Paid Posts by each Journalist during the Yearly Posting Period. So, for example, if a Journalist posts two Paid Posts that are each bought by the same User, the Journalist has
one unique User. If the Journalist posts two Paid Posts that are each bought by different unique Users,
the Journalist has two unique Users. At the end of the Yearly Contest, the Journalist with the most
unique Users wins $1,000.</p>
            <p>Void where prohibited.</p>
            </>
        </Container>
        </div>
        </>
    )
}
import { Container, List, Image, Button, Tab } from "semantic-ui-react";
import avatar from './images/Jaden.JPG';
import { useState, useEffect } from "react";
import { useOutletContext, useNavigate } from 'react-router';
import { getPgData, postPgData, patchPgData } from '../data/rdsClient';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// todo: remove Topics or implement as hashtags

export const FollowingView = () => {
  //Introduce State for Error Handling
  const [errorMessage, setErrorMessage] = useState(null);
  const handleError = (message) => {setErrorMessage(message);};
  const resetError = () => {setErrorMessage(null);};
    // Step 1: Introduce State for Success Handling
  const [successMessage, setSuccessMessage] = useState(null);
  const handleSuccess = (message) => {setSuccessMessage(message);};
  const resetSuccess = () => {setSuccessMessage(null);};

  const navigate = useNavigate();
  const session = useOutletContext(); // outletContext session is holding the logged-in-user's info, it goes in every view and can be used t check if a user is logged in.
  console.log(session)
  const [userPublic, setUserPublic] = useState(session.data.session.userPublic);
  const [usersFollowing, setUsersFollowing] = useState();

  // done: rewrite to leverage the fact that we don't need to get the loggen-in-user's userPublic.
  async function handleFollowClick(toFollowId, addRemove) {
    if(session.data){
      // relation
      const relation = {
        follower_uid:session.data.user.id, //2
        uid_followed:toFollowId //12
      }
      // we're going to assume the user is followed/unfollowed according to param 'addRemove' TODO: is_followed_flag would be a better name for this flag
      if (addRemove === 'add'){ 
        // use /rpc/follow
        const { data, error } = await postPgData('/rpc/follow', 
          {
            follower_uid: relation.follower_uid,
            uid_followed: relation.uid_followed
          },
          session//.data.session.access_token) // todo: examine session param for default value case
        )
        if (error) {
          console.error('Error:', error);
          console.log(error.message);
          return { error: error.message };
        }
        console.log('postPgData - /rpc/follow:', data);
        // change button state
        // setIsFollowing(true); // button state unused in FollowingView; may need to update DOM to remove user
        // notify otheruser that they've been followed:
          // add to notifications table
          console.log('postPgData - notifications:');
          const { data: postPgNotification, error: postPgNotificationError } = await postPgData(
            `/notifications`,
            {
              // Default: eid bigserial PRIMARY KEY,
              // Default: created timestamp with time zone DEFAULT NOW(),
              // Default: updated timestamp with time zone DEFAULT NOW(),
              // Default: type text,
              trigger_uid: session.data.session.userPublic.id,
              trigger_uname: session.data.session.userPublic.name,
              action: 'followed', // this gets used in the string in NotificationsView; see notifications.txt lines 269-274.
              subject_aid_uid: toFollowId,
              subject_title_name: '', // had to add this as a input to handleArticleLike() function
              owner_uid: toFollowId // had to add this as a input to handleArticleLike() function
            },
            session
          );

          if (postPgNotificationError) {
            console.error('Error:', postPgNotificationError);
            console.log(postPgNotificationError.message);
            return { error: postPgNotificationError.message };
          }

          console.log('postPgNotification: ', postPgNotification)
        handleSuccess('Followed!'); 
        // update userPublic stateVar
        let newUserPublic = userPublic;
        console.log('newUserPublic: ',newUserPublic)
        newUserPublic.following_ids=data[0].updated_following_ids // todo: maybe data[0].following_ids
        newUserPublic.num_followers=data[0].updated_numfollowing // todo: maybe data[0].numfollowing
        setUserPublic(newUserPublic);
        // update stored session
        let newSession = session;
        newSession.data.session.userPublic = newUserPublic;
        session.login(newSession);
        // TODO: update otherUserPublic state variable, probably with a getPgData, maybe with useEffect?
      }else{ 
        if(addRemove === 'remove'){
          // use /rpc/follow
          const { data, error } = await postPgData('/rpc/unfollow', 
          {
            unfollower_uid: relation.follower_uid,
            uid_unfollowed: relation.uid_followed
          },
          session//.data.session.access_token // todo: examine session param for default value case
          )
          if (error) {
            console.error('Error:', error);
            console.log(error.message);
            return { error: error.message };
          }
          console.log('postPgData - /rpc/unfollow:', data);
          // change button state
          //setIsFollowing(false); // button state unused in FollowingView; may need to update DOM to remove user
          handleError('Unfollowed'); 
          // update userPublic stateVar
          let newUserPublic = userPublic;
          console.log('newUserPublic: ',newUserPublic)
          newUserPublic.following_ids=data[0].updated_following_ids // todo: maybe data[0].following_ids
          newUserPublic.num_followers=data[0].updated_numfollowing // todo: maybe data[0].numfollowing
          setUserPublic(newUserPublic);
          // update stored session
          let newSession = session;
          newSession.data.session.userPublic = newUserPublic;
          session.login(newSession);
          // TODO: update otherUserPublic state variable, probably with a getPgData, maybe with useEffect?
        }else{
          console.log('problem with addRemove param')
          handleError('Error')
        }
      }
    }else{
      handleError('Are you logged in?')
    }
  } 
  

    async function handleUnfollowUserClick(userFollowingId){
        handleFollowClick(userFollowingId, 'remove')
    }
        
        
    async function fetchFollowing() { // fetches the details of followed users; the list of user_ids is already in userPublic.
        console.log('fetchFollowing');
        let token = '';
        let userId = '';
        if(session.data){
            console.log('fetchFollowerDetails session=',session)
            token = session.data.session.access_token
            userId = session.data.user.id
        }else{
            handleError('Are you logged in?')
            return 'not logged in'
        }
        console.log('fetchFollowing following_ids:', userPublic.following_ids)

        try {
            const { data: followingData, error: followingError } = await getPgData(`/userpublic?id=in.(${userPublic.following_ids})`) // todo: examine session param for default value case

            if (followingError) {
                console.error('Error:', followingError);
                console.log(followingError.message);
                return { error: followingError.message };
            }
    
            console.log('getPgData - followingData:', followingData);
            setUsersFollowing(followingData);
        } catch (error) {
            console.error('fetchFollowingDetails error:', error);
            return { error: error.message };
        }
    }

    // .map() was goofing with menuItem, was resolved by initializing statevar to undefined instead of empty {}
    // this thread seemed like it was about a similar issue:: https://github.com/mui/material-ui/issues/14286
    const panes = [
        { menuItem: 'People', render: () => // [Error] TypeError: undefined is not a function (near '...c.map...') — FollowingView.js:53
            <Tab.Pane inverted attached={false}> 
            {usersFollowing ? (usersFollowing.map(userFollowing => (
                <List divided verticalAlign='middle' inverted>
                    <List.Item style={{padding: '10px 0px'}} href={`/user/${userFollowing.id}`}>
                        <List.Content floated='right'>
                            <Button basic color="blue" onClick={() => handleUnfollowUserClick(userFollowing.id)}>Unfollow</Button>
                        </List.Content>
                        <Image avatar src={userFollowing.profile_pic_url} />
                        <List.Content>{userFollowing.name}</List.Content>
                    </List.Item>
                </List>
            ))) : (
                // Render a loading indicator while waiting for usersFollowing data
                <div>Loading...</div>
            )}
            </Tab.Pane>
        },
        // todo: TODO: add the follow/block buttons here
        { menuItem: 'Topics (Hashtags)', render: () => 
            <Tab.Pane inverted attached={false}>
              {session?.data?.session?.userPublic?.hashtags_following_text ? (session.data.session.userPublic.hashtags_following_text.map(hashtag => (
                <List divided verticalAlign="bottom" inverted>
                    <List.Item style={{padding: '10px 0px'}}>
                        <List.Content><a href={`/hashtag/${hashtag}`}><h3>{hashtag}</h3></a></List.Content>
                    </List.Item>
                </List>
              ))):(<>Not following any Hashtags yet, go to <a href='/hashtag'>singlepaynews.com/hashtag</a> to find some!</>)}
            </Tab.Pane> 
        }
    ]
    // in any case, this is meant to display the users/subjects/locations that a user is following.  Assuming we have a list of ids from the userPublic table,
    // our next step will be to getPgData for each id.
    // when that id is of a user, we'll want their username, profile pic, everything from their userPublic table.
    // when it is a subject or location, we'll only be getting a text value 'nameOfSubject' or 'locationName'
    useEffect(() => {
        const userId = session.data.user.id
        console.log('FollowingView useEffect userId: ',userId)
        fetchFollowing();
    }, [userPublic]);
   return (
    <div style={{padding:"50px 0", backgroundColor: '#0f0f0f', color: 'white', height:'100vh'}}>
        <Container>
          <h3>Following</h3>
          {/* <HighlightOffIcon float='right' onClick={() => navigate('/account')}></HighlightOffIcon> */}
            <Tab panes={panes} menu={{attached: true, tabular: false, inverted: true, secondary: true, size: 'large'}}/>
        </Container>
        {/** Step 2: Displaying Error Messages in the UI */}
        {errorMessage && (
          <div className="error-message">
              <p>{errorMessage}</p>
              <button onClick={resetError}>Close</button>
          </div>
      )}
      {/** Step 2: Displaying Success Messages in the UI */}
      {successMessage && (
          <div className="success-message">
              <p>{successMessage}</p>
              <button onClick={resetSuccess}>Close</button>
          </div>
      )}
    </div>
   );
}

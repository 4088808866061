// update--I think this is true via UI if statement: disallow following something you're currently blocking
// TODO: This page uses semantic, it should be changed to use MUI

import { Outlet, useNavigation, useOutletContext } from 'react-router';
import { useState, useEffect } from "react";
import { 
    Grid,
    Header,
    Button,
    Segment,
    Container,
    Image,
    Divider,
    Card } from 'semantic-ui-react'
import React, { useRef } from 'react';
import avatar from './images/Jaden.JPG';
import '../styles/UserViewStyles/user.css';
import { useNavigate } from 'react-router-dom';
import { FollowingView } from './FollowingView';
import { getPgData, postPgData, patchPgData, deletePgData } from '../data/rdsClient';

import { useParams } from 'react-router-dom';


export const OtherUserView = () => {
  //Introduce State for Error Handling
  const [errorMessage, setErrorMessage] = useState(null);
  const handleError = (message) => {setErrorMessage(message);};
  const resetError = () => {setErrorMessage(null);};
    // Step 1: Introduce State for Success Handling
  const [successMessage, setSuccessMessage] = useState(null);
  const handleSuccess = (message) => {setSuccessMessage(message);};
  const resetSuccess = () => {setSuccessMessage(null);};

  const { id } = useParams();
  const session = useOutletContext();
  // does not have to be signed in to be on this page
  const [userPublic, setUserPublic] = useState(session.data?session.data.session.userPublic:{});
  const navigation = useNavigation();
  const navigate = useNavigate();

  //State variables for user---------------------------------
  const [otherUserPublic, setotherUserPublic] = useState({
    articles_posted_for_sale: 'loading',
    bio: 'loading',
    created: 'loading',
    follower_ids: ['loading'],
    following_ids: ['loading'],
    id: 'loading',
    name: 'loading',
    num_followers: 'loading',
    num_following: 'loading',
    profile_pic_url: 'loading',
    type: 'loading',
    updated: 'loading',
  });
  const [isFollowing, setIsFollowing] = useState(false); // if not signed in, we ain't followin' hoss// var for whether we're looking at a profile of a user we're following
  //State variables for user---------------------------------
  const [blocked, setBlocked] = useState(false);
  


  const [articles,setArticles] = useState('loading');
  const [items, setItems] = useState([]);

  // Sample array of objects in format (a)
const formatA = [
  {
    author_name: "author_name",
    blurb: "Bloody, bloody, blah...",
    content: "A Seattle man recently...",
    created: "2023-09-25T01:19:23.284116+00:00",
    id: 209,
    image_url: "https://files.worldwildlife.org/...",
    is_paid: false,
    location_id: 3,
    num_likes: 0,
    subjects: [3, 1, 4, 1, 5],
    title: "Seattle Man Creates Multibillion Dollar Startup",
    total_sales: null,
    type: null,
    updated: "2023-09-25T01:19:23.284116+00:00",
    user_author: 2
  }
];

// Mapping function for articles to DOM format TODO: make this unnecessary by updating DOM's use of stateVar articles
const mapToFormatB = (formatA) => {
  return formatA.map((a) => {
    const header = a.title;
    const description = a.blurb;
    const meta = "By " + a.author_name;
    const extra = "Published: " + convertDateTimeWithTimeZoneToString(a.created);
    const image = a.image_url; // post.image_url
    const href = `/${a.id}`;
    const a_id = a.id; // added this id, I intend to pass it as a param to edit and delete icon handleClicks();

    return {
      header,
      description,
      meta,
      extra,
      image,
      href,
      id
    };
  });
};

// Call the mapping function
const formatB = mapToFormatB(formatA);

console.log(formatB);

function convertDateTimeWithTimeZoneToString(dateTimeWithTimeZoneString) {
  // Parse the PostgreSQL DateTimeWithTimeZone string into a JavaScript Date object
  const date = new Date(dateTimeWithTimeZoneString);

  // Check if the parsing was successful
  if (isNaN(date)) {
    return "Invalid Date"; // Handle invalid input
  }

  // Get the components of the date (month, day, year)
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const year = String(date.getFullYear());

  // Format the date as "mm/dd/yyyy"
  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
}

// Example usage:
const dateTimeWithTimeZoneString = "2023-10-11T15:30:00.000Z"; // Sample input
const formattedDate = convertDateTimeWithTimeZoneToString(dateTimeWithTimeZoneString);
console.log(formattedDate); // Output: "10/11/2023"


  console.log('OtherUserView, session=',session)


  async function fetchOtherProfile() {
      console.log('fetchOtherProfile');
      let token = '';
      let otherUserId = '';
      if(session.data){ // will fail when not logged in
        console.log('fetchOtherProfile session=',session) // may not be signed in
        token = session?session.data.session.access_token:null // todo: examine session param for default value case
        otherUserId = id
      }else{
        token = ""
        otherUserId = id
      }
      try {
        // getPgData otherUserPublic where is = session...id 
        const { data: otherUserPublicData, error: otherUserPublicError } = await getPgData(`/userpublic?id=eq.${otherUserId}`, {}, session);// todo: examine session param for default value case
    
        if (otherUserPublicError) {
          console.error('Error:', otherUserPublicError);
          console.log(otherUserPublicError.message);
          return { error: otherUserPublicError.message };
        }
    
        console.log('getPgData - otherUserPublic:', otherUserPublicData);
        // Update the otherUserPublic state object
        setotherUserPublic(otherUserPublicData[0]);
      } catch (error) {
        console.error('fetchOtherProfile error:', error);
        return { error: error.message };
      }
    }

  async function fetchArticles() {
      let token = '';
      let otherUserId = '';
      if(session.data){
        console.log('fetchArticles session=',session) // may not be signed in
        token = session?session.data.session.access_token:null
        otherUserId = id
      }else{
        token = ""
        otherUserId = id
      }
      try {
      const response = await getPgData(`/articlefresh?user_author=eq.${otherUserId}&order=created.desc`, {}, session);
      if (Array.isArray(response.data)) {
          setArticles(response.data); // Set the response data if it's an array
          console.log('OtherUserView articles query: ', response.data);
          setItems(mapToFormatB(response.data))
      } else {
          console.error('Response data is not an array:', response.data);
      }
      } catch (error) {
      console.log(error);
      throw error;
      }
  }

  // todo: just set with session.userPublic.following
  async function fetchIsFollowing(){
    let token = '';
    let signedInUserId = '';
    let thisPageUserId = '';
    if(session.data){
      console.log('fetchIsFollowing session=',session) // may not be signed in
      token = session?session.data.session.access_token:null // todo: examine session param for default value case
      signedInUserId = session?session.data.user.id:null
      thisPageUserId = id
      try {
        const response = await getPgData(`/user_follow_relation?follower_uid=eq.${signedInUserId}&uid_followed=eq.${thisPageUserId}`, {}, session); // todo: examine session param for default value case
        if (Array.isArray(response.data)) { // if valid response
          if (response.data.length) { // and there's an entry in the table
            setIsFollowing(true) // already following this user
            // lmao todo: so this *does* work in the case where a user follows themselves, but we should probably check for that in advance and send to /UserView/ instead of /OtherUserView/.
            // Here's what the pgrest response looks like:
              // [Log] OtherUserView fetchIsFollowing query:  (bundle.js, line 4990)
              // Array (1)
              // 0 Object
              // created: "2023-10-12T07:08:54.572845+00:00"
              // follower_uid: 2
              // id: 1
              // type: null
              // uid_followed: 2
              // updated: null
              // Object Prototype
              // Array Prototype
          } else {
            setIsFollowing(false) // not following this user
          }
          console.log('OtherUserView fetchIsFollowing query: ', response.data);
        } else {
          console.error('Response data is not an array:', response.data);
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    }else{
      setIsFollowing(false) // if not signed in, we ain't followin' hoss
      return 'not signed in';
    }
  }

  // done: rewrite to leverage the fact that we don't need to get the loggen-in-user's userPublic.
  async function handleFollowClick(toFollowId, addRemove) {
    if(session.data){
      if(isBlocked(toFollowId)){ // disallow following something you're currently blocking
        handleError('Can\'t follow a user you\'re blocking.');
        return;
      }else{
        // relation
        const relation = {
          p_follower_uid:session.data.user.id, //2
          p_uid_followed:toFollowId //12
        }
        // we're going to assume the user is followed/unfollowed according to param 'addRemove' TODO: is_followed_flag would be a better name for this flag
        if (addRemove === 'add'){ 
          // use /rpc/follow
          const { data, error } = await postPgData('/rpc/follow_user', relation, session);
          if (error) {
            console.error('Error:', error);
            console.log(error.message);
            return { error: error.message };
          }
          console.log('postPgData - /rpc/follow_user:', data);
          // notify otheruser that they've been followed:
          // add to notifications table
          console.log('postPgData - notifications:');
          const { data: postPgNotification, error: postPgNotificationError } = await postPgData(
            `/notifications`,
            {
              // Default: eid bigserial PRIMARY KEY,
              // Default: created timestamp with time zone DEFAULT NOW(),
              // Default: updated timestamp with time zone DEFAULT NOW(),
              // Default: type text,
              trigger_uid: session.data.session.userPublic.id,
              trigger_uname: session.data.session.userPublic.name,
              action: 'followed', // this gets used in the string in NotificationsView; see notifications.txt lines 269-274.
              subject_aid_uid: toFollowId,
              subject_title_name: '', // had to add this as a input to handleArticleLike() function
              owner_uid: toFollowId // had to add this as a input to handleArticleLike() function
            },
            session
          );

          if (postPgNotificationError) {
            console.error('Error:', postPgNotificationError);
            console.log(postPgNotificationError.message);
            return { error: postPgNotificationError.message };
          }

          console.log('postPgNotification: ', postPgNotification)
          // change button state
          setIsFollowing(true);
          handleSuccess('Followed!'); 
          // update userPublic stateVar
          let newUserPublic = userPublic;
          console.log('newUserPublic: ',newUserPublic)
          newUserPublic.following_ids=data[0].updated_following_ids;
          newUserPublic.num_followers=data[0].updated_num_following;
          setUserPublic(newUserPublic);
          // update stored session
          let newSession = session;
          newSession.data.session.userPublic = newUserPublic;
          session.login(newSession);
          // TODO: update otherUserPublic state variable, probably with a getPgData, maybe with useEffect?
        }else{ 
          if(addRemove === 'remove'){
            // use /rpc/follow
            const { data, error } = await postPgData('/rpc/unfollow_user', relation, session);
            if (error) {
              console.error('Error:', error);
              console.log(error.message);
              return { error: error.message };
            }
            console.log('postPgData - /rpc/unfollow_user:', data);
            // change button state
            setIsFollowing(false);
            handleError('Unfollowed'); 
            // update userPublic stateVar
            let newUserPublic = userPublic;
            newUserPublic.following_ids=data[0].updated_following_ids;
            newUserPublic.num_followers=data[0].updated_num_following;
            console.log('newUserPublic: ',newUserPublic);
            setUserPublic(newUserPublic);
            // update stored session
            let newSession = session;
            newSession.data.session.userPublic = newUserPublic;
            session.login(newSession);
            // TODO: update otherUserPublic state variable, probably with a getPgData, maybe with useEffect?
            }else{
              console.log('problem with addRemove param')
              handleError('Error')
            }
          }
        }
    }else{
      handleError('Are you logged in?')
    }
  } 
  
  async function handleBlockClick(addRemove, id_to_block) {
    console.log('handleBlockClick');
    if (session.data) {//check if logged in
      if(addRemove === 'block'){
        // use /rpc/block_user
        const { data, error } = await postPgData(
            '/rpc/block_user',
            {
              p_blocker_uid: session.data.session.userPublic.id,
              p_uid_blocked: id_to_block
            },
            session//.data.session.access_token // todo: examine session param for default value case
        )
        if (error) {
            console.error('Error:', error);
            console.log(error.message);
            return { error: error.message };
        }
        console.log('postPgData - /rpc/block:', data);
        // update userPublic stateVar
        let newUserPublic = userPublic;
        newUserPublic.blocked_ids=data[0].updated_blocked_ids;
        newUserPublic.num_blocked=data[0].updated_num_blocked;
        newUserPublic.following_ids=data[0].updated_following_ids;
        newUserPublic.num_following=data[0].updated_num_following;
        newUserPublic.follower_ids=data[0].updated_follower_ids;
        newUserPublic.num_followers=data[0].updated_num_followers;
        console.log('newUserPublic: ',newUserPublic);
        setUserPublic(newUserPublic);
        // update stored session
        let newSession = session;
        newSession.data.session.userPublic = newUserPublic;
        session.login(newSession);
        // alert user, navigate away
        setBlocked(isBlocked(id));
        handleError('Blocked.');
        navigate('/');

  
      }else if(addRemove === 'unblock'){
        // use /rpc/block_user
        const { data, error } = await postPgData(
            '/rpc/unblock_user',
            {
              p_blocker_uid: session.data.session.userPublic.id,
              p_uid_unblocked: id_to_block
            },
            session//.data.session.access_token // todo: examine session param for default value case
        )
        if (error) {
            console.error('Error:', error);
            console.log(error.message);
            return { error: error.message };
        }
        console.log('postPgData - /rpc/unblock_user:', data);
        // update userPublic stateVar
        let newUserPublic = userPublic;
        newUserPublic.blocked_ids=data[0].updated_blocked_ids;
        newUserPublic.num_blocked=data[0].updated_num_blocked;
        console.log('newUserPublic: ',newUserPublic);
        setUserPublic(newUserPublic);
        // update stored session
        let newSession = session;
        newSession.data.session.userPublic = newUserPublic;
        session.login(newSession);
        // alert user, navigate to same page to update DOM;
        // TODO: use a useState, see this page's handleFollowClick for an example, to avoid extra network calls.
        handleSuccess('Unblocked.');
        setBlocked(isBlocked(id));
  
      }else{
        console.log('problem with addRemove param');
        handleError('Something went wrong, try signing in again?');
        return 'error';
      }
    }else{
      handleError('Are you logged in?')
    }
  }

  useEffect(() => {
      const otherUserId = id
      console.log('OtherUserView useEffect otherUserId should match url: ',otherUserId)
      // does not have to be signed on to see this page
      if(session.data){
        console.log('session: ',session.data.user.id,' otherUser(url): ', otherUserId)
        if(session.data.user.id == otherUserId){//done: this works.  imports are important, as are assignments in initial 'export const OtherUserView'
          navigate('/account')
        }
      }
      
      fetchOtherProfile()
      fetchArticles()
      fetchIsFollowing()
      setBlocked(isBlocked(parseInt(id)));
  }, []);

    // isBlocked(blocked_id); returns true if blocked_id in blocked_ids, false otherwise (not in or logged out)
    function isBlocked(blocked_id) {
      console.log('isBlocked:', blocked_id)
      if (session?.data?.session?.userPublic?.blocked_ids){
        console.log(session.data.session.userPublic.blocked_ids)
        console.log(session.data.session.userPublic.blocked_ids.includes(blocked_id))
          return session.data.session.userPublic.blocked_ids.includes(blocked_id);
      }else{
          return false;
      }
    }


  // To prevent runtime errors when an HTTP request fails to set userPublic, you should add error handling to your code. You can use conditional rendering to display an error message or a loading indicator when the userPublic data is not available. Here's an example of how you can modify your code to handle errors gracefully:
  // In this code, we use conditional rendering to check if userPublic and items data are available. If the data is not available, we display a loading indicator. If there's an error loading items, we display an error message. You can adjust the loading and error messages as needed to provide better feedback to your users.
  return (
    <div style={{ height: '100%', backgroundColor: '#0f0f0f', color: 'white', padding: '40px 0px', display: 'flex' , justifyContent: 'center' }}>
      <Container>
        {/** Step 2: Displaying Error Messages in the UI */}
        {errorMessage && (
          <div className="error-message">
              <p>{errorMessage}</p>
              <button onClick={resetError}>Close</button>
          </div>
      )}
      {/** Step 2: Displaying Success Messages in the UI */}
      {successMessage && (
          <div className="success-message">
              <p>{successMessage}</p>
              <button onClick={resetSuccess}>Close</button>
          </div>
      )}
        <div id='profile-container'>
          <Container text>
            <div id='profile-image'>
              <Image src={otherUserPublic.profile_pic_url} as={'a'} avatar style={{ fontSize: '4.2rem', cursor: 'pointer' }} />
            </div>
            <div id='profile-header'>
              {otherUserPublic ? ( // Check if otherUserPublic data is available
                <>
                  <h3>{otherUserPublic.name}</h3>
                  {/* TODO: these buttons fail&lock the dom rn because they're made to work on /account; right now the simplest thing is to remove them                  
                  <Button basic color='blue' size='tiny' onClick={handleFollowingClick}>
                    Following
                  </Button>
                  <Button basic color='teal' style={{ marginLeft: '5px' }} size='tiny' onClick={handleFollowersClick}>
                    Followers
                  </Button> 
                  */}
                  <br />
                  <Button 
                    basic color='blue' 
                    style={{ position: 'relative', top: '1em', padding: '.625rem 1.81rem' }} 
                    size='tiny' 
                    onClick={() => handleFollowClick(otherUserPublic.id, isFollowing ? 'remove' : 'add')}>
                    {isFollowing ? 'Unfollow' : 'Follow'}
                  </Button>
                  <Button 
                    basic color='orange' 
                    style={{ position: 'relative', top: '1em', padding: '.625rem 1.81rem' }} 
                    size='tiny' 
                    onClick={() => handleBlockClick(blocked ? 'unblock' : 'block', otherUserPublic.id)}>
                    {blocked ? 'Unblock' : 'Block'}
                  </Button>
                </>
              ) : (
                // Render a loading indicator while waiting for otherUserPublic data
                <div>Loading...</div>
              )}
            </div>
          </Container>
          {/* Reposition divs once external CSS files are created */}
          <div id='profile-body'>
            <Container text textAlign='left'>
              {otherUserPublic ? ( // Check if otherUserPublic data is available
                <p style={{ fontSize: '1.2em' }}>{otherUserPublic.bio}</p>
              ) : (
                // Render a loading indicator while waiting for otherUserPublic data
                <div>Loading...</div>
              )}
            </Container>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
              <h2>Published Articles</h2>
            </div>
              <br></br>
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'center'}}>
              <p>Click an Article to open it in Feed</p>
            </div>
            <Divider />
            <div id='profile-cards'>
              <div style={{ display: 'inline-block' }}>
                {items.length > 0 ? ( // Check if items are available
                  <Card.Group items={items} />
                ) : (
                  // Render a loading indicator or error message for items
                  items === 'loading' ? (
                    <div>Loading...</div>
                  ) : (
                    <div>No articles published yet</div> // Changed from 'Error loading items'
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
                  }
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import 'semantic-ui-css/semantic.min.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
document.body.style.backgroundColor = "#0f0f0f";
document.body.style.margin = 0;

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);


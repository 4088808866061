// I can't think of a way to do this without exposing the generated code in the network tab; 
// meaning a bad actor could enter someone else's email, look up the code, and then use it as a 
// urlparam to change that person's password.

// yikes.

import { useActionData, useNavigation, useOutletContext } from 'react-router';
import { Form, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import '../styles/LoginSignUpStyles/signup.css';
import { Mail } from '@mui/icons-material';
import { registerWithPg, loginWithPg, getPgData } from '../data/rdsClient';

// TODO: add a recaptcha, and email, and a button to recieve a link to change the password

export const ForgotPasswordView = () => {
  //Introduce State for Error Handling
  const [errorMessage, setErrorMessage] = useState(null);
  const handleError = (message) => {setErrorMessage(message);};
  const resetError = () => {setErrorMessage(null);};
    // Step 1: Introduce State for Success Handling
  const [successMessage, setSuccessMessage] = useState(null);
  const handleSuccess = (message) => {setSuccessMessage(message);};
  const resetSuccess = () => {setSuccessMessage(null);};

    const navigation = useNavigation();
    const navigate = useNavigate();
    const session = useOutletContext();  
    const isLoading =
      navigation.state === 'loading' || navigation.state === 'submitting';
    const data = useActionData();


    // /* ReCaptcha
    const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  
    // https://github.com/google/recaptcha/issues/74
    window.onCaptchaResponse = (res) => {
        //console.log(res);
        window.googleRecaptcha=res
        //console.log('recaptcha set: ', window.googleRecaptcha);
    }
    // ReCaptcha */

    // this is called on form submission
    // fails inside action; succeeds inside useEffect
    useEffect(() => {
      console.log("ForgotPasswordView useEffect")
      console.log("ForgotPasswordView data: ", data)
      if (data && !data.error) {
          console.log('data=', data)
          if (data.pass) {
              session.logout();  
              handleSuccess('Check your email for a link to update your password.');
          } else {
              console.log(data);
              handleError('Something went wrong, try again or email us at singlepaynews@gmail.com')
          }
      } else if (data && data.error) { // Handle the error returned from action
          console.log(data.error);
          handleError(data.error); // Use the handleError defined in the component
          navigate('/captchafailed');
      }
  
      // ReCaptcha
      if (!recaptchaLoaded) {
          const script = document.createElement("script");
          script.src = "https://www.google.com/recaptcha/api.js";
          script.async = true;
          script.defer = true;
          script.onload = () => {
              setRecaptchaLoaded(true);
              console.log('recaptcha script loaded');
          }
          document.body.appendChild(script);
      }
      // ReCaptcha
  }, [recaptchaLoaded, data]);
  

    return(
        <>
            <div style={{height: '100vh', backgroundColor: '#0f0f0f', color: 'white',}}>
                <script src="https://www.google.com/recaptcha/api.js" async defer></script>
                {/* these used to be signup-header and subheader, may be causing a CSS bug */}
                <h1 id='forgotPW-header'>Single Pay News</h1>
                <h3 id='forgotPW-subheader'>Forgot Password</h3>

                <div className='form-container'>

                {/* <StyledFormLayout> */}
                    <Form id='form' action='/forgot-password' method='post'>
                      {/* ReCaptcha */}
                    <div 
                        className="g-recaptcha" 
                        data-sitekey="6Lcz7XwoAAAAAAxDK1oW_mJyfOVFz1ROQfvFM3cb"
                        data-callback={"onCaptchaResponse"} // Use the handleRecaptcha function
                    ></div>
                    {/* /ReCaptcha */}
                    <label className='signup-label'>
                        Email:
                        <input disabled={isLoading&&recaptchaLoaded} type="text" name="email" placeholder={isLoading&&recaptchaLoaded ? 'loading...' : 'email'} className='signup-input'/>
                    </label>
                    <button disabled={isLoading&&recaptchaLoaded} id='forgot-password-button'>
                        {isLoading&&recaptchaLoaded ? 'loading...' : 'Send Email with Link'}
                    </button>
                    </Form>
                    {data && data.error && <div>{data.error}</div>}
                {/* </StyledFormLayout> */}
        {/** Step 2: Displaying Error Messages in the UI */}
        {errorMessage && (
          <div className="error-message">
              <p>{errorMessage}</p>
              <button onClick={resetError}>Close</button>
          </div>
      )}
      {/** Step 2: Displaying Success Messages in the UI */}
      {successMessage && (
          <div className="success-message">
              <p>{successMessage}</p>
              <button onClick={resetSuccess}>Close</button>
          </div>
      )}
                </div>
            </div>
        </>
    )
}


export async function action({ request }) {
  // const navigation = useNavigation();
  // const navigate = useNavigate();
  console.log('action called')

  // /* ReCaptcha
  // check if we passed the recaptcha (see server.js /api/verify-recaptcha)
  const options = { //Default options are marked with *
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    // not sure what credentials is for, revisit when implementing auth. //credentials: "include", // include, *same-origin, omit
    headers: {
        'Content-Type': "application/json"
    },
    // no credentials on recaptcha endpoint credentials: 'include', // need credentials included also when we intend to write a cookie https://stackoverflow.com/questions/73730172/response-header-set-cookie-doesnt-store-cookies-in-browser  https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
    redirect: "follow", // manual, *follow, error
    referrerPolicy: 'strict-origin-when-cross-origin', //"no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify({'recaptchaResponse': window.googleRecaptcha}) // body data type must match "Content-Type" header
  }
  const response = await fetch('https://singlepaynews.com/api/verify-recaptcha', options)//
  const recaptchaResult = await response.json();
  //Important to note, someone can probably use cross-site-scripting to simply set formData.recaptchaResult to 'pass' to bypass all this.  I don't know how that works either.
  // /* ReCaptcha
  if (recaptchaResult.pass === 'pass') {
    // TODO: use rpc/add_temp_code to prime basic_auth.users row to change its password
    const formData = await request.formData();
    const formDataEscape = { 
      email: formData.get('email').toLowerCase()
    };
    try {
      // TODO: use rpc/add_temp_code to prime basic_auth.users row to change its password
      const options = { //Default options are marked with *
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        // not sure what credentials is for, revisit when implementing auth. //credentials: "include", // include, *same-origin, omit
        headers: {
            'Content-Type': "application/json"
        },
        credentials: 'include', // need credentials included also when we intend to write a cookie https://stackoverflow.com/questions/73730172/response-header-set-cookie-doesnt-store-cookies-in-browser  https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
        redirect: "follow", // manual, *follow, error
        referrerPolicy: 'strict-origin-when-cross-origin', //"no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(formDataEscape) // body data type must match "Content-Type" header
      }
      const result = await fetch('https://singlepaynews.com/api/forgot-password', options)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        return data
      })
      .catch((error) => {
        console.error(' error:', error);
        return error;
      });
      console.log(result);
      return result;
    } catch (error) {
      console.log(error)
      throw error;
    }
  } else {
    // /* ReCaptcha
    // fails inside action; succeeds inside useEffect 
    // If reCAPTCHA verification fails, you can display an error to the user.
    
    // Replace this:
    // handleError('Try the reCAPTCHA again?');

    // With this:
    return { error: 'Try the reCAPTCHA again?' }; // Pass the error message back to the component
  }
}

import {
  createRoutesFromElements,
  Outlet,
  RouterProvider,
  useOutletContext,
  useParams,
  useRouteError,
} from 'react-router';
import { useContext, useCallback } from 'react';
import { createBrowserRouter, Route, useNavigate } from 'react-router-dom';
import { MainLayout } from './components/MainLayout';
// import { EdgeErrorLayout } from './components/EdgeErrorLayout.js'; Deprecated.  TODO: Delete this.
import { ProtectedRoute } from './components/ProtectedRoute';
import { PublicRoute } from './components/PublicRoute';

import { LoginView, action as loginAction } from './views/LoginView';
import { useEffect, useState } from 'react';
import { SignUpView} from './views/SignUpView'; // legacy.  You register by purchasing tokens now, and no action form is submitted. //, action as signupAction } from './views/SignUpView';
import { RegisterView, action as registerAction } from './views/RegisterView';
import { ForgotPasswordView, action as forgotpasswordAction } from './views/ForgotPasswordView';
import { AboutView } from './views/AboutView'; 
import { UserView }  from './views/UserView';
import { GeoView }  from './views/GeoView';
import { SettingsView }  from './views/SettingsView';//, action as settingsAction }  from './views/SettingsView';
import { NotFoundView } from './views/NotFoundView';
import { CreateView } from './views/CreateView';//, action as createAction } from './views/CreateView';
import { FollowingView } from './views/FollowingView';
import { FollowersView } from './views/FollowersView';
import { FeedView } from './views/FeedView';
import { OtherUserView } from './views/OtherUserView';
import { ReportView, action as reportAction } from './views/ReportView';
import { logoutWithPg } from './data/rdsClient.js';
// import { PaypalView } from './views/PaypalView.js'; TODO: I think this is unused and should be removed or converted into documentation.
import { ChangePasswordView, action as changePasswordAction } from './views/ChangePasswordView';
import { BuyTokensView } from './views/BuyTokensView';
import { CashOutView } from './views/CashOutView';
import { BalanceView } from './views/BalanceView';
import { HashtagView } from './views/HashtagView';
import { ContestView } from './views/ContestView';
import { CampaignView } from './views/CampaignView';
import { SignedUpNotRegisteredView } from './views/SignedUpNotRegisteredView';
import { CaptchaFailedView } from './views/CaptchaFailedView';
import { NotificationsView } from './views/NotificationsView';
import { SearchResultView } from './views/SearchResultView.js';


const ErrorView = () => {
  const error = useRouteError();

  return (
    <div>
      Error View
      <p>{error.message}</p>
    </div>
  );
};


// SessionProvider component definition...
const SessionProvider = () => {
  // SessionProvider implementation...
  // https://reactrouter.com/en/main/hooks/use-outlet-context
  const [data, setData] = useState(() => { // initial session data
    const storedSession = localStorage.getItem('session'); 
    console.log('storedSession: ',JSON.parse(storedSession)) //this.data.session.access_token
    const parsedSession = JSON.parse(storedSession)
    let escapedSession = null
    if (parsedSession != null){
      escapedSession = parsedSession.data 
    } else {
      escapedSession = null
    }
    console.log('escapedSession: ',escapedSession) //this.session.access_token
    return escapedSession ? escapedSession : null; 
  });

  // state for unchecked notifications
  const [uncheckedNotifs, setUncheckedNotifs] = useState(0);

  // Method to update uncheckedNotifs
  const update_uncheckedNotifs = (new_uncheckedNotifs) => {
    setUncheckedNotifs(new_uncheckedNotifs);
  };

  // Method to update balance
  const updateBalance = (newBalance) => {
    setData((prevData) => ({
      ...prevData,
      session: {
        ...prevData.session,
        userPrivate: {
          ...prevData.session.userPrivate,
          balance: newBalance,
        },
      },
    }));
  };

  const login = (newSessionData) => {
    console.log('newSessionData: ',newSessionData) //this.data.session.access_token
    console.log('balance before:', data?.session?.userPrivate?.balance);
    if (newSessionData) { 
      // Merge the new session data with the existing session data
      // Store the access token in local storage
      localStorage.setItem('session', JSON.stringify(newSessionData));
      // Update the session data
      console.log('newSessionData.data: ',newSessionData.data) //this.session.access_token
      setData(newSessionData.data); // Update the context directly with new session data
      console.log('session updated');
      console.log('balance after:', data?.session?.userPrivate?.balance);
      console.log('newSession balance:', newSessionData?.data?.session?.userPrivate?.balance);
      // After updating the session, notify components about balance changes
      updateBalance(newSessionData.data.session.userPrivate.balance);
    }
  };

  const logout = async () => {
    // Make a request to /rpc/logout to clear refresh_token cookie
    const logoutResp = await logoutWithPg();
    console.log(logoutResp);
    // Clear the access token from local storage and set data to null
    localStorage.removeItem('session');
    setData(null);
    console.log('session updated');
    //await supabase.auth.signOut();
    // TODO: You can add more sign-out logic here if needed, such as invalidating session tokens on the server.
  };

  return <Outlet context={
    { data, login, logout, updateBalance, uncheckedNotifs, update_uncheckedNotifs }
  } />;
};


const router = createBrowserRouter(
  createRoutesFromElements([
    // SessionProvider can maybe be deleted/replaced with AuthContext
    // todo: understand the Session vs AuthContext details; see MainLayout
    <Route element={<SessionProvider />} errorElement={<ErrorView />}>
        <Route element={<MainLayout />}>
          <Route path="/" element={<FeedView />} />
          {/* To link to a particular article, we have to be able to show it at the top of a feed. */}
          <Route path="/:article" element={<FeedView />} />
          <Route path="/about" element={<AboutView />} />
          <Route path="/sunr" element={<SignedUpNotRegisteredView />} />
          <Route path="/captchafailed" element={<CaptchaFailedView />} />
          <Route path="/contest" element={<ContestView />} />
          <Route path="/campaigns" element={<CampaignView />} />
          {/* Todo: is this used? */}
          {/* <Route path="/pay" element={<PaypalView />} /> */}
          {/* Ideally the web_anon payment flow is based on a /component that displays inline in FeedView*/}
          <Route
            path="/buytokens"
            element={
              <ProtectedRoute
                element={<ProtectedRoute element={<BuyTokensView />} />}
              />
            }
          />
          <Route
            path="/cashout"
            element={
              <ProtectedRoute
                element={<ProtectedRoute element={<CashOutView />} />}
              />
            }
          />
          <Route
            path="/balance"
            element={
              <ProtectedRoute
                element={<ProtectedRoute element={<BalanceView />} />}
              />
            }
          />
          {/* Login/Signup: */}
          <Route
            path="/login"
            element={<PublicRoute element={<LoginView />} />}
            action={loginAction}
          />
          <Route
            path="/signup"
            element={<PublicRoute element={<SignUpView />} />}
          />
          {/* Register/Change Password: */}
          <Route
            path="/register/"
            element={<PublicRoute element={<RegisterView />} />}
            action={registerAction}
          />
          <Route
            path="/register/:email/:code/:initial_password"
            element={<PublicRoute element={<RegisterView />} />}
            action={registerAction}
          />
          <Route
            path="/forgot-password"
            element={<PublicRoute element={<ForgotPasswordView />} />}
            action={forgotpasswordAction}
          />
          <Route
            path="/change-password/:email/:code"
            element={<PublicRoute element={<ChangePasswordView />} />}
            action={changePasswordAction}
          />
          {/* unused; todo: use it lol */}
          <Route
            path="/geo"
            element={<PublicRoute element={<GeoView />} />}
          />
          {/* Stuff you *do* follow: */}
          <Route
            path="/account"
            element={
              <ProtectedRoute
                element={<ProtectedRoute element={<UserView />} />}
              />
            }
          />
          <Route
            path="/account/following"
            element={
              <ProtectedRoute
                element={<ProtectedRoute element={<FollowingView />} />}
              />
            }
          />
          <Route
            path="/account/followers"
            element={
              <ProtectedRoute
                element={<ProtectedRoute element={<FollowersView />} />}
              />
            }
          />
          {/* Stuff you can follow: */}
          <Route 
            path="/user/:id" 
            element={<PublicRoute element={<OtherUserView />}/>} 
            />
          <Route
              path="/hashtag"
              element={<PublicRoute element={<HashtagView />} />}
            />
          <Route
              path="/hashtag/:text"
              element={<PublicRoute element={<HashtagView />} />}
            />
          <Route
              path="/hashtag/:text/:id"
              element={<PublicRoute element={<HashtagView />} />}
            />
          {/* <Route path="/search" element={<PublicRoute element={<SearchResultView />} />} /> */}
          <Route
              path="/search/:query"
              element={<PublicRoute element={<SearchResultView />} />}
            />
          {/* Stuff you can post: */}
          <Route
            path="/settings"
            element={<ProtectedRoute element={<SettingsView />} />}
          />
          <Route 
            path="/create" 
            element={<PublicRoute element={<CreateView />}/>}
          />
          <Route
            path="/report/:item_type/:item_id"
            element={<ProtectedRoute element={<ReportView />} />}
            action={reportAction}
          />
          <Route
            path="/notifications"
            element={
              <ProtectedRoute
                element={<ProtectedRoute element={<NotificationsView />} />}
              />
            }
          />
        </Route> {/* End MainLayout */}
        {/* 404: */}
        <Route path="*" element={<NotFoundView />} />
    </Route>,
  ]),
);



export const App = () => {
  // Browser whitelist approach: not working for chrome on desktop
  // const [isNotChromeOrSafari, setIsNotChromeOrSafari] = useState(false);
  
  // useEffect(() => {
  //   // look up what browser is being used--lots of browsers aren't supporting window.alert/other stuff and it leads to users double-posting
  //   const userAgent = window.navigator.userAgent;
  //   console.log("User Agent:", userAgent);
  //   const isChrome = /Chrome/.test(userAgent) && !/(Edg|OPR|Brave|Chromium|Safari|Avast)/.test(userAgent);
  //   const isFirefox = /Firefox/.test(userAgent);
  //   const isSafari = /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
  //   setIsNotChromeOrSafari(!isChrome && !isFirefox && !isSafari);
  // }, []);

  // console.log("isNotChromeOrSafari:", isNotChromeOrSafari);

  // Browser blacklist approach
  // DEPRECATED -- we think window.alert() is the only problem, and now we can support edge
  // const [isEdge, setIsEdge] = useState(false);
  // useEffect(() => {
  //   const userAgent = window.navigator.userAgent;
  //   console.log("User Agent:", userAgent);
  //   setIsEdge(userAgent.indexOf("Edg") > -1); // By changing "Edge" to "Edg", the code will correctly identify Microsoft Edge in the user agent string and display the appropriate message when Edge is detected.
  // }, []);
  // console.log("isEdge:", isEdge);

  return (
    <div>
      {/* 
  // DEPRECATED -- we think window.alert() is the only problem, and now we can support edge{isEdge ? (
        <EdgeErrorLayout />
      ) : ( */}
        <RouterProvider router={router}>
          <SessionProvider>
            <MainLayout>
              <Outlet />
            </MainLayout>
          </SessionProvider>
        </RouterProvider>
      {/* )} */}
    </div>
  )
};

// Note the new order of parameters.  This is for ease-of-use w.r.t. default values, and has the happy side effect of forcing thoughtful updates by making old uses of these functions throw errors.

// see src/views/TestRdsClientView.js
//import { createClient } from 'rdsJsPackage'
// class rdsClient {
//     constructor(url, key){
//         this.url = url;
//         this.key = key;
//     }
// }

// todo: what about when tokens expire?  pgrst replies like this:
// [Log] getPgData:  (bundle.js, line 9522)
// Object
// code: "PGRST301"
// details: null
// hint: null
// message: "JWT expired"
// Object Prototype


// Todo: put in an .env file to hide from haxors
//const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const rdsUri = 'https://www.singlepaynews.com:3000'
const signupEndpoint = '/rpc/signup'
//const supabaseAnonKey = process.env.REACT_APP_SUPABASE_KEY
//const rdsKey = ''
//export const supabase = createClient(supabaseUrl, supabaseAnonKey)
//export const rdsClient = rdsClient(rdsUri, rdsKey)

// // [Error] Response data is not an array:
// Object
// code: "PGRST301"
// details: null
// hint: null
// message: "JWT expired"
// Object Prototype
// 	(anonymous function) (bundle.js:5797)
async function graceful_token_refresh(storedSession) {
  // get a new access token and return it
  // if the refresh token is expired, or anything else goes wrong, return false

  // get the userId from storedSession
  const userId = storedSession.data.user.id;

  // setup options for /rpc/graceful_access_token_refresh
  const options = { //Default options are marked with *
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    // not sure what credentials is for, revisit when implementing auth. //credentials: "include", // include, *same-origin, omit
    headers: {
        'Content-Type': "application/json",
        'Prefer': 'return=representation' // return headers-only also an option 
    },
    credentials: 'include', // need credentials included also when we intend to write a cookie https://stackoverflow.com/questions/73730172/response-header-set-cookie-doesnt-store-cookies-in-browser  https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
    redirect: "follow", // manual, *follow, error
    referrerPolicy: 'strict-origin-when-cross-origin', //"no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify({user_id: userId}) // body data type must match "Content-Type" header
  }
  console.log('gatr request body: ',options.body);
  try{
    const gatrResponse = await fetch(rdsUri + '/rpc/graceful_access_token_refresh', options);
    console.log('gatrResponse', gatrResponse);
    const gatrData = await gatrResponse.json();
    console.log('gatrResponse.json: ',gatrData)
    const gatrResponseParsed = JSON.parse(gatrData);
    console.log('gatrResponseParsed', gatrResponseParsed);
    //console.log('gatrResponseParsed.token!=undefined: ', gatrResponseParsed.refreshed_access_token!=undefined)
    //console.log('gatrResponseParsed.token: ', gatrResponseParsed.refreshed_access_token)

    if (gatrResponseParsed.refreshed_access_token!=undefined) { // Object.hasOwn check fails to catch when true
      // return new access token --todo: update storedSession inside graceful_token_refresh?
      return gatrResponseParsed.refreshed_access_token;
    }
    // if (Object.hasOwn(gatrData, 'message')) { // this conditional isn't catching its case
    //   if (gatrData.message == "login expired") {
    //     return false;
    //   }
    // }
    else { // should be unreachable, but is de facto the catch for gatrData.message == "login expired"
      console.error('graceful_access_token_refresh error: ', gatrData.message);
      // throw gatrData.message;
      return false;
    }
  }catch (error) {
    console.error('graceful_access_token_refresh error: ', error);
    throw error;
  }
}

// rest get = crud read
export async function getPgData(endpoint = "/error", data = {},  storedSession, url = rdsUri) {
  let Token = ""
  console.log('storedSession: ', storedSession);
  console.log('typeof storedSession: ', (typeof storedSession));

  // temporary test: what is type of storedSession?
  // TODO: I am highly suspicious of a system that needs to do type checks.  If session is always an object, except when undefined (logged out), we should be able to handle pgData calls gracefully.
  if (typeof storedSession == "object"){ // passed as session object
    if (storedSession.data==null){ // when not signed in this is an object, TypeError: null is not an object (evaluating 'n.data.session')
      Token = "";
    } else {
      Token = storedSession.data.session.access_token;
    }
  }else if (typeof storedSession == "string"){ // passed as Token string
    Token = storedSession;
  }else if (typeof storedSession == "undefined"){ // passed as session object, but not logged in so no session
    Token = "";
  }else{ // who knows what's up, let's just say empty string.
    Token = "";
  }
  console.log("Token after type check: ", Token);
  //console.log('inside getPgData:', endpoint, data, Token) // old debugging message 
  let options = {}
  if (Token === ""){//todo: strictly ""? Or any Falsy value?
      options = { //Default options are marked with *
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      // not sure what credentials is for, revisit when implementing auth. //credentials: "include", // include, *same-origin, omit
      headers: {
          'Content-Type': "application/json",
          'Prefer': 'return=representation' // return headers-only also an option
      },
      credentials: 'include', // need credentials included also when we intend to write a cookie https://stackoverflow.com/questions/73730172/response-header-set-cookie-doesnt-store-cookies-in-browser  https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
      redirect: "follow", // manual, *follow, error
      referrerPolicy: 'strict-origin-when-cross-origin', //"no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    }
  }else{
      options = { //Default options are marked with *
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      // not sure what credentials is for, revisit when implementing auth. //credentials: "include", // include, *same-origin, omit
      headers: {
          'Content-Type': "application/json",
          'Prefer': 'return=representation', // return headers-only also an option
          'Authorization': `Bearer ${Token}` //ToDo: make articles fresh public to read
      },
      credentials: 'include', // need credentials included also when we intend to write a cookie https://stackoverflow.com/questions/73730172/response-header-set-cookie-doesnt-store-cookies-in-browser  https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
      redirect: "follow", // manual, *follow, error
      referrerPolicy: 'strict-origin-when-cross-origin', //"no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    }
  }
  
  try {
      console.log(url+endpoint, options)
      const response = await fetch(url + endpoint, options);
  
      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
  
      const responseData = await response.json();

    if (responseData.code == "PGRST301"){
      if (responseData.message == "JWT expired"){
        console.log('attempt graceful refresh');
        // graceful access token refresh returns new access token on success, false otherwise
        const success = await graceful_token_refresh(storedSession)
        if (success){
          // update stored session --todo: update storedSession inside graceful_token_refresh?
          let newSession = storedSession;
          newSession.data.session.access_token = success;
          // todo: .login is not a function?  I may need to find a way to wrap this in <SessionProvider>
          storedSession.login(newSession);
          // retry request
          // TODO: this does not appear to be using new token on new request.  do I need session in every page's useEffect?
          try {
            // manually update options while I wait to understand why .login() doesn't
            const newOptions = { //Default options are marked with *
              method: "GET", // *GET, POST, PUT, DELETE, etc.
              mode: "cors", // no-cors, *cors, same-origin
              cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
              // not sure what credentials is for, revisit when implementing auth. //credentials: "include", // include, *same-origin, omit
              headers: {
                  'Content-Type': "application/json",
                  'Prefer': 'return=representation', // return headers-only also an option
                  'Authorization': `Bearer ${success}` //ToDo: make articles fresh public to read
              },
              credentials: 'include', // need credentials included also when we intend to write a cookie https://stackoverflow.com/questions/73730172/response-header-set-cookie-doesnt-store-cookies-in-browser  https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
              redirect: "follow", // manual, *follow, error
              referrerPolicy: 'strict-origin-when-cross-origin', //"no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            }
            console.log(url+endpoint, newOptions)
            const responseRetry = await fetch(url + endpoint, newOptions);
            const responseDataRetry = await responseRetry.json();
            console.log('getPgData: ', responseDataRetry); // if this also fails then ask to sign in again
            if (responseDataRetry.code == "PGRST301"){
              if (responseDataRetry.message == "JWT expired"){
                window.alert('login expired, please log out/in again') // TODO: deprecate window.alert by passing the error message out to caller
              }}
            return {data: responseDataRetry, error: null};
          } catch (error) {
            console.error('getPgData error: ', error);
            throw error;
          }
        }else{
          console.log('graceful_token_refresh problem: ', success)
          window.alert('login expired, please sign in again') // TODO: deprecate window.alert by passing the error message out to caller
        }
      }
    }
    console.log('getPgData: ', responseData);
    return {data: responseData, error: null};
  } catch (error) {
    console.error('getPgData error: ', error);
    throw error;
  }
}

  // rest Post = crud Create
  export async function postPgData(endpoint = "/error", data = {},  storedSession, url = rdsUri) { // default endpoint to /error to force proper input
    let Token = ""
    console.log('storedSession: ', storedSession);
    console.log('typeof storedSession: ', (typeof storedSession));
  
    // temporary test: what is type of storedSession?
  // TODO: I am highly suspicious of a system that needs to do type checks.  If session is always an object, except when undefined (logged out), we should be able to handle pgData calls gracefully.
    if (typeof storedSession == "object"){ // passed as session object
      if (storedSession.data==null){ // when not signed in this is an object, TypeError: null is not an object (evaluating 'n.data.session')
        Token = "";
      } else {
        Token = storedSession.data.session.access_token;
      }
    }else if (typeof storedSession == "string"){ // passed as Token string
      Token = storedSession;
    }else if (typeof storedSession == "undefined"){ // passed as session object, but not logged in so no session
      Token = "";
    }else{ // who knows what's up, let's just say empty string.
      Token = "";
    }
    console.log("Token after type check: ", Token);
    console.log('inside postPgData:', data) // old debugging message 
    const options = { //Default options are marked with *
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        // not sure what credentials is for, revisit when implementing auth. //credentials: "include", // include, *same-origin, omit
        headers: {
            'Content-Type': "application/json",
            'Prefer': 'return=representation', // return headers-only also an option
            'Authorization': `Bearer ${Token}` //ToDo: What if no token?
        },
        credentials: 'include', // need credentials included also when we intend to write a cookie https://stackoverflow.com/questions/73730172/response-header-set-cookie-doesnt-store-cookies-in-browser  https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
        redirect: "follow", // manual, *follow, error
        referrerPolicy: 'strict-origin-when-cross-origin', //"no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    }
    try {
      console.log('#######################')
        console.log(url+endpoint, options)
        const response = await fetch(url + endpoint, options);
    
        // if (!response.ok) {
        //   throw new Error('Network response was not ok');
        // }
    
        const responseData = await response.json();
        if (responseData.code == "PGRST301"){
          if (responseData.message == "JWT expired"){
            // graceful access token refresh returns new access token on success, false otherwise
            const success = await graceful_token_refresh(storedSession)
            if (success){
              // update stored session --todo: update storedSession inside graceful_token_refresh?
              let newSession = storedSession;
              newSession.data.session.access_token = success;
              storedSession.login(newSession);
              // retry request
              try {
                // manually update options while I wait to understand why .login() doesn't
                const newOptions =  { //Default options are marked with *
                  method: "POST", // *GET, POST, PUT, DELETE, etc.
                  mode: "cors", // no-cors, *cors, same-origin
                  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                  // not sure what credentials is for, revisit when implementing auth. //credentials: "include", // include, *same-origin, omit
                  headers: {
                      'Content-Type': "application/json",
                      'Prefer': 'return=representation', // return headers-only also an option
                      'Authorization': `Bearer ${success}` //ToDo: What if no token?
                  },
                  credentials: 'include', // need credentials included also when we intend to write a cookie https://stackoverflow.com/questions/73730172/response-header-set-cookie-doesnt-store-cookies-in-browser  https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
                  redirect: "follow", // manual, *follow, error
                  referrerPolicy: 'strict-origin-when-cross-origin', //"no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                  body: JSON.stringify(data) // body data type must match "Content-Type" header
                }
                console.log(url+endpoint, newOptions)
                const responseRetry = await fetch(url + endpoint, newOptions);
                const responseDataRetry = await responseRetry.json();
                console.log('getPgData: ', responseDataRetry);
                if (responseDataRetry.code == "PGRST301"){
                  if (responseDataRetry.message == "JWT expired"){
                    window.alert('login expired, please log out/in again') // TODO: deprecate window.alert by passing the error message out to caller
                  }}
                return {data: responseDataRetry, error: null};
              } catch (error) {
                console.error('getPgData error: ', error);
                throw error;
              }
            }else{
              console.log('graceful_token_refresh problem: ', success)
              window.alert('login expired, please sign in again') // TODO: deprecate window.alert by passing the error message out to caller
            }
          }
        }
        console.log('postPgData: ', responseData);
        return {data: responseData, error: null};
      } catch (error) {
        console.error('postPgData error: ', error);
        throw error;
      }
    }


// rest Patch = Crud Update
//"To update a row or rows in a table, use the PATCH verb.""
//https://postgrest.org/en/stable/references/api/tables_views.html#update
//TODO: add horizontal filtering to patch to prevent table-wide update
//TODO: see deletePgData for other ways to implement this safety feature
export async function patchPgData(endpoint = "/error", data = {},  storedSession, url = rdsUri) {
  let Token = "";
  console.log('storedSession: ', storedSession);
  console.log('typeof storedSession: ', (typeof storedSession));

  // temporary test: what is type of storedSession?
  // TODO: I am highly suspicious of a system that needs to do type checks.  If session is always an object, except when undefined (logged out), we should be able to handle pgData calls gracefully.
  if (typeof storedSession == "object"){ // passed as session object
    Token = storedSession.data.session.access_token;
  }else if (typeof storedSession == "string"){ // passed as Token string
    Token = storedSession;
  }else if (typeof storedSession == "undefined"){ // passed as session object, but not logged in so no session
    Token = "";
  }else{ // who knows what's up, let's just say empty string.
    Token = "";
  }
  console.log('inside patchPgData:', data) // old debugging message 
  //TODO: add horizontal filtering to patch to prevent table-wide update
  if(endpoint.includes('?')){
    const options = { //Default options are marked with *
        method: "PATCH", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        // not sure what credentials is for, revisit when implementing auth. //credentials: "include", // include, *same-origin, omit
        headers: {
            'Content-Type': "application/json",
            'Prefer': 'return=representation', // return headers-only also an option
            'Authorization': `Bearer ${Token}` //ToDo: What if no token?
        },
        credentials: 'include', // need credentials included also when we intend to write a cookie https://stackoverflow.com/questions/73730172/response-header-set-cookie-doesnt-store-cookies-in-browser  https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
        redirect: "follow", // manual, *follow, error
        referrerPolicy: 'strict-origin-when-cross-origin', //"no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    }
    try {
        console.log(url+endpoint, options)
        const response = await fetch(url+endpoint, options);
    
        // if (!response.ok) {
        //   throw new Error('Network response was not ok');
        // }
    
        const responseData = await response.json();
        if (responseData.code == "PGRST301"){
          if (responseData.message == "JWT expired"){
            // graceful access token refresh returns new access token on success, false otherwise
            const success = await graceful_token_refresh(storedSession)
            if (success){
              // update stored session --todo: update storedSession inside graceful_token_refresh?
              let newSession = storedSession;
              newSession.data.session.access_token = success;
              storedSession.login(newSession);
              // retry request
              try {
                // manually update options while I wait to understand why .login() doesn't
                const newOptions = { //Default options are marked with *
                  method: "PATCH", // *GET, POST, PUT, DELETE, etc.
                  mode: "cors", // no-cors, *cors, same-origin
                  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                  // not sure what credentials is for, revisit when implementing auth. //credentials: "include", // include, *same-origin, omit
                  headers: {
                      'Content-Type': "application/json",
                      'Prefer': 'return=representation', // return headers-only also an option
                      'Authorization': `Bearer ${success}` //ToDo: What if no token?
                  },
                  credentials: 'include', // need credentials included also when we intend to write a cookie https://stackoverflow.com/questions/73730172/response-header-set-cookie-doesnt-store-cookies-in-browser  https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
                  redirect: "follow", // manual, *follow, error
                  referrerPolicy: 'strict-origin-when-cross-origin', //"no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                  body: JSON.stringify(data) // body data type must match "Content-Type" header
                }
                console.log(url+endpoint, newOptions)
                const responseRetry = await fetch(url + endpoint, newOptions);
                const responseDataRetry = await responseRetry.json();
                console.log('getPgData: ', responseDataRetry);
                if (responseDataRetry.code == "PGRST301"){
                  if (responseDataRetry.message == "JWT expired"){
                    window.alert('login expired, please log out/in again') // TODO: deprecate window.alert by passing the error message out to caller
                  }}
                return {data: responseDataRetry, error: null};
              } catch (error) {
                console.error('getPgData error: ', error);
                throw error;
              }
            }else{
              console.log('graceful_token_refresh problem: ', success)
              window.alert('login expired, please sign in again') // TODO: deprecate window.alert by passing the error message out to caller
            }
          }
        }
        console.log('patchPgData: ', responseData);
    
        return {data: responseData, error: null};
      } catch (error) {
        console.error('patchPgData error: ', error);
        throw error;
      }
    }else{
      console.log('patchPgData Error: Are you using a horizontal filter?  if statement in rdsClient is preventing full-table update.  Endpoint:',endpoint);
      console.error('patchPgData Error: Are you using a horizontal filter?  if statement in rdsClient is preventing full-table update.  Endpoint:',endpoint);
      return {data: null, error: 'something went wrong'};
    }
  }

// REST remove = CRUD Delete
// postgrest supports an extension pg_safeupdate that will prevent accidental full-table deletes
// https://postgrest.org/en/stable/integrations/pg-safeupdate.html#block-full-table-operations
// unfortunately, AWS doesn't support that extension
// https://docs.aws.amazon.com/AmazonRDS/latest/PostgreSQLReleaseNotes/postgresql-extensions.html
// It could maybe be added as pg_tle, a, AWS Trusted Language Extensions
// https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/Appendix.PostgreSQL.CommonDBATasks.Extensions.html
// But I'm not going to worry about that right now, and instead add a parameter LIMIT that defaults to 1
// https://postgrest.org/en/stable/references/api/tables_views.html#limited-update-delete
// TODO: add pg_safeupdate ^^
// "To delete rows in a table, use the DELETE verb plus Horizontal Filtering. "
//https://postgrest.org/en/stable/references/api/tables_views.html#delete
export async function deletePgData(endpoint = "/error", data = {},  storedSession, url = rdsUri) {
  let Token = "";
  console.log('storedSession: ', storedSession);
  console.log('typeof storedSession: ', (typeof storedSession));

  // temporary test: what is type of storedSession?
  // TODO: I am highly suspicious of a system that needs to do type checks.  If session is always an object, except when undefined (logged out), we should be able to handle pgData calls gracefully.
  if (typeof storedSession == "object"){ // passed as session object
    Token = storedSession.data.session.access_token;
  }else if (typeof storedSession == "string"){ // passed as Token string
    Token = storedSession;
  }else if (typeof storedSession == "undefined"){ // passed as session object, but not logged in so no session
    Token = "";
  }else{ // who knows what's up, let's just say empty string.
    Token = "";
  }
  console.log('inside deletePgData:', data) // old debugging message 
  // You can limit the amount of affected rows by Update or Delete with the limit query parameter. For this, you must add an explicit order on a unique column(s).
  if(endpoint.includes('order')){
    const options = 
    { //Default options are marked with *
      method: "DELETE", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      // not sure what credentials is for, revisit when implementing auth. //credentials: "include", // include, *same-origin, omit
      headers: {
          'Content-Type': "application/json",
          'Prefer': 'return=representation', // return headers-only also an option
          'Authorization': `Bearer ${Token}` //ToDo: What if no token?
      },
      credentials: 'include', // need credentials included also when we intend to write a cookie https://stackoverflow.com/questions/73730172/response-header-set-cookie-doesnt-store-cookies-in-browser  https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
      redirect: "follow", // manual, *follow, error
      referrerPolicy: 'strict-origin-when-cross-origin', //"no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    }
    try {
      console.log(url+endpoint, options)
      const response = await fetch(url+endpoint, options);

      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }

      const responseData = await response.json();
      if (responseData.code == "PGRST301"){
        if (responseData.message == "JWT expired"){
          // graceful access token refresh returns new access token on success, false otherwise
          const success = await graceful_token_refresh(storedSession)
          if (success){
            // update stored session --todo: update storedSession inside graceful_token_refresh?
            let newSession = storedSession;
            newSession.data.session.access_token = success;
            storedSession.login(newSession);
            // retry request
            try {
              // manually update options while I wait to understand why .login() doesn't
              const newOptions = 
              { //Default options are marked with *
                method: "DELETE", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                // not sure what credentials is for, revisit when implementing auth. //credentials: "include", // include, *same-origin, omit
                headers: {
                    'Content-Type': "application/json",
                    'Prefer': 'return=representation', // return headers-only also an option
                    'Authorization': `Bearer ${success}` //ToDo: What if no token?
                },
                credentials: 'include', // need credentials included also when we intend to write a cookie https://stackoverflow.com/questions/73730172/response-header-set-cookie-doesnt-store-cookies-in-browser  https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
                redirect: "follow", // manual, *follow, error
                referrerPolicy: 'strict-origin-when-cross-origin', //"no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data) // body data type must match "Content-Type" header
              };
              console.log(url+endpoint, newOptions)
              const responseRetry = await fetch(url + endpoint, newOptions);
              const responseDataRetry = await responseRetry.json();
              console.log('getPgData: ', responseDataRetry);
              if (responseDataRetry.code == "PGRST301"){
                if (responseDataRetry.message == "JWT expired"){
                  window.alert('login expired, please log out/in again') // TODO: deprecate window.alert by passing the error message out to caller
                }}
              return {data: responseDataRetry, error: null};
            } catch (error) {
              console.error('getPgData error: ', error);
              throw error;
            }
          }else{
            console.log('graceful_token_refresh problem: ', success)
            window.alert('login expired, please sign in again') // TODO: deprecate window.alert by passing the error message out to caller
          }
        }
      }
      console.log('deletePgData: ', responseData);

      return {data: responseData, error: null};
    } catch (error) {
      console.error('deletePgData error: ', error);
      throw error;
    }
  }else{
    console.log('deletePgData Error: You must add an explicit order on a unique column(s).');
    console.error('deletePgData Error: You must add an explicit order on a unique column(s).');
  }
}



// signup is an http POST against a /rpc/signup endpoint, referencing a basic_auth.unverified_users table that is not publicly exposed
export async function signupWithPg(data = {}, url = rdsUri, endpoint = signupEndpoint) {
  //console.log('inside signupWithPg', JSON.stringify(data)) 
  const options = { 
      method: "POST", 
      mode: "cors", 
      cache: "no-cache",
      headers: {
          'Content-Type': "application/json",
          'Prefer': 'return=representation', 
      },
      // TODO: include credentials, and set a cookie with password to autofill link for auth?
      redirect: "follow", // manual, *follow, error
      referrerPolicy: 'strict-origin-when-cross-origin', 
      body: JSON.stringify(data) // body data type must match "Content-Type" header
  }
  try {
      console.log(url+endpoint, options)


    const response = await fetch(url + endpoint, options);

    if (!response.ok) {
        //throw new Error('Network response was not ok');
        const responseData = await response.json();
        console.log('signupWithPg: ', responseData);
        return {data: null, error: responseData};
      }

    const responseData = await response.json();

    console.log('signupWithPg: ', responseData);

    return {data: responseData, error: null};
  } catch (error) {
    console.error('signupWithPg error: ', error);
    throw error;
  }
}



// register is an http POST against a /rpc/verify endpoint, referencing a basic_auth.unverified_users table that is not publicly exposed
// ToDo: we now want this to set a new password, other than the default
export async function registerWithPg(data = {}, url = rdsUri, endpoint = "/rpc/verify" ) {
  //console.log('inside registerWithPg', JSON.stringify(data)) 
  const options = { 
      method: "POST", 
      mode: "cors", 
      cache: "no-cache",
      headers: {
          'Content-Type': "application/json",
          'Prefer': 'return=representation', 
      },
      // TODO: include credentials, and set a cookie with password to autofill link for auth?
      redirect: "follow", // manual, *follow, error
      referrerPolicy: 'strict-origin-when-cross-origin', 
      body: JSON.stringify(data) // body data type must match "Content-Type" header
  }
  try {
      console.log(url+endpoint, options)


    const response = await fetch(url + endpoint, options);

    if (!response.ok) {
        //throw new Error('Network response was not ok');
        const responseData = await response.json();
        console.log('registerWithPg: ', responseData);
        return {data: null, error: responseData};
      }

    const responseData = await response.json();

    console.log('registerWithPg: ', responseData);
    // {token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiY…DY3fQ.EteQmP2wvVnM1RCG6z1Ioe5iLacT9LLez0k5X5GVe74'}token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYXV0aGVudGljYXRlZF91c2VyIiwiZW1haWwiOiJkb3VnbGFzLmUubWNraW5sZXlAZ21haWwuY29tIiwiZXhwIjoxNzAxNzQyMDY3fQ.EteQmP2wvVnM1RCG6z1Ioe5iLacT9LLez0k5X5GVe74"[[Prototype]]: Object}

    return {data: responseData, error: null};
  } catch (error) {
    console.error('registerWithPg error: ', error);
    throw error;
  }
}


// login is an http POST against a /rpc/login endpoint, referencing a basic_auth.users table that is not publicly exposed
export async function loginWithPg(data = {}, url = rdsUri, endpoint = "/rpc/login") {
  //console.log('inside loginWithPg', JSON.stringify(data)) 
  const options = { 
      method: "POST", 
      mode: "cors", 
      cache: "no-cache",
      headers: {
          'Content-Type': "application/json",
          'Prefer': 'return=representation', 
      },
      credentials: 'include', // need credentials included also when we intend to write a cookie https://stackoverflow.com/questions/73730172/response-header-set-cookie-doesnt-store-cookies-in-browser  https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
      redirect: "follow", // manual, *follow, error
      referrerPolicy: 'strict-origin-when-cross-origin', 
      body: JSON.stringify(data) // body data type must match "Content-Type" header
  }
  try {
      console.log(url+endpoint, options)


    const response = await fetch(url + endpoint, options);

    if (!response.ok) {
      //throw new Error('Network response was not ok');
      const responseData = await response.json();
      console.log('loginWithPg: ', responseData);
      return {data: null, error: responseData};
    }

    const responseData = await response.json();

    console.log('loginWithPg: ', responseData);

    return {data: responseData, error: null};
  } catch (error) {
    console.error('loginWithPg error: ', error);
    throw error;
  }
}

// TODO: add a /rpc/logout endpoint that removes the refresh-token cookie

// https://stackoverflow.com/questions/5285940/correct-way-to-delete-cookies-server-side

/**
 * Sending the same cookie value with ; expires appended will not destroy the cookie.

Invalidate the cookie by setting an empty value and include an expires field as well:

Set-Cookie: token=deleted; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT
Note that you cannot force all browsers to delete a cookie. The client can configure the browser in such a way that the cookie persists, even if it's expired. Setting the value as described above would solve this problem.
 */
export async function logoutWithPg(url = rdsUri, endpoint = "/rpc/logout") {
  const options = { 
    method: "POST", 
    mode: "cors", 
    cache: "no-cache",
    headers: {
        'Content-Type': "application/json",
        'Prefer': 'return=representation', 
    },
    credentials: 'include', // need credentials included also when we intend to write a cookie https://stackoverflow.com/questions/73730172/response-header-set-cookie-doesnt-store-cookies-in-browser  https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
    redirect: "follow", // manual, *follow, error
    referrerPolicy: 'strict-origin-when-cross-origin'
  }
  try {
    console.log(url+endpoint, options)
    const response = await fetch(url + endpoint, options);
    if (!response.ok) {
      //throw new Error('Network response was not ok');
      console.log('logoutWithPg: ', response);
      return {data: null, error: response};
    }
    console.log('logoutWithPg: ', response);
    return {data: response, error: null};
  } catch (error) {
  console.error('logoutWithPg error: ', error);
  throw error;
  }
}

/**
 * execute rpc/update_user_password
 */
// const { data, error } = await changePasswordWithPg( // basic_auth.update_user_password input_email TEXT, input_pass TEXT, input_code TEXT
//           {
//             input_code: formDataEscape.code,
//             input_email: formDataEscape.email,
//             input_pass: formDataEscape.password
//           })
export async function changePasswordWithPg(data={}, url = rdsUri, endpoint = "/rpc/update_user_password") {
  const options = { 
    method: "POST", 
    mode: "cors", 
    cache: "no-cache",
    headers: {
        'Content-Type': "application/json",
        'Prefer': 'return=representation', 
    },
    credentials: 'include', // need credentials included also when we intend to write a cookie https://stackoverflow.com/questions/73730172/response-header-set-cookie-doesnt-store-cookies-in-browser  https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
    redirect: "follow", // manual, *follow, error
    referrerPolicy: 'strict-origin-when-cross-origin',
    body: JSON.stringify(data)
  }
  try {
    console.log(url+endpoint, options)
    const response = await fetch(url + endpoint, options);
    if (!response.ok) {
      //throw new Error('Network response was not ok');
      console.log('changePasswordWithPg: ', response);
      return {data: null, error: response};
    }
    console.log('changePasswordWithPg: ', response);
    return {data: response, error: null};
  } catch (error) {
  console.error('changePasswordWithPg error: ', error);
  throw error;
  }
}

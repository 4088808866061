// todo:import from Navigation.js

import styled from 'styled-components';

export const Footer = () => {
  /* after testing from blank, can't scroll all the way down!  
  I think we need the footer for that, and can just make it 
  an empty footer */
  /** Nope.  Didn't fix the scroll problem */
  return <StyledFooter></StyledFooter>;
};

const StyledFooter = styled.div`
  padding: 20px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #0f0f0f;
  color: #ffffff;
  a {
    color: #ffffff;
    margin: 0 10px;
  }
`;

import { Container, List, Image, Button, Tab } from "semantic-ui-react";
import { useState, useEffect } from "react";
import { useNavigation, useOutletContext } from 'react-router';
import { useNavigate, Form } from 'react-router-dom';
import avatar from './images/Jaden.JPG';
import { getPgData, postPgData } from '../data/rdsClient';
import { Token } from "@mui/icons-material";

//TODO: what if no followers yet?
//TODO: update DOM after blocking a user
//TODO: what if looking at the followers of OtherUser?

//TODO: add a X button that navigates back to /account


export const FollowersView = () => {
    //Introduce State for Error Handling
    const [errorMessage, setErrorMessage] = useState(null);
    const handleError = (message) => {setErrorMessage(message);};
    const resetError = () => {setErrorMessage(null);};
      // Step 1: Introduce State for Success Handling
    const [successMessage, setSuccessMessage] = useState(null);
    const handleSuccess = (message) => {setSuccessMessage(message);};
    const resetSuccess = () => {setSuccessMessage(null);};
    
    const navigation = useNavigation();
    const navigate = useNavigate();
    const session = useOutletContext(); // outletContext session is holding the logged-in-user's info, it goes in every view and can be used t check if a user is logged in.
    console.log(session)

    const [userPublic, setUserPublic] = useState(session.data.session.userPublic);
    // state variables are used for displaying things in the DOM(html) that we plan to query the RDS for in fetch()
    // followerDetails is going to be an array, where the ids from userPublic.follower_ids are converted into json objects that have the id, profile_pic_url, and name
    const [followers, setFollowers] = useState();

    async function fetchFollowerDetails() {
        console.log('fetchFollowerDetails');
        let token = '';
        let userId = '';
        if(session.data){
            console.log('fetchFollowerDetails session=',session)
            token = session.data.session.access_token
            userId = session.data.user.id
        }else{
            handleError('Are you logged in?')
            return 'not logged in'
        }
        console.log('fetchFollowerDetails follower_ids:', userPublic.follower_ids)

        try {
            const { data: followersData, error: followersError } = await getPgData(`/userpublic?id=in.(${userPublic.follower_ids})`) // todo: examine session param for default value case

            if (followersError) {
                console.error('Error:', followersError);
                console.log(followersError.message);
                return { error: followersError.message };
            }
      
            console.log('getPgData - followersData:', followersData);
            setFollowers(followersData);
        } catch (error) {
            console.error('fetchFollowerDetails error:', error);
            return { error: error.message };
        }
    }

    async function handleBlockClick(id_to_block) {
        console.log('handleBlockClick');
        if (session.data) {//check if logged in
            // use /rpc/block
            const { data, error } = await postPgData(
                '/rpc/block',
                {
                    blocker_uid: session.data.session.userPublic.id,
                    uid_blocked: id_to_block
                },
                session//.data.session.access_token // todo: examine session param for default value case
            )
            if (error) {
                console.error('Error:', error);
                console.log(error.message);
                return { error: error.message };
            }
            console.log('postPgData - /rpc/block:', data);
            // update userPublic stateVar
            let newUserPublic = userPublic;
            console.log('newUserPublic: ',newUserPublic)
            newUserPublic.following_ids=data[0].updated_following_ids // todo: maybe data[0].following_ids
            newUserPublic.num_followers=data[0].updated_numfollowing // todo: maybe data[0].numfollowing
            setUserPublic(newUserPublic);
            // update stored session
            let newSession = session;
            newSession.data.session.userPublic = newUserPublic;
            session.login(newSession);
            // alert user, navigate away
            handleError('Blocked.');
            navigate('/');
        }else{
            handleError('Are you logged in?')
        }
    }
    
    // and finally the page's useEffect is used to call the fetch() function, which should set the State variables
    useEffect(() => {
        const userId = session.data.user.id
        console.log('FollowersView useEffect userId: ',userId)

        fetchFollowerDetails()
    }, []);

    return(
<div style={{padding:"50px 0", backgroundColor: '#0f0f0f', color: 'white', height:'100vh'}}>
    <Container style={{backgroundColor: '#1b1c1d', padding: '1rem 1.3rem', borderRadius: '3px'}}>
        <h2>Followers</h2>

    {followers ? (followers.map(follower => (
        <List divided verticalAlign='middle' inverted>
            <List.Item style={{padding: '10px 0px'}} href={`/user/${follower.id}`}>
                <Image avatar src={follower.profile_pic_url} />
                <List.Content>{follower.name}</List.Content>
                <List.Content floated='right'>
                    <Button basic color="orange" onClick={() => handleBlockClick(follower.id)}>Block</Button>
                </List.Content>
            </List.Item>
        </List>
    ))) : (
        // Render a loading indicator while waiting for followers data
        <div>Loading...</div>
    )}
    {/** Step 2: Displaying Error Messages in the UI */}
    {errorMessage && (
      <div className="error-message">
          <p>{errorMessage}</p>
          <button onClick={resetError}>Close</button>
      </div>
  )}
  {/** Step 2: Displaying Success Messages in the UI */}
  {successMessage && (
      <div className="success-message">
          <p>{successMessage}</p>
          <button onClick={resetSuccess}>Close</button>
      </div>
  )}
    </Container>
</div>
    );
};
import { Outlet, useOutletContext, useNavigate } from 'react-router';
import {useState, useEffect} from 'react';
import styled, { css } from 'styled-components';
import { Navigation } from './Navigation';
import { Footer } from './Footer';

export const MainLayout = () => {

  const session = useOutletContext();
  console.log('MainLayout rendered with session=', session);


  return (
    <StyledMainLayout>
      <div>
        {/* Navigation is a child of MainLayout, it will automatically inherit the context */}
        <Navigation /> 
        {/* <Navigation session={session} /> */}
        <StyledContainer isLoggedIn={!!session}>
          {/* Outlet renders the child route based on the current URL */}
          {/* todo: do we need to pass a context prop here? */}
          <Outlet context={session} />
        </StyledContainer>
      </div>
      {/* after testing from blank, can't scroll all the way down!  
      I think we need the footer for that, and can just make it
       an empty footer 
      /** Nope.  Didn't fix the scroll problem */}
      <Footer />
    </StyledMainLayout>
  );
};

const StyledContainer = styled.div(({ isLoggedIn }) => {
  return css`
    background-color: #0f0f0f;
  `;
});

const StyledMainLayout = styled.div`
  background-color: #0f0f0f;
  padding: 0;
  margin: 0;
`;


/**
 * Make sure MainLayout renders the Navigation component properly.
  const MainLayout = () => {
    return (
        <div>
            <Navigation />
            <Outlet />
        </div>
    );
};

export default MainLayout;
 */

import { useNavigate, useOutletContext } from 'react-router';
import { useState, useEffect } from "react";
import { 
    Grid,
    Header,
    Button,
    Segment,
    Container,
    Image,
    Divider,
    Card } from 'semantic-ui-react'
import '../styles/UserViewStyles/user.css';
import { getPgData } from '../data/rdsClient';
import { useParams } from 'react-router-dom';


export const SearchResultView = () => {
  const session = useOutletContext();
  const [items, setItems] = useState([]);
  const { query } = useParams(); // query is the search term entered by user
  const [loading, setLoading] = useState(false); // Add loading state



  // Sample array of objects in format (a)
const formatA = [
  {
    author_name: "author_name",
    blurb: "Bloody, bloody, blah...",
    content: "A Seattle man recently...",
    created: "2023-09-25T01:19:23.284116+00:00",
    id: 209,
    image_url: "https://files.worldwildlife.org/...",
    is_paid: false,
    location_id: 3,
    num_likes: 0,
    subjects: [3, 1, 4, 1, 5],
    title: "Seattle Man Creates Multibillion Dollar Startup",
    total_sales: null,
    type: null,
    updated: "2023-09-25T01:19:23.284116+00:00",
    user_author: 2
  }
];

// Mapping function
const mapToFormatB = (formatA) => {
  return formatA.map((a) => {
    const header = a.title;
    const description = a.blurb;
    const meta = "By " + a.author_name;
    const extra = "Published: " + convertDateTimeWithTimeZoneToString(a.created);
    const image = a.image_url;
    const href = `/${a.id}`;
    const a_id = a.id; // added this id, I intend to pass it as a param to edit and delete icon handleClicks();

    return {
      header,
      description,
      meta,
      extra,
      image,
      href,
      a_id
    };
  });
};

// Call the mapping function
const formatB = mapToFormatB(formatA);

console.log(formatB);

function convertDateTimeWithTimeZoneToString(dateTimeWithTimeZoneString) {
  // Parse the PostgreSQL DateTimeWithTimeZone string into a JavaScript Date object
  const date = new Date(dateTimeWithTimeZoneString);

  // Check if the parsing was successful
  if (isNaN(date)) {
    return "Invalid Date"; // Handle invalid input
  }

  // Get the components of the date (month, day, year)
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const year = String(date.getFullYear());

  // Format the date as "mm/dd/yyyy"
  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
}

// Example usage:
const dateTimeWithTimeZoneString = "2023-10-11T15:30:00.000Z"; // Sample input
const formattedDate = convertDateTimeWithTimeZoneToString(dateTimeWithTimeZoneString);
console.log(formattedDate); // Output: "10/11/2023"


  console.log('SearchResultView')
  console.log('Query: ', query)
  console.log(session)

  async function searchArticles(query) {
    // searchArticles will recieve some search string as a urlParam
      try {
      const response = await getPgData(`/articlefresh?content=ilike.*${query}*`, {}, session);
      console.log('SearchResultView response: ', response);
      if (Array.isArray(response.data)) {
          console.log('SearchResultView articles: ', response.data);
          setItems(mapToFormatB(response.data))
      } else {
          console.error('Response data is not an array:', response.data);
      }
      } catch (error) {
      console.log(error);
      throw error;
      }
  }

  useEffect(() => {
      console.log('SearchResultView useEffect query: ',query)
      
      searchArticles(query)
  }, [query]);

  const navigate = useNavigate();

  return (
    <div style={{
            height: '100%', 
            backgroundColor: '#0f0f0f', 
            color: 'white',  
            padding: '80px 40px',
            display: 'flex' , 
            justifyContent: 'center'
        }}>
    <>
        <Container text>
        <div id='profile-body'>
            <div style={{display: 'flex' , justifyContent: 'center'}}>
                <p>Click an Article to open it in Feed</p>
            </div>
            <Divider />
            {loading ? ( // Render loading indicator while search is in progress
              <div>Loading...</div>
            ) : (
              <div id="profile-cards">
                <div style={{ display: 'inline-block' }}>
                  <Card.Group items={items} />
                </div>
              </div>
            )}
          </div>
        </Container>
    </>
  </div>
  );
};